/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Form.jsx
# Description: This is the detail per register of Roles of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/13/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Table,
    Alert,
    UncontrolledTooltip,
  } from "reactstrap";
  
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationContext from "context/NotificationContext";
import { Link, useParams } from "react-router-dom";
import RolesContext from "context/RolesContext";
import RolesFormValidate from "../../../services/rolesForm";
import { useForm } from "hooks/useForm";
import useAuth from "hooks/useAuth";

import Permissions from "./Permissions.jsx";
import ReactBSAlert from "react-bootstrap-sweetalert";

  const initialForm = {
    description: "",
  };

  const Formulario = ( ) => {

    const { detail:data, updateData, saveData, setModule, module, setToDetail, setDetail, setToUpdate, 
        viewModalPermissions, setViewModalPermissions, permissions, rolePermissions,addPermissions, deletePermissions,
        applications, toApplications, setToApplications, fetchDataPermissions } = useContext(RolesContext);

    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const { checkTokenExpiry } = useAuth();

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, RolesFormValidate.validationsForm);

    const { id } = useParams();

    const[state, setState] = useState({});
    const[idDelete, setIdDelete] = useState();

    const hideAlert = () => {
        setState({
          alert: null
        });
    };

    const confirmAlert = (id) => {
        setState({
            alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onCancel={() => hideAlert()}
                onConfirm={() => {setIdDelete(id); hideAlert();}}
                showCancel
                confirmBtnBsStyle="hensall-cancel"
                confirmBtnText="Yes, delete it!"
                cancelBtnBsStyle="hensall"
                cancelBtnText="Cancel"
                btnSize=""
            >
                You won't be able to revert this!
            </ReactBSAlert>
            )
        });
    };

    useEffect(() => {
        checkTokenExpiry();
        if(id){
            setToDetail(id);
            setToUpdate(id);
            setModule("update");
        }else{
            setModule("add");
        }
    },[]);


    useEffect(() => {
        setForm(data);
        setErrors(initialForm);
    },[data]);
    
    const handleUpdate = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            if (JSON.stringify(data) === JSON.stringify(form)) {
                setType('info');
                setMessage("No changes detected. Please cancel the process or change the information to save");
                setStatus(1);
            } else {
                updateData(form);
            }
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            saveData(form);
        }
    }

    const handleSelectApp = (e) => {
        const { value } = e.target;
        setToApplications(value);
      }

    const loadModalPermissions = (e) => {
        fetchDataPermissions();
        if (permissions?.length == 0){
            setType("danger");
            setMessage("There is no permissions available for this application. Please, define permissions first before add them to a role.");
            setStatus(1);
        } else {
            setViewModalPermissions(true);
        }
    }

    useEffect(() => {
        if(idDelete){
            handleDeletePermissions(idDelete);
        }
    },[idDelete]);

    const handleDeletePermissions = (id) => {
        deletePermissions(id);
    }

    return (
      <>
      {state.alert}
        <SimpleHeader name={"Roles " + (module ? (module[0].toUpperCase() + module.slice(1)) : "")} parentName="Roles" toUrl="roles" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                <Card>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Roles Form</h3>
                                <p className="text-sm mb-0">
                                    This is a Role form where you can create or update a Role information and permissions.
                                </p>
                            </Col>
                            {/* {module == "update" ? 
                            (
                            <Col className="text-right" xs="4">
                                <Input 
                                  className="form-control"
                                  id="input-type"
                                  type="select"
                                  name="type"
                                  value={toApplications}
                                  onChange={handleSelectApp}
                                  onBlur={handleSelectApp}
                                  disabled={true}
                                  >
                                  <option value="" hidden>Select an Application</option>
                                  {applications.map(item => (
                                      <option key={item.id} value={item.id}>
                                          {item.text}
                                      </option>
                                  ))};
                                </Input>
                            </Col>
                            ):("")} */}
                            <Col className="text-right" xs="4">
                                <Input 
                                  className="form-control"
                                  id="input-type"
                                  type="select"
                                  name="type"
                                  value={toApplications}
                                  onChange={handleSelectApp}
                                  onBlur={handleSelectApp}
                                  hidden={(module == 'update') ? true : false }
                                  >
                                  <option value="" hidden>Select an Application</option>
                                  {applications.map(item => (
                                      <option key={item.id} value={item.id}>
                                          {item.text}
                                      </option>
                                  ))};
                                </Input>
                            </Col>
                        </Row>
                      </CardHeader>
                    <CardBody>
                        <Form>
                            <h6 className="heading-small text-muted mb-4">
                            Information
                            </h6>
                            <div>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-description"
                                        >
                                        Description
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-description"
                                        placeholder=""
                                        type="text"
                                        name="description"
                                        required="required"
                                        invalid={errors.description !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.description}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.description}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="col justify-content-end mb-4">
                                {module == "update" ? (
                                    <Button
                                        color="hensall"
                                        href=""
                                        onClick={handleUpdate}
                                        >
                                        Update
                                    </Button>
                                ) : (
                                    <Button
                                        color="hensall"
                                        href=""
                                        onClick={handleSave}
                                        >
                                        Save 
                                    </Button>
                                )}
                                <Link
                                    className="btn btn-hensall-cancel"
                                    color="default"
                                    to={"/admin/roles"}
                                    >
                                    Cancel
                                </Link>
                            </Row>
                            {module == "update" ? (
                            <Card>
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col xs="6">
                                            <h3 className="mb-0">Role Permissions</h3>
                                        </Col>
                                        <Col className="text-right" xs="6">
                                            <a 
                                                className="btn-round btn-icon btn btn-hensall btn-sm"
                                                onClick={e => loadModalPermissions(e)} >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="ni ni-fat-add"></i>
                                                </span>
                                                <span className="">Add Permissions</span>
                                            </a>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush table-permissions" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Permissions</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rolePermissions.length > 0 ? 
                                        rolePermissions.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.description}</td>
                                            <td className="table-actions">
                                                <Button
                                                    className='btn btn-hensall-cancel btn-sm'
                                                    onClick={e => confirmAlert(item.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                        )):
                                        (
                                            <tr>
                                                <td>
                                            <Alert color="secondary" className="m-2">
                                                There is no information to display in this section of <strong>Permissions !</strong>
                                            </Alert>
                                            </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Card>
                            ):("")}
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </Container>
        <Permissions />
      </>
    );
  };
  
  export default Formulario;