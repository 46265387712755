/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: usersForm.js
# Description: This file is a service file that provides the validation to the user creation form on UAM
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/23/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    let errores = {};
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/;

    if (!form.name?.trim()) {
        errores.name = "Please the field is required.";
    }else if (form.name?.trim().length > 20) {
        errores.name = "The field must be less than 20 characters.";
    }else{
        errores.name = "";
    }

    if (!form.username?.trim()) {
        errores.username = "Please the field is required.";
    }else if (form.username?.trim().length > 30) {
        errores.username = "The field must be less than 30 characters.";
    }else{
        errores.username = "";
    }

    if (!form.email?.trim()) {
        errores.email = "Please the field is required.";
    }else if (!regexEmail.test(form.email.trim())) {
        errores.email = "The field accepts emails only.";
    }else if (form.email?.trim().length > 50) {
        errores.email = "The field must be less than 50 characters.";
    }else{
        errores.email = "";
    }

    return errores;
};

const UsersForm = {
    validationsForm,
};
  
export default UsersForm;