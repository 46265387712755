/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Article5.jsx
# Description: This is the content of a FAQs article, with images, styling and content.
# Created by: Juan David Olivares Padilla
# Creation Date: 02/09/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

import { Container } from "reactstrap";

import "assets/vendor/nucleo/css/nucleo.css";

function Article5() {
    const path = window.location.pathname
    const pathArray = path.split('/')
    let redirect = "/auth/FAQs"
    
    if (pathArray[1] === 'admin') {
        redirect = "/admin/FAQs"
    }
    
    return(
        <>
            <Container
                style={{
                    padding: "3em 0 2em 0"
                }}
                >    
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: "0.375em",
                    padding: "2em",
                    marginTop: "0.5em"
                }}>
                <div>
                    <Link to={redirect}><i className="ni ni-bold-left"></i> <strong>Back</strong> </Link>
                    <h1 className="text-center mb-6">How to setup MFA by Software Token on IOS phones</h1>
                </div>
                <h3>
                    1. Open the PlayStore on your phone. The application with this icon “
                    <span> <img alt="PlayStore_logo" src="/FAQs/appstore_icon.png" style={{height: "30px", width: "30px", boxShadow:".25px .25px 5px"}}/> </span>
                    ”:
                </h3>
                <div className="mb-6 text-center">
                    <img src="/FAQs/Article5/screenshot1.png" alt="Screenshot" width="35%"/>
                </div>
                <h3>
                    2. Go to the search tab on the down navigation bar. Then, on the top search bar, type "authenticator" and search:
                </h3>
                <div className="mb-6 text-center">
                    <img src="/FAQs/Article5/screenshot2.png" alt="Screenshot" width="35%"/>
                </div>
                <h3>
                    3. You will find plenty of options you can set-up. But we strongly recommend to use Google Authenticator, the one with this icon 
                    <span>"<img alt="PlayStore_apps_logo" src="/FAQs/google_authenticator_icon.png" style={{height: "40px", width: "40px", boxShadow:".25px .25px 5px", border:".25px solid #000"}}/> </span>
                    " select it and then press the "Get" button:
                </h3>
                <div className="mb-6 text-center">
                    <img src="/FAQs/Article5/screenshot3.png" alt="Screenshot" width="35%"/>
                </div>
                <h3>
                    4. Complete any security or authorization process and install the application:
                </h3>
                <div className="mb-6 text-center">
                    <img src="/FAQs/Article5/screenshot4.png" alt="Screenshot" width="35%"/>
                </div>
                <h3>
                    5. Wait until the application gets downloaded and installed. Then press the button "Open":
                </h3>
                <div className="mb-6 text-center">
                    <img src="/FAQs/Article5/screenshot5.png" alt="Screenshot" width="35%"/>
                </div>
                <h3>
                    6. Now you can use the Authenticator on any compatible app, in our case we will show how to use it on Hensall Login but you can set it up for many apps:
                </h3>
                <ul>
                    <li className="mb-5">
                        <h4>When you are setting up your MFA by software inside Hensall Login. First, you will receive a secret key. You must click over it to copy the text to the clipboard and use it later:</h4>
                        <div className="text-center">
                            <img src="/FAQs/Article6/screenshot5.jpg" alt="Screenshot" width="35%" />
                        </div>
                    </li>
                    <li className="mb-5">
                        <h4>Open the Google Authenticator again and start setting up your first or a new account. Select the option "Enter a setup key":</h4>
                        <div className="text-center">
                            <img src="/FAQs/Article6/screenshot6.jpg" alt="Screenshot" width="35%" />
                        </div>
                    </li>
                    <li className="mb-5">
                        <h4>You will see somthing like to the following image. Paste the key in the "Your key" field and also provide a name you could remember the site this belongs to on the "Account name", example: 'Hensall Login'. And finally click the "Add" button to confirm:</h4>
                        <div className="text-center">
                            <img src="/FAQs/Article6/screenshot7.png" alt="Screenshot" width="35%" />
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>Finally, copy the Temporary One-Time Password(TOTP) code that the Google Authenticator generates. Then, back to the Hensall Login application, paste the code on the 'Verification code' field and click on 'Send':</h4>
                        <div className="text-center align-items-center">
                            <img src="/FAQs/Article5/screenshot6.png" alt="Screenshot" width="35%" className="px-2"/>
                            <img src="/FAQs/Article6/screenshot9.png" alt="Screenshot" width="37%" className="px-2"/>
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>For now on whenever you are asked for SoftwareToken MFA verification code you must provide the code generated on the Authenticator.</h4>
                    </li>
                </ul>
            </div>
            </Container>
        </>
    );

}

export default Article5;