/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AddUser.jsx
# Description: This is the Fieldtrace connection modal, this file styles and define the base component that links the Fieldtrace account requesting to FT API.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import AppContext from "context/AppContext";
import { useForm } from "../../../hooks/useForm";
import AddUsersAppForm from "../../../services/addUsersAppForm";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    username: "",
    password: "",
  };
  
function AddUser() {

    const { viewModalAddUsers, setViewModalAddUsers, syncFieldTrace } = useContext(AppContext);

    const {
        form,
        errors,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, AddUsersAppForm.validationsForm);

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

    const handleStart = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            syncFieldTrace({
                username: form.username,
                password: form.password
            });
        }
    }

    const closeModal = (e) => {
        e.preventDefault();
        setViewModalAddUsers(false);
    }

    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalAddUsers}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    FieldTrace
                </h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div className="pl-lg-4">
                        <h3 className='text-center'>Connect an Account</h3>
                        <p className='text-center'>Please enter the FieldTrace username and password you wish to connect to this Hensall Login account.</p>
                        <Row>
                            <Col lg="12">
                            <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                    >
                                    Username
                                    </label>
                                    <Input 
                                        className="form-control"
                                        id="input-username"
                                        type="text"
                                        name="username"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={errors.username !== ""}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.username}
                                    </div>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-password"
                                    >
                                    Password
                                    </label>
                                    <Input 
                                        className="form-control"
                                        id="input-password"
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={errors.password !== ""}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.password}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleStart}
                    >
                        Link
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default AddUser;