/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: ModalPassword.js
# Description: This file is a basic forgot-password modal example from the template.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect} from 'react';
import { helpHttp } from "helpers/helpHttp";
import { useForm } from "../../hooks/useForm";
import PasswordForm from "../../services/passwordForm";
import authService from "services/auth";
import NotificationContext from "context/NotificationContext";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    newPassword: "",
    repeatPassword: "",
  };

function ModalPassword({viewModalPassword, setViewModalPassword}) {

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, PasswordForm.validationsForm);

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { REACT_APP_API_URL } = process.env;
    let api = helpHttp();
    let url = REACT_APP_API_URL+"users/changepwd";

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            if(form.newPassword != form.repeatPassword){
                setType("danger");
                setMessage("The keys must be the same");
                setStatus(1);
            }else{
                saveData(form);
            }
        }
    }

    const saveData = (data) => {
        let endpoint = url;
        let newData = {"password":data.newPassword};
        let options = {
            body: newData,
            headers: {
                "content-type":"application/json",
                "Authorization":"Bearer "+authService.getCurrentUser()
            }
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setViewModalPassword(false);
                setType("success");
                setMessage("The password was updated correctly");
                setStatus(1);
            }else{

            }
        })
    }

    const closeModal = (e) => {
        e.preventDefault();
        setViewModalPassword(false);
    }

    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={viewModalPassword}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Change password
                </h6>
            </div>
            <div className="modal-body">
                <Form>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-newPassword"
                                    >
                                    New password
                                    </label>
                                    <Input
                                    className="form-control"
                                    id="input-newPassword"
                                    placeholder=""
                                    type="password"
                                    name="newPassword"
                                    required="required"
                                    invalid={errors.newPassword !== ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue=""
                                    />
                                    <div className="invalid-feedback">
                                        {errors.newPassword}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-repeatPassword"
                                    >
                                    Repeat new password
                                    </label>
                                    <Input
                                    className="form-control"
                                    id="input-repeatPassword"
                                    placeholder=""
                                    type="password"
                                    name="repeatPassword"
                                    required="required"
                                    invalid={errors.repeatPassword !== ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue=""
                                    />
                                    <div className="invalid-feedback">
                                        {errors.repeatPassword}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href="#pablo"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default ModalPassword;