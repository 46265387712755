/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: routes.js
# Description: This file contains all the routes existing in the app and structures the sidebar menu as well.
               We defined all routes you can navigate to.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/pages/login/Login";
import ValidateAccount from "views/pages/login/ValidateAccount";
import ForgotPassword from "views/pages/login/ForgotPassword";
import Init from "views/pages/init/Index";
import Users from "views/pages/users/Index";
import Fields from "views/pages/fields/Index";
import Roles from "views/pages/roles/Index";
import Permissions from "views/pages/permissions/Index";
import Systems from "views/pages/systems/Index";
import App from "views/pages/app/Index";
import Profile from "views/pages/profile/Index";
import Audit from "views/pages/audit/Index";
import FAQs from "views/pages/FAQs/Index";
import Dashboard from "views/pages/dashboards/Index";

const { REACT_APP_ENV } = process.env;

function parseJwt (token) {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  } catch (error) {
    localStorage.removeItem("token_hensall_login");
    localStorage.removeItem("token_hensall");
    localStorage.removeItem("app_hensall");
    window.location.href = "/auth/login";
  }
  return JSON.parse(jsonPayload);
}

const isAzureUser = () => {
  let decode;
  let token;

  if(localStorage.getItem("token_hensall")){
    token = JSON.parse(localStorage.getItem("token_hensall"));
  }
  if(token){
    decode = parseJwt(token);
  }

  return decode?.azure ? true : false;
}

const routes = [
  {
    path: "/app",
    name: "Home",
    icon: "ni ni-shop text-hensall",
    component: App,
    layout: "/admin",
  },
  {
    path: "/home",
    name: "Home",
    icon: "ni ni-shop text-hensall",
    component: App,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/mfa-settings",
    name: "MFA Settings",
    icon: "ni ni-circle-08 text-hensall",
    component: Profile,
    layout: "/admin",
    invisible: isAzureUser()
  },
  {
    collapse: true,
    name: "Administration",
    icon: "ni ni-money-coins text-hensall",
    state: "administrationCollapse",
    views: [
      {
        path: "/applications",
        name: "Applications",
        miniName: "A",
        component: Systems,
        layout: "/admin",
      },
      {
        path: "/users",
        name: "Users",
        miniName: "U",
        component: Users,
        layout: "/admin",
      },
      {
        path: "/fields",
        name: "Fields",
        miniName: "F",
        component: Fields,
        layout: "/admin",
      },
      {
        path: "/roles",
        name: "Roles",
        miniName: "R",
        component: Roles,
        layout: "/admin",
      },
      {
        path: "/permissions",
        name: "Permissions",
        miniName: "P",
        component: Permissions,
        layout: "/admin",
      },
      {
        path: "/audit",
        name: "Audit",
        miniName: "A",
        component: Audit,
        layout: "/admin",
      },
      {
        path: "/statistics-dashboards",
        name: "Statistics Dashboard",
        miniName: "SD",
        component: Dashboard,
        layout: "/admin",
        invisible: (REACT_APP_ENV === 'PRODUCTION' || REACT_APP_ENV === 'PREPRODUCTION') ? true : false
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-single-02 text-hensall",
    component: Login,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/init",
    name: "Init",
    icon: "ni ni-single-02 text-hensall",
    component: Init,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    icon: "ni ni-single-02 text-hensall",
    component: ForgotPassword,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/validate-account",
    name: "ValidateAccount",
    icon: "ni ni-single-02 text-hensall",
    component: ValidateAccount,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/FAQs",
    name: "FAQs",
    icon: "ni ni-single-02 text-hensall",
    component: FAQs,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/FAQs",
    name: "FAQs",
    icon: "ni ni-single-02 text-hensall",
    component: FAQs,
    layout: "/admin",
    invisible: true
  },
];

export default routes;