// views/pages/SamlCallback.js
import React, { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from "hooks/useAuth";

const SamlCallback = () => {
    const history = useHistory();
    const location = useLocation();
    const { setAuth, setSsoError, parseJwt } = useAuth();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        const tokenMatch = document.cookie.match(/(?:^|; )auth_token=([^;]*)/);
        const singleValue = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
        
        // verifySsoToken(parseJwt(token).token_jwt)

        const error = location.pathname.split("/")[3];

        if (singleValue) {
            console.log("Cookie");
            console.log(singleValue);
            localStorage.setItem("token_hensall", JSON.stringify(singleValue));
            setAuth(true);
            history.push('/admin/app/');
        }else if (token) {
            console.log("URL");
            console.log(token);
            localStorage.setItem("token_hensall", JSON.stringify(token));
            setAuth(true);
            history.push('/admin/app/');
        } else if (error){
            setSsoError(error)
            history.push('/auth/login');
        } else {
            history.push('/auth/login');
        }
    }, [history, location]);

    const verifySsoToken = (token) => {
        // let endpoint = url +"users/token_verification";
        fetch("https://olnew-api.prismaqa.com/api/v1/users/token_verification", {
            headers: {
                "content-type": "application/json",
                "authorization": "Bearer " + token,
            },
            body: JSON.stringify({'token': token}),
            method: "POST",
            credentials: "include"
        }).then((res) => {
            if(!res.err){
                console.log(res);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (<></>);
};

export default SamlCallback;