import React, { useContext, useEffect, useState } from 'react';
import UsersContext from "context/UsersContext";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
 
function AdditionalInformationSystems({data}) {

    const { 
        field,
        fields,
        setField,
        fetchField,
        fetchFields,
        saveCustomField,
        fetchFieldsUsers,
        viewModalAdditionalInformation,
        setViewModalAdditionalInformation,
    } = useContext(UsersContext);

    const [show, setShow] = useState(false)
    const [fieldId, setFieldId] = useState("")
    const [showErros, setShowErrors] = useState(false)
    const [dataFieldUser, setDataFieldUser] = useState({})
    const [inputState, setInputState] = useState({field_id: false, option_id: false})

    useEffect(() => {
        fetchFields()
    }, [])

    useEffect(() => {
        if(fieldId !== "") fetchField(fieldId)
    }, [fieldId])
    
    useEffect(() => {
        if(data) setDataFieldUser({...dataFieldUser, "user_id": data.id, "email": data.email})
    }, [data])

    const closeModal = (e) => {
        setShow(false)
        setViewModalAdditionalInformation(false);
        e.preventDefault();
    }

    const handleField = (e, isValid) => {
        const { name, value } = e.target
        if(name === "field_id") setFieldId(value)
        if(name === "value") {
            setDataFieldUser({...dataFieldUser, [name]: value})
            setInputState({...inputState, [name]: isValid})
            return
        }
        setDataFieldUser({...dataFieldUser, [name]: Number(value)})
        setInputState({...inputState, [name]: isValid})
    }

    const handleSave = async () => {
        setShowErrors(true)
        if(!inputState.field_id) return
        if(dataFieldUser && Object.keys(dataFieldUser).length != 0) {
            field.options && field.options.length > 0 ? delete dataFieldUser.value : delete dataFieldUser.option_id
            if (field.options && field.options.length > 0 && !inputState.option_id) return
            if (!field.options && !field.options.length > 0 && !inputState.value) return
            await saveCustomField(dataFieldUser)
            setShowErrors(false)
            setShow(false)
            setViewModalAdditionalInformation(false);
            setInputState({field_id: false, option_id: false, value: false})
            setField([])
            if(data && Object.keys(data).length > 0 ) fetchFieldsUsers(data.id)
        }
    }

    return(
        <Modal
            size="sm"
            toggle={closeModal}
            className="modal-dialog-centered"
            isOpen={viewModalAdditionalInformation}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">Add Custom field</h6>
            </div>
            <div className="modal-body">
                <Form>
                    <div>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-field">Select new field</label>
                                    <Input 
                                        type="select"
                                        name="field_id"
                                        id="input-field"
                                        className="form-control"
                                        invalid={showErros && !inputState.field_id}
                                        onChange={(e) => {
                                            handleField(e, e.target.selectedIndex !== 0)
                                            setShow(true)
                                        }}
                                    >
                                        <option value="" hidden>Select...</option>
                                        {fields && fields.length > 0 ? fields.map((field, index) => (
                                            <option key={index} value={field.id}>{field.name}</option>
                                        )) : <option>No found data</option>}
                                    </Input>
                                    {showErros && inputState.field_id ? <div>Please the field is required.</div> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    {show
                        ? field.options && field.options.length > 0 ?
                            <div>
                                <Row>
                                    <Col lg="12">
                                        <label className="form-control-label" htmlFor="input-option">{field.name}</label>
                                        <Input 
                                            type="select"
                                            name="option_id"
                                            id="input-option"
                                            className="form-control"
                                            invalid={showErros && !inputState.option_id}
                                            onChange={(e) => handleField(e, e.target.selectedIndex !== 0)}
                                        >
                                            <option value="" hidden>Select...</option>
                                            {field.options && field.options.length > 0 ? field.options.map((option, index) => (
                                                <option key={index} value={option.id}>{option.name}</option>
                                            )) : <option>No found data</option>}
                                        </Input>
                                        {showErros && inputState.option_id ? <div>Please the field is required.</div> : null}
                                    </Col>
                                </Row>
                            </div> 
                        : 
                            <div>
                                <Row>
                                    <Col lg="12">
                                        <label className="form-control-label" htmlFor="input-value">Value</label>
                                        <Input 
                                            type="text"
                                            name="value"
                                            id="input-value"
                                            className="form-control"
                                            invalid={showErros && !inputState.value}
                                            onChange={(e) => handleField(e, e.target.value > 0)}
                                        ></Input>
                                        {showErros && inputState.value ? <div>Please the field is required.</div> : null}
                                    </Col>
                                </Row>
                            </div> 
                        : null
                    }
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button color="hensall" onClick={() => { handleSave() }}>Save</Button>
                    <Button className="btn btn-hensall-cancel" color="default" onClick={closeModal}>Cancel</Button>
                </Row>
            </div>
        </Modal>
    );

}

export default AdditionalInformationSystems;