/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: UsersContext.js
# Description: This is the UAM users context, it contains API calls, variables and logic needed in users section in the UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 17/05/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { helpHttpFile } from "helpers/helpHttpFile";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { TYPES } from "actions/genericAction";
import { genericReducer, genericInitialState } from "../reducers/genericReducer";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";

import { useAuth } from "hooks/useAuth";

const UsersContext = createContext();

const UsersProvider = ({children}) => {

    const { getNameUser, setApp } = useAuth();

    const [toDetail, setToDetail] = useState();
    const [toUpdate, setToUpdate] = useState();
    const [toSystem, setToSystem] = useState();
    const [detail, setDetail] = useState({});
    const [module, setModule] = useState();
    const[alertProc, setAlertProc] = useState(false);

    const [applications, setApplications] = useState([]);
    const [toApplications, setToApplications] = useState(null);

    const[oldPhone, setOldPhone] = useState();
    const[phone, setPhone] = useState();

    const [currentPreference, setCurrentPreference] = useState({
        smsEnable: "",
        smsPreferred: "",
        softwareEnable: "",
        softwarePreferred: ""
    });

    const [roles, setRoles] = useState([]);
    const [systems, setSystems] = useState([]);
    const [roleSystems, setRoleSystems] = useState(false);
    const [adminSystems, setAdminSystems] = useState(false);

    const [viewModalRoles, setViewModalRoles] = useState();
    const [viewModalAdditionalInformation, setViewModalAdditionalInformation] = useState();
    const [viewModalSystems, setViewModalSystems] = useState();

    const history = useHistory();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    const { REACT_APP_API_URL } = process.env;
    const [state, dispatch] = useReducer(genericReducer, genericInitialState);
    const { db } = state;
    const [fields, setFields] = useState([]);
    const [field, setField] = useState([]);
    const [fieldUsers, setFieldUsers] = useState([]);

    let api = helpHttp();
    let apiFile = helpHttpFile();
    let url = REACT_APP_API_URL+"users";
    let urlFetch = REACT_APP_API_URL+"users";

    useEffect(() => {
        fetchDataApplications();
    },[]);

    useEffect(() => {
        if(toApplications){
            setApp(toApplications);
            fetchData();
            if(toUpdate != 0){
                fetchDataRoleSystems();
                fetchDataAdminSystems();
            }
        }
    },[toApplications, module]);

    useEffect(() => {
        if(toUpdate && toUpdate != 0){
            fetchDataDetail();
            if(toApplications){
                fetchDataRoleSystems();
                fetchDataAdminSystems();
            }
        }
    },[toUpdate]);

    useEffect(() => {
        if(viewModalRoles || viewModalSystems){
            //fetchDataSystems();
            fetchDataRoles();
        }
    },[viewModalRoles])


    const fetchData = () => {
        setLoading(true);
        url = REACT_APP_API_URL+"systems/" + toApplications + "/users";
         api.get(url).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
            }else{
                dispatch({ type: TYPES.NO_DATA });
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setType('danger')
            setMessage("Can't load data")
            setStatus(1)
        });
    };

    const fetchDataApplications = () => {
        url = REACT_APP_API_URL+"admins/current/systems";
        api.get(url).then((res) => {
            var data = res.map(function (obj) {
                obj.text = obj.text || obj.name;
                return obj;
            });
            setApplications(data);
        }).catch((err) => {
            setLoading(false);
        });
    };

    const fetchDataDetail = () => {
        setLoading(true);
        url = REACT_APP_API_URL+"users";
        url = url+"/"+toUpdate;
        api.get(url).then((res) => {
            res['role'] = res.role?.id;
            setDetail(res);
            setLoading(false);
            setOldPhone(res.phone ? res.phone : '')
        }).catch((err) => {
            setLoading(false);
        });
    };

    const fetchDataRoleSystems = () => {
        urlFetch = REACT_APP_API_URL+"users/"+toUpdate+"/system/"+toApplications;
        api.get(urlFetch).then((res) => {
            if(!res.msg){
                setRoleSystems(res);
            }else{
                setRoleSystems(false);
            }
        }).catch((err) => {
            setLoading(false);
        });
    };

    const fetchDataAdminSystems = () => {
        urlFetch = REACT_APP_API_URL+"admins/"+toUpdate+"/systems/"+toApplications+"/admin";
        api.get(urlFetch).then((res) => {
            if(!res.msg){
                setAdminSystems(res);
            }else{
                setAdminSystems(false);
            }
        });
    };

    const fetchDataRoles = () => {
        url = REACT_APP_API_URL+"systems/"+toApplications+"/roles";
        api.get(url).then((res) => { 
            var data = res.map(function (obj) {
                obj.text = obj.text || obj.description;
                return obj;
            });
            if (data.length > 0) {
                setRoles(data);   
            } else {
                setViewModalRoles(false)
                setType('danger')
                setMessage('This application doesn\'t have any role available to assign.')
                setStatus(1)
            }
        });
    };

    const fetchDataDownload = () => {
        setLoading(true);
        let endpoint = url+"/"+toApplications+"/users_download";
        let tsvFileName = toApplications
        apiFile.get(endpoint).then((res) => {
            if(res){
                applications.map((app) => {
                    if (app.id === toApplications) {
                        tsvFileName = app.acronym;      
                    }
                })
                saveFile(res, tsvFileName + '_users_list' + '.tsv')
                setType("success");
                setMessage("The search result has been downloaded.");
                setStatus(1);
            }
            setLoading(false);
        }).catch(err => {
            if (err.err) {
                setType("danger");
                setMessage("Currently the service is presenting interruptions. Try again later, if error persists please send an email to HensallLoginSupport@hdc.on.ca with subject: 'Service interruption Error OL-06'.");
                setStatus(1);
            }
            setLoading(false);
        })
    };

    const saveFile = (function () {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
            const blob = new Blob([data], {type: "octet/stream"}),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());


    const saveData = (data) => {
        //setLoading(true);
        let endpoint = url+"/email/"+data.email;
        api.get(endpoint).then((res) => {
            if(!res.err){
                // history.push('/admin/users/detail/'+res.id);
                // setToUpdate(res.id);
                // setModule("update");
                // fetchDataDetail();
                setType("danger");
                setMessage("The email used already belongs to an existing user, please use a different email address.");
                setStatus(1);            
            }else{
                data['user_created'] = getNameUser().toUpperCase();
                data['status'] = "1";
                let newData = data;
                delete newData.id;
                let options = {
                    body: newData,
                    headers: {"content-type":"application/json"}
                }
                endpoint = url;
                api.post(endpoint, options).then((res) => {
                    if(!res.err){
                        dispatch({ type: TYPES.CREATE_DATA, payload: res });
                        setAlertProc(true);
                        setToUpdate(res.id);
                        setModule("update");
                        history.push('/admin/users/detail/'+res.id);
                    }else{
                        res.body.then((res2)=> {
                            if (res2.msg[0].split(' ')[0].split('.')[2] === "UniqueViolation)") {
                                setType("danger");
                                setMessage("The username is already used. Please change it to be unique");
                                setStatus(1);
                            }
                        })
                    }
                    setLoading(false);
                })
            }
        })
    }

    const updateData = (data) => {
        setLoading(true);
        let endpoint = url+"/"+data.id;
        data['user_created'] = getNameUser().toUpperCase();
        let newData = data;
        delete newData.id;
        delete newData.date_created;
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.put(endpoint, options).then((res) => {
            if(!res.err){
                setDetail(res);
                dispatch({ type: TYPES.UPDATE_DATA, payload: res });
                history.push('/admin/users');
                setType("success");
                setMessage("The registry was updated correctly");
                setStatus(1);
            }else{

            }
            setLoading(false);
        })
    }

    const deleteData = (id) => {
        setLoading(true);
        let endpoint = url+"/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.DELETE_DATA, payload: id });
            }
            setLoading(false);
        });
    }

    const enableDisable = (data) => {
        setLoading(true)
        let endpoint = url+ (data.mfa_config?.StatusCognito == 'Enable' ? "/disable" : "/enable") +"_user";
        let options = {
            body: {email: data.email},
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                window.location.reload();
            }
        })
    }

    const deleteDataCognito = (email) => {
        setLoading(true);
        let endpoint = url+"/delete_user";
        let options = {
            body: {email: email},
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.DELETE_DATA, payload: email });
                setType("success");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
            setLoading(false);
        });
    }

    const mfaPreference = (data) => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"auth/mfa_preference";
        let options = {
            body: data,
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                delete data.email;
                setCurrentPreference(data);
                setType("success");
                setMessage("New MFA configuration saved");
                setStatus(1);
            }
            setLoading(false);
        });
    }

    const addRoleSystems = (data) => {
        let endpoint = REACT_APP_API_URL+"users/"+toUpdate+"/system/"+toApplications+"/role/"+data.role;
        api.post(endpoint).then((res) => {
            if(!res.err){
                fetchData();
                setRoleSystems(res)
                setType("success");
                setMessage("The record has been successfully saved");
                setStatus(1);
            }
        });
    }

    const deleteRoles = (id) => {
        let endpoint = REACT_APP_API_URL+"users/"+id+"/role";
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                fetchData();
                setRoleSystems(false);
                setType("success");
                setMessage("The UAM permissions were deleted correctly");
                setStatus(1);
            }
        });
    }

    const addAdminSystems = () => {
        let endpoint = REACT_APP_API_URL+"admins/"+toUpdate+"/systems/"+toApplications;
        let options = {
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setAdminSystems(res)
                setType("success");
                setMessage("UAM permissions added successfully to administer the selected application");
                setStatus(1);
            }
        });
    }

    const deleteSystems = (id) => {
        let endpoint = REACT_APP_API_URL+"admins/"+toUpdate+"/systems/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                setAdminSystems(false);
                setType("success");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
        });
    }

    const resetPassword = (id) => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"users/reset_pass/"+id;
        api.get(endpoint).then((res) => {
            if(!res.err){
                setType("success");
                setMessage("The User Password was changed correctly");
                setStatus(1);
            }
            setLoading(false);
        });
    }

    const fetchUserApplications = (id) => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"users/" + id + "/systems_role_user";
        api.get(endpoint).then((res) => {
            if(!res.err){
                setSystems(res);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    }

    const fetchFields = async () => {
        const res = await api.get(`${REACT_APP_API_URL}field`).catch((err) => {setLoading(false)});
        if (res) { if (res.data) setFields(res.data); }
    }

    const fetchField = async (field_id) => {
        const res = await api.get(`${REACT_APP_API_URL}field/${field_id}`)
        setField(res);
    };

    const fetchFieldsUsers = async (user_id) => {
        const res = await api.get(`${REACT_APP_API_URL}field/fields_user/${user_id}`)
        setFieldUsers(res);
    };

    const saveCustomField = async (data) => {
        let newData = data;
        let options = {
            body: newData,
            headers: { "content-type": "application/json" },
        };
        const res = await api.post(`${REACT_APP_API_URL}field/user_custom_field`, options)
        if (!res.err) {
            dispatch({ type: TYPES.CREATE_DATA, payload: res });
            setType("success");
            setMessage("The registry was updated correctly");
            setStatus(1);
        }
        setLoading(false);
    };

    const deleteField = async (id) => {
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        await api.del(`${REACT_APP_API_URL}field/user_custom_field/${id}`, options)
        // .then((res) => {
        //     if(!res.err){
        //         setType("success");
        //         setMessage("The registry was deleted correctly");
        //         // setStatus(1);
        //     }
        // });
    }

    const data = {
        db,
        field,
        detail,
        roles,
        module,
        phone,
        oldPhone,
        fields,
        systems,
        saveData,
        setPhone,
        setOldPhone,
        setField,
        setDetail,
        setModule,
        alertProc,
        fieldUsers,
        updateData,
        fetchField,
        deleteData,
        deleteField,
        fetchFields,
        setToUpdate,
        setToDetail,
        roleSystems,
        deleteRoles,
        adminSystems,
        setAlertProc,
        applications,
        enableDisable,
        deleteSystems,
        mfaPreference,
        currentPreference,
        setCurrentPreference,
        toApplications,
        viewModalRoles,
        resetPassword,
        addRoleSystems,
        addAdminSystems,
        saveCustomField,
        fetchFieldsUsers,
        viewModalSystems,
        fetchDataDownload,
        setViewModalRoles,
        deleteDataCognito,
        setToApplications,
        setViewModalSystems,
        fetchUserApplications,
        fetchDataApplications,
        viewModalAdditionalInformation,
        setViewModalAdditionalInformation,
    };

    return <UsersContext.Provider value={data}>{children}</UsersContext.Provider>;
}

export { UsersProvider };
export default UsersContext;