/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: PermissionsContext.js
# Description: This is the UAM permissions context, it contains API calls, variables and logic needed in permissions section the UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { TYPES } from "actions/genericAction";
import { genericReducer, genericInitialState } from "../reducers/genericReducer";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";
import { useAuth } from "hooks/useAuth";

const PermissionsContext = createContext();

const PermissionsProvider = ({children}) => {

    const { getNameUser, setApp, getApp } = useAuth();

    const [toDetail, setToDetail] = useState();
    const [toUpdate, setToUpdate] = useState();
    const [detail, setDetail] = useState({});
    const [module, setModule] = useState();

    const [applications, setApplications] = useState([]);
    const [toApplications, setToApplications] = useState();
    
    const history = useHistory();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    const { REACT_APP_API_URL } = process.env;
    const [state, dispatch] = useReducer(genericReducer, genericInitialState);
    const { db } = state;

    let api = helpHttp();
    let url = REACT_APP_API_URL+"permissions";
    let urlFetch = REACT_APP_API_URL+"permissions";

    useEffect(() => {
        //fetchData();
        fetchDataApplications();
        if(getApp){
            setToApplications(getApp);
        }
    },[]);

    useEffect(() => {
        if(toApplications){
            if (toApplications === 'all' || toApplications === 'unassigned'){
                setToApplications('')
                setApp('');
            } else {
                setApp(toApplications);
                fetchDataByApp();
            }
        }
    },[toApplications]);

    useEffect(() => {
        if(toUpdate && toUpdate != 0){
            fetchDataDetail();
        }
    },[toUpdate]);

    const fetchData = () => {
        setLoading(true);
         api.get(url).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
            }else{
                dispatch({ type: TYPES.NO_DATA });
            }
            setLoading(false);
        });
    };

    const fetchDataByApp = () => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"systems/"+toApplications+"/permissions";
         api.get(endpoint).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
            }else{
                dispatch({ type: TYPES.NO_DATA });
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    };

    const fetchDataApplications = () => {
        url = REACT_APP_API_URL+"admins/current/systems";
        api.get(url).then((res) => {
            var data = res.map(function (obj) {
                obj.text = obj.text || obj.name;
                return obj;
            });
            setApplications(data);
        }).catch((err) => {
            setLoading(false);
            setType('danger')
            setMessage("Can't load data")
            setStatus(1)
        });
    };

    const fetchDataDetail = () => {
        setLoading(true);
        let endpoint = url+"/"+toUpdate;
        api.get(endpoint).then((res) => {
            setDetail(res);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    };

    const saveData = (data) => {
        if(!toApplications){
            setType("danger");
            setMessage("An application must be selected");
            setStatus(1);
        }else{
            setLoading(true);
            let endpoint = url;
            let newData = data;
            newData.system = toApplications;
            delete newData.id;
            let options = {
                body: newData,
                headers: {"content-type":"application/json"}
            }
            api.post(endpoint, options).then((res) => {
                if(!res.err){
                    dispatch({ type: TYPES.CREATE_DATA, payload: res });
                    history.push('/admin/permissions/');
                    setType("success");
                    setMessage("The registry was updated correctly");
                    setStatus(1);
                }else{

                }
                setLoading(false);
            })
        }
    }

    const updateData = (data) => {
        setLoading(true);
        let endpoint = url+"/"+data.id;
        let newData = data;
        delete newData.id;
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.put(endpoint, options).then((res) => {
            if(!res.err){
                setDetail(res);
                dispatch({ type: TYPES.UPDATE_DATA, payload: res });
                history.push('/admin/permissions');
                setType("success");
                setMessage("The registry was updated correctly");
                setStatus(1);
            }else{

            }
            setLoading(false);
        })
    }

    const deleteData = (id) => {
        setLoading(true);
        let endpoint = url+"/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.DELETE_DATA, payload: id });
                setType("success");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
            setLoading(false);
        });
    }

    const data = {db, detail, setToDetail, setToUpdate, updateData, saveData, deleteData, module, 
        setModule, setDetail, applications, toApplications, setToApplications };

    return <PermissionsContext.Provider value={data}>{children}</PermissionsContext.Provider>;
}

export { PermissionsProvider };
export default PermissionsContext;