/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: RolesContext.js
# Description: This is the UAM roles context, it contains API calls, variables and logic needed in roles section in the UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { TYPES } from "actions/genericAction";
import { genericReducer, genericInitialState } from "../reducers/genericReducer";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";
import { useAuth } from "hooks/useAuth";

const RolesContext = createContext();

const RolesProvider = ({children}) => {

    const { getNameUser, setApp, getApp } = useAuth();

    const [toDetail, setToDetail] = useState();
    const [toUpdate, setToUpdate] = useState();
    const [detail, setDetail] = useState({});
    const [module, setModule] = useState();

    const [applications, setApplications] = useState([]);
    const [toApplications, setToApplications] = useState();

    const [permissions, setPermissions] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    
    const [viewModal, setViewModal] = useState();
    const [viewModalPermissions, setViewModalPermissions] = useState();

    const history = useHistory();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    const { REACT_APP_API_URL } = process.env;
    const [state, dispatch] = useReducer(genericReducer, genericInitialState);
    const { db } = state;

    let api = helpHttp();
    let url = REACT_APP_API_URL+"roles";
    let urlFetch = REACT_APP_API_URL+"roles";

    useEffect(() => {
        //fetchData();
        fetchDataApplications();
        if(getApp){
            setToApplications(getApp);
        }
    },[]);

    useEffect(() => {
        if(toUpdate && toUpdate != 0){
            fetchDataDetail();
            fetchDataRolePermissions();
        }
    },[toUpdate]);

    useEffect(() => {
        if(toApplications){
            if (module === 'list'){
                if (toApplications === 'unassigned' || toApplications == '[object Object]') {
                    setApp('');
                    setToApplications('');
                } else {
                    setApp(toApplications);
                    fetchDataByApp();
                }
            } else if (module === 'update') {
                fetchDataPermissions();
            }
        }
    },[toApplications]);

    useEffect(() => {
        if(viewModalPermissions){
            // fetchDataPermissions();
        }
    },[viewModalPermissions])

    const fetchData = () => {
        setLoading(true);
         api.get(url).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
            }else{
                dispatch({ type: TYPES.NO_DATA });
            }
            setLoading(false);
        });
    };

    const fetchDataByApp = () => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"systems/"+toApplications+"/roles";
         api.get(endpoint).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
            }else{
                dispatch({ type: TYPES.NO_DATA });
            }
            setLoading(false);
        });
    };

    const fetchDataApplications = () => {
        url = REACT_APP_API_URL+"admins/current/systems";
        api.get(url).then((res) => {
            var data = res.map(function (obj) {
                obj.text = obj.text || obj.name;
                return obj;
            });
            setApplications(data);
        });
    };

    const fetchDataDetail = () => {
        setLoading(true);
        url = url+"/"+toUpdate;
        api.get(url).then((res) => {
            if (!res.err) {
                if (res.description) {
                    setPermissions([]);
                    setDetail(res);
                } else if (res.length > 0) {
                    if (toApplications === "all" || toApplications === '' || !toApplications || res.length > 1){
                        setToApplications(res[0].syst_uuid);
                    } else {
                        fetchDataPermissions();
                        setToApplications(toApplications)
                    }
                    setDetail(res[0]);
                }
            } else {
                //error
            }
            setLoading(false);
        });
    };

    const fetchDataRolePermissions = () => {
        urlFetch = REACT_APP_API_URL+"roles/"+toUpdate+"/permissions";
        api.get(urlFetch).then((res) => {
            setRolePermissions(res);
        });
    };

    const fetchDataPermissions = () => {
        //urlFetch = REACT_APP_API_URL+"permissions";
        urlFetch = REACT_APP_API_URL+"systems/"+toApplications+"/permissions";
        api.get(urlFetch).then((res) => {
            if (!res.err) {
                var data = res.map(function (obj) {
                    obj.text = obj.text || obj.description;
                    return obj;
                });
                setPermissions(data);
            }
        });
    };

    const saveData = (data) => {
        setLoading(true);
        let endpoint = url;
        data['status'] = "1";
        let newData = data;
        delete newData.id;
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                //dispatch({ type: TYPES.CREATE_DATA, payload: res });
                if (!toApplications || toApplications === "all" || toApplications === "") {
                    history.push('/admin/roles');
                    setType("success");
                    setMessage("The registry was updated correctly");
                    setStatus(1);
                } else {
                    let endpoint = REACT_APP_API_URL+"systems/"+toApplications+"/permissions/"+res.id;
                    let options = {
                        headers: {"content-type":"application/json"}
                    }
                    api.post(endpoint, options).then((res) => {
                        if(!res.err){
                            dispatch({ type: TYPES.CREATE_DATA, payload: res });
                            history.push('/admin/roles');
                            setType("success");
                            setMessage("The record has been successfully saved");
                            setStatus(1);
                        }else{
                            setType("danger");
                            setMessage("The role could not get assigned to the application");
                            setStatus(1);
                        }
                    })

                }
            }else{
                setType("danger");
                setMessage("Registry not created, it may already exist");
                setStatus(1);
            }
            setLoading(false);
        })
    }

    const updateData = (data) => {
        setLoading(true);
        let endpoint = url+"/"+data.id;
        let newData = data;
        delete newData.id;
        delete newData.name_system;
        delete newData.syst_uuid;

        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.put(endpoint, options).then((res) => {
            if(!res.err){
                setDetail(res);
                dispatch({ type: TYPES.UPDATE_DATA, payload: res });
                history.push('/admin/roles');
                setType("success");
                setMessage("The registry was updated correctly");
                setStatus(1);
            }else{

            }
            setLoading(false);
        })
    }

    const deleteData = (id) => {
        setLoading(true);
        let endpoint = url+"/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.DELETE_DATA, payload: id });
                setType("success");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
            setLoading(false);
        });
    }

    const addPermissions = (data) => {
        let exist = false;
        rolePermissions.map((el) => {
            if(el.description === data.equipment_text){
                exist = true;
                return;
            }
        });
        if(exist){
            setType("danger");
            setMessage("The permission already exists");
            setStatus(1);
            return;
        }else{
            let endpoint = REACT_APP_API_URL+"roles/"+toUpdate+"/permissions/"+data.permissions;
            let options = {
                headers: {"content-type":"application/json"}
            }
            api.post(endpoint, options).then((res) => {
                if(!res.err){
                    setRolePermissions((rolePermissions) => {
                        return [...rolePermissions, res]
                    })
                    setType("success");
                    setMessage("The record has been successfully saved");
                    setStatus(1);
                }else{

                }
            })
        }
    }

    const deletePermissions = (id) => {
        let endpoint = REACT_APP_API_URL+"roles/"+toUpdate+"/permissions/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                let newData = rolePermissions.filter((el) => el.id !== id);
                setRolePermissions(newData);
                setType("success");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
        });
    }

    const data = {db, detail, setToDetail, setToUpdate, updateData, saveData, deleteData, module, 
        setModule, setDetail, viewModal, setViewModal, viewModalPermissions, setViewModalPermissions, 
        permissions, rolePermissions, addPermissions, deletePermissions, applications, toApplications, 
        setToApplications, fetchDataPermissions};

    return <RolesContext.Provider value={data}>{children}</RolesContext.Provider>;
}

export { RolesProvider };
export default RolesContext;