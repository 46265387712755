/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Form.jsx
# Description: This is the detail per register of Systems of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/21/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/12/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Table,
    Alert,
    UncontrolledTooltip,
  } from "reactstrap";
  
  import SimpleHeader from "components/Headers/SimpleHeader.js";
  import { Link, useParams } from "react-router-dom";
  import SystemsContext from "context/SystemsContext";
  import SystemsFormValidate from "../../../services/systemsForm";
  import { useForm } from "hooks/useForm";
  import useAuth from "hooks/useAuth";
  import NotificationContext from "context/NotificationContext";

  import Roles from "./Roles.jsx";
  import ReactBSAlert from "react-bootstrap-sweetalert";

  const initialForm = {
    name: "",
	type: "",
    acronym: "",
    url: "",
    token_structure: "",
    api: "",
    log_file: "",
  };

  const Formulario = ( ) => {

    const { detail:data, updateData, saveData, setModule, module, setToDetail,setDetail, setToUpdate, 
        viewModalRoles, setViewModalRoles, systemRoles, addRoles, deleteRoles, typeSystem } = useContext(SystemsContext);

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, SystemsFormValidate.validationsForm);

    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const { checkTokenExpiry } = useAuth();

    const { id } = useParams();

    const[state, setState] = useState({});
    const[idDelete, setIdDelete] = useState();

    const hideAlert = () => {
        setState({
          alert: null
        });
    };

    const confirmAlert = (id) => {
        setState({
            alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onCancel={() => hideAlert()}
                onConfirm={() => {setIdDelete(id); hideAlert();}}
                showCancel
                confirmBtnBsStyle="hensall-cancel"
                confirmBtnText="Yes, delete it!"
                cancelBtnBsStyle="hensall"
                cancelBtnText="Cancel"
                btnSize=""
            >
                You won't be able to revert this!
            </ReactBSAlert>
            )
        });
    };

    useEffect(() => {
        checkTokenExpiry();
        if(id){
            setToDetail(id);
            setToUpdate(id);
            setModule("update");
        }else{
            setModule("add");
        }
    },[]);

    useEffect(() => {
        setForm(data);
        setErrors(initialForm);
    },[data]);
    
    const handleUpdate = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            if (JSON.stringify(data) === JSON.stringify(form)) {
                setType("info");
                setMessage("No changes detected. Please cancel the process or change the information to save");
                setStatus(1);
            } else {
                updateData(form);
            }
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            saveData(form);
        }
    }

    const loadModalRoles = (e) => {
        setViewModalRoles(true);
    }

    useEffect(() => {
        if(idDelete){
            handleDeleteRoles(idDelete);
        }
    },[idDelete]);

    const handleDeleteRoles = (id) => {
        deleteRoles(id);
    }

    return (
      <>
        {state.alert}
        <SimpleHeader name={"Application " + (module ? (module[0].toUpperCase() + module.slice(1)) : "")} parentName="Applications" toUrl="applications" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                <Card>
                    
                    <CardBody>
                        <Form>
                            <h6 className="heading-small text-muted mb-4">
                            Information
                            </h6>
                            <div>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-name"
                                        >
                                        Name
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-name"
                                        placeholder=""
                                        type="text"
                                        name="name"
                                        required="required"
                                        invalid={errors.name !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.name}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.name}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-type"
                                        >
                                        Type
                                        </label>
                                        <Input 
                                            className="form-control"
                                            id="input-type"
                                            type="select"
                                            name="type"
                                            value={form.type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={errors.type !== ""}
                                            >
                                            <option value="" hidden></option>
                                            {typeSystem.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.text}
                                                </option>
                                            ))};
                                        </Input>
                                        <div className="invalid-feedback">
                                            {errors.type}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-acronym"
                                        >
                                        Acronym
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-acronym"
                                        placeholder=""
                                        type="text"
                                        name="acronym"
                                        required="required"
                                        invalid={errors.acronym !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.acronym}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.acronym}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-url"
                                        >
                                        URL
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-url"
                                        placeholder=""
                                        type="text"
                                        name="url"
                                        required="required"
                                        invalid={errors.url !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.url}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.url}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-token_structure"
                                        >
                                        Token Structure
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-token_structure"
                                        placeholder=""
                                        type="text"
                                        name="token_structure"
                                        required="required"
                                        invalid={errors.token_structure !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.token_structure}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.token_structure}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-api"
                                        >
                                        API URL
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-api"
                                        placeholder=""
                                        type="text"
                                        name="api"
                                        required="required"
                                        invalid={errors.api !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.api}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.api}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-log_file"
                                        >
                                        Log File
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-log_file"
                                        placeholder=""
                                        type="text"
                                        name="log_file"
                                        required="required"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.log_file}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {module == "update" ? (
                            <Card>
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col xs="6">
                                            <h3 className="mb-0">Application Role</h3>
                                        </Col>
                                        <Col className="text-right" xs="6">
                                            <a 
                                                className="btn-round btn-icon btn btn-hensall btn-sm"
                                                onClick={e => loadModalRoles(e)} >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="ni ni-fat-add"></i>
                                                </span>
                                                <span className="">Add Roles</span>
                                            </a>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush table-permissions" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Role</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {systemRoles.length > 0 ? 
                                        systemRoles.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.description}</td>
                                            <td className="table-actions">
                                                <Button
                                                    className='btn btn-hensall-cancel btn-sm'
                                                    onClick={e => confirmAlert(item.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                        )):
                                        (
                                            <tr>
                                                <td>
                                            <Alert color="secondary" className="m-2">
                                                There is no information to display in this section of <strong>Application Roles !</strong>
                                            </Alert>
                                            </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Card>
                            ):("")}
                            <Row className="col justify-content-end">
                                {module == "update" ? (
                                    <Button
                                        color="hensall"
                                        href=""
                                        onClick={handleUpdate}
                                        >
                                        Update
                                    </Button>
                                ) : (
                                    <Button
                                        color="hensall"
                                        href=""
                                        onClick={handleSave}
                                        >
                                        Save 
                                    </Button>
                                )}
                                <Link
                                    className="btn btn-hensall-cancel"
                                    color="default"
                                    to={"/admin/applications"}
                                    >
                                    Cancel
                                </Link>
                            </Row>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </Container>
        <Roles />
      </>
    );
  };
  
  export default Formulario;