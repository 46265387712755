/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Profile.jsx
# Description: This is the Profile view component with all the MFa configuration and methods for setup.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/21/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-number-input'
import ProfileContext from "context/ProfileContext";
import LoginContext from "context/LoginContext";
import LoadingContext from "context/LoadingContext";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Container,
    Row,
    Col,
    CardTitle,
  } from "reactstrap";
import '../../../assets/css/custom/mfa-preference.css';
import ReactBSAlert from "react-bootstrap-sweetalert";

import ProfileHeader from "components/Headers/ProfileHeader.js";
import SoftwareToken_Setup from "../MFA/SoftwareToken_Setup";
import useAuth from "hooks/useAuth";


  const Profile = () => {

    //RegEx for error messages
    const regexPhoneLenght = /^\+?[0-9]{11,21}$/;
    const regexPhoneOnlycc = /^\+?([0-9]{1,3})$/;

    //Contexts data
    const { stSetup } = useContext(LoginContext);
    const { getMfaPreference, updatePhone, currentPreference,
            setCurrentPreference, setNewPreference,
            phone, newPhone, setNewPhone,
            mfaPreference, mfaTypes,
            errorAlertIvalidPhone, errorAlertCantDeletePhone, errorAlertCantSavePreference
    } = useContext(ProfileContext);

    const { setLoading } = useContext(LoadingContext);
    const history = useHistory();
    const { checkTokenExpiryApp, isAzureUser } = useAuth();

    //Component states
    const[alert1State, setAlert1State] = useState({});
    const[alert2State, setAlert2State] = useState({});
    const[alert3State, setAlert3State] = useState({});

    useEffect(() => {
      if (isAzureUser()) {
        confirmAlert3();
      } else {
        checkTokenExpiryApp();
        getMfaPreference(); 
      }
    },[])
    useEffect(() => {
      let phone = document.getElementById("phone")
      if (!phone.classList.contains("form-control")) { 
        phone.classList.add("form-control"); 
      }
    }, []);
    useEffect(() => {
      if (mfaTypes){
        document.getElementById("radioSMS").disabled = false;
        document.getElementById("radioSt").disabled = false;
        // document.getElementById("mfaPreferenceForm")?.classList.remove('disabled');

        if(((mfaTypes.includes('SMS_MFA') && mfaTypes.includes('SOFTWARE_TOKEN_MFA')) || (phone !== '' && phone !== undefined && mfaTypes.includes('SOFTWARE_TOKEN_MFA')))){
          document.getElementById("StStatus").innerHTML = "Enabled"
          if (mfaPreference === 'SMS_MFA'){
            document.getElementById("radioSMS").checked = true;
            setCurrentPreference({
              smsEnable: "true",
              smsPreferred: "true",
              softwareEnable: "true",
              softwarePreferred: ""
            })
          } else if (mfaPreference === 'SOFTWARE_TOKEN_MFA'){
            document.getElementById("radioSt").checked = true;
            setCurrentPreference({
              smsEnable: "true",
              smsPreferred: "",
              softwareEnable: "true",
              softwarePreferred: "true"
            })
          }
        } else if (mfaTypes.includes('SMS_MFA')) {
          if (mfaPreference === 'SMS_MFA'){
            document.getElementById("radioSMS").checked = true;
            setCurrentPreference({
              smsEnable: "true",
              smsPreferred: "true",
              softwareEnable: "",
              softwarePreferred: ""
            })
          }
        } else if (mfaTypes.includes('SOFTWARE_TOKEN_MFA')) {
          document.getElementById("StStatus").innerHTML = "Enabled"
          if (mfaPreference === 'SOFTWARE_TOKEN_MFA'){
            document.getElementById("radioSt").checked = true;
            setCurrentPreference({
              smsEnable: "",
              smsPreferred: "",
              softwareEnable: "true",
              softwarePreferred: "true"
            })
          }
        }
      }
    }, [mfaTypes, mfaPreference]);

    const defineNewPreference = () => {
      let rtn = {
        smsEnable: "",
        smsPreferred: "",
        softwareEnable: "",
        softwarePreferred: ""
      }

      if(newPhone && document.getElementById("StStatus").innerHTML === "Enabled"){
        rtn.smsEnable = "true";
        rtn.softwareEnable = "true";
        if (document.getElementById("radioSMS").checked){
          rtn.smsPreferred = "true";
          setNewPreference(rtn);
        } else if (document.getElementById("radioSt").checked){
          rtn.softwarePreferred = "true";
          setNewPreference(rtn);
        }
      } else if (newPhone) {
        rtn.smsEnable = "true";
        if (document.getElementById("radioSMS").checked){
          rtn.smsPreferred = "true";
          setNewPreference(rtn);
        } else if (document.getElementById("radioSt").checked){
          rtn.softwarePreferred = "true";
          setNewPreference(rtn);
        }
      } else if (document.getElementById("StStatus").innerHTML === "Enabled") {
        rtn.softwareEnable = "true";
        if (document.getElementById("radioSt").checked){
          rtn.softwarePreferred = "true";
          setNewPreference(rtn);
        } else if (document.getElementById("radioSMS").checked){
          rtn.smsPreferred = "true";
          setNewPreference(rtn);
        }
      }
    }

    const hideAlert1 = () => {
      setAlert1State({ alert: null });
    };
    const hideAlert2 = () => {
      setAlert2State({ alert: null });
    };
    const hideAlert3 = () => {
      setAlert3State({ alert: null });
      history.replace("/");
      window.location.reload();
    };

    const confirmAlert1 = () => {
      setAlert1State({
          alert: (
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="There is an existing MFA software enabled already!"
              onCancel={() => hideAlert1()}
              onConfirm={() => {hideAlert1(); stSetup();}}
              showCancel
              confirmBtnBsStyle="hensall"
              confirmBtnText="Proceed"
              cancelBtnBsStyle="hensall-cancel"
              cancelBtnText="Cancel"
              btnSize=""
          >
            Are you sure you want to overwrite the existing MFA Software?<br /> <br />
            You wont be able to revert this!
          </ReactBSAlert>
          )
      });
    };

    const confirmAlert2 = () => {
      setAlert2State({
        alert: (
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={"Are you sure?"}
              onConfirm={() => {
                setNewPhone('');
                updatePhone({phone: ''});

                if (mfaTypes && mfaTypes.includes("SOFTWARE_TOKEN_MFA")) {
                  setNewPreference({
                    smsEnable: "",
                    smsPreferred: "",
                    softwareEnable: "true",
                    softwarePreferred: "true"
                  });
                } else {
                  setNewPreference({
                    smsEnable: "",
                    smsPreferred: "",
                    softwareEnable: "",
                    softwarePreferred: ""
                  });
                }

                hideAlert2();
                getMfaPreference();
              }}
              onCancel={() => hideAlert2()}
              showCancel
              confirmBtnBsStyle="hensall-cancel"
              confirmBtnText="Yes, I'm sure!"
              cancelBtnBsStyle="hensall"
              cancelBtnText="Cancel"
              btnSize=""
          >
            You wont be able to reverse this! 
          </ReactBSAlert>
        )
      });
    };

    const confirmAlert3 = () => {
      setAlert3State({
          alert: (
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="You are authenticated with a Hensall network account"
              onConfirm={() => {hideAlert3();}}
              confirmBtnBsStyle="hensall"
              confirmBtnText="Ok"
              btnSize=""
          >
            You cannot use Hensall Login's MFA settings. To change these, please do it on your Hensall network platform or contact your administrator for further assistance.
          </ReactBSAlert>
          )
      });
    };

    const handleSmsSave = (e) => {
      e.preventDefault();
      if (phone !== newPhone && regexPhoneLenght.test(newPhone)) {
        setLoading(true)
        updatePhone({phone: newPhone})

        if (mfaTypes && mfaTypes.includes("SOFTWARE_TOKEN_MFA")) {
          setNewPreference({
            smsEnable: "true",
            smsPreferred: "true",
            softwareEnable: "true",
            softwarePreferred: ""
          });
        } else {
          setNewPreference({
            smsEnable: "true",
            smsPreferred: "true",
            softwareEnable: "",
            softwarePreferred: ""
          });
        }
      } else if (regexPhoneOnlycc.test(newPhone)) {
        errorAlertIvalidPhone()
      } else {
        errorAlertIvalidPhone()
      }
    }

    const handleSmsDelete = (e) => {
      e.preventDefault();
      if (phone !== '' && phone !== undefined) {
        confirmAlert2();
      } else {
        errorAlertCantDeletePhone()
      }
    }

    const handleStSave = (e) => {
      e.preventDefault();
      if (mfaTypes && mfaPreference) {
        if (mfaTypes.includes("SOFTWARE_TOKEN_MFA")){
          confirmAlert1();
        } else {
          stSetup();
        }
      } else {
        stSetup();
      }
    }

    const handleSave = (e) => {
      e.preventDefault();
      if (mfaTypes) {
        setLoading(true)
        defineNewPreference();
      } else {
        errorAlertCantSavePreference()
      }
    }

    return (
      <>
        {alert1State.alert}
        {alert2State.alert}
        {alert3State.alert}
        <ProfileHeader />
        <Container className="mt--8 pb-3" fluid>
          <Card className="card-profile col-12 col-md-9 col-lg-8 col-xl-7 mx-0 mx-sm-auto w-100 my-4 p-4 p-lg-2">
            <CardBody className="p-0 p-sm-3">
              <FormGroup className="mx-0 mt-3 px-0">
                <CardTitle className="font-weight-bold h2 mb-4 text-center">MFA by SMS</CardTitle>
                <Row className="my-3 mx-0 px-0 px-sm-3">
                  <Col className="my-auto col-2 px-0 mx-0 text-xl-center">
                    <label
                      className="form-control-label my-auto"
                      htmlFor="phone"
                    >
                    Phone:
                    </label>
                  </Col>
                  <Col className="col-10 px-0 mx-0">
                    <PhoneInput
                      name="phone"
                      id="phone"
                      international
                      defaultCountry="CA"
                      onChange={setNewPhone}
                      className="pr-0"
                      value={newPhone}
                      >
                    </PhoneInput>
                  </Col>
                </Row>
                <Row className="justify-content-center justify-content-sm-end my-3 px-3">
                  <Button
                      className="btn btn-hensall"
                      onClick={handleSmsSave}
                  >
                      Save
                  </Button>
                  <Button
                      className="btn btn-hensall-cancel"
                      onClick={handleSmsDelete}
                  >
                      delete
                  </Button>
                </Row>
              </FormGroup>
              <hr />
              <FormGroup className="mx-0 mt-5 px-0">
                <CardTitle className="font-weight-bold h2 mb-4 text-center">MFA by Software</CardTitle>
                <Row className="my-3 mx-0 px-0 px-sm-3">
                  <Col className="my-auto col-2 px-0 mx-0 text-xl-center">
                    <label 
                      className="form-control-label px-0" 
                      style={{
                        margin: "auto",
                        padding: "0.5em",
                        textAlign: "left"
                      }}
                      htmlFor="">
                      Status:
                    </label>
                  </Col>
                  <Col className="col-10 px-0 mx-0">
                    <p
                      name="StStatus"
                      id="StStatus"
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        padding: "0.5em",
                        border: "solid 1px black",
                        color: "#ffff",
                        fontWeight: "500",
                        textShadow: ".5px .5px 2px #000",
                        backgroundColor: "#CCCC",
                        border: "1px solid #dee2e6",
                        borderRadius: "0.25rem",
                        boxShadow: "0 3px 2px rgb(233 236 239 / 5%)",
                      }}>
                      Disabled
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center justify-content-sm-end my-3 px-3">
                  <Button
                    className="btn btn-hensall"
                    onClick={handleStSave}
                  >
                    Setup
                  </Button>
                </Row>
              </FormGroup>
              <hr />
              <FormGroup className="mx-0 mt-5 mb-0 px-0">
                <CardTitle className="font-weight-bold h2 mb- text-center">MFA Preference</CardTitle>
                <Row className="mb-5 mb-md-4 mx-0 mx-lg-3 px-0 px-md-2 px-lg-5">
                  <p className='col-12 form-control-label'>Choose your preferred MFA type:</p>
                  <div className="col-12 custom-control custom-radio mb-3">
                    <input
                      className="custom-control-input"
                      id="radioSMS"
                      name="mfaPreference"
                      type="radio"
                      value="SMS_MFA"
                      disabled
                    />
                    <label className="custom-control-label" htmlFor="radioSMS">
                      By SMS (to your saved phone number)
                    </label>
                  </div>
                  <div className="col-12 custom-control custom-radio">
                      <input
                        className="custom-control-input"
                        id="radioSt"
                        name="mfaPreference"
                        type="radio"
                        value="SOFTWARE_TOKEN_MFA"
                        disabled
                      />
                      <label className="custom-control-label" htmlFor="radioSt">
                        By authentication software (Google authenticator, Authy, etc)
                      </label>
                  </div>
                </Row>
                <Row className="justify-content-center justify-content-sm-end mt-3 px-3">
                  <Button
                    color="hensall"
                    name="btnPreference"
                    id="btnPreference"
                    onClick={handleSave}
                  >
                    Save Preference
                  </Button>
                </Row>
              </FormGroup>
            </CardBody>
          </Card>
        </Container>
        <SoftwareToken_Setup />
      </>
    );
  };
  
  export default Profile;