/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Form.jsx
# Description: This is the detail per register of Systems of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/21/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/12/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Table,
    Alert,
    UncontrolledTooltip,
  } from "reactstrap";
  
  import SimpleHeader from "components/Headers/SimpleHeader.js";
  import { Link, useParams } from "react-router-dom";
  import SystemsContext from "context/SystemsContext";
  import useAuth from "hooks/useAuth";
  import { useForm } from "hooks/useForm";
  import NotificationContext from "context/NotificationContext";

  import LogLineFilterFormValidate from "../../../services/logLineFilterForm";

  const initialForm = {
    lines: ""
  };

  const Logs = ( ) => {

    const { detail:data, setToLog, fetchDataLogLines } = useContext(SystemsContext);

    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const { checkTokenExpiry } = useAuth();

    const { id } = useParams();

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, LogLineFilterFormValidate.validationsForm);

    const [toLines, setToLines] = useState(500);

    useEffect(() => {
        checkTokenExpiry();
        if(id){
            setToLog(id);
        }
        setForm({"lines":"500"});
        setErrors(initialForm);
    },[]);

    const handleLoadLog = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            fetchDataLogLines(form.lines);
        }
    }

    return (
      <>
        <SimpleHeader name={"Application Logs"} parentName="Applications" toUrl="applications" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                <Card>
                    <CardHeader>
                        <h3 className="mb-0">Application Log Information</h3>
                        <p className="text-sm mb-0">
                            This is the information from the system log.
                        </p>
                    </CardHeader>
                    <CardHeader>
                        <div className="align-items-center row">
                            <div className="col-10">
                                <Form>
                                    <Row>
                                        <div className="text-right col-3">
                                          <label
                                            className="form-control-label col-form-label"
                                            htmlFor="input-id"
                                            >
                                            Number of Lines <span className="text-hensall">*</span>
                                          </label>
                                        </div>
                                        <Col lg="9">
                                          <Input
                                            className="form-control"
                                            id="input-lines"
                                            placeholder=""
                                            type="text"
                                            name="lines"
                                            required="required"
                                            invalid={errors.lines !== ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={form.lines}
                                            />
                                            <div className="invalid-feedback">
                                              {errors.lines}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="text-center col-2">
                                <Button
                                  color="hensall"
                                  href=""
                                  onClick={handleLoadLog}
                                  >
                                    Load Log
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <div>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <Input
                                        className="form-control"
                                        id="input-log"
                                        placeholder=""
                                        type="textarea"
                                        name="log"
                                        rows="20"
                                        defaultValue={data.log}
                                        />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            
                            
                            <Row className="col justify-content-end">
                                <Link
                                    className="btn btn-hensall-cancel"
                                    color="default"
                                    to={"/admin/applications"}
                                    >
                                    Cancel
                                </Link>
                            </Row>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </Container>
      </>
    );
  };
  
  export default Logs;