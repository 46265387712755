/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: ProfileContext.js
# Description: This is the profile context, it contains API calls, variables and logic needed in setting up MFA.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/21/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext, useState, useEffect } from "react";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useAuth } from "hooks/useAuth";

const ProfileContext = createContext();

const ProfileProvider = ({children}) => {

    const { getBearerAcessToken, getNameUser, getCurrentUser, getJwtToken } = useAuth();

    const [mfaPreference, setMfaPreference] = useState();
    const [mfaTypes, setMfaTypes] = useState();

    const [phone, setPhone] = useState(undefined);
    const [newPhone, setNewPhone] = useState(undefined);

    // MFA preference states
    const [currentPreference, setCurrentPreference] = useState({
        smsEnable: "",
        smsPreferred: "",
        softwareEnable: "",
        softwarePreferred: ""
    });
    const [newPreference, setNewPreference] = useState();

    //Effect to update preference
    useEffect(() => {
        updateMfaPreference(newPreference)
    },[newPreference])

    // Notification and loading contexts
    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    
    // API info
    const { REACT_APP_API_URL } = process.env;
    let api = helpHttp();
    let url = REACT_APP_API_URL;


    //Error trigger function
    const errorAlertIvalidPhone = () => {
        setType("info");
        setMessage("The phone number can't be updated. It hasn't changed.");
        setStatus(1);
    }

    const errorAlertCantDeletePhone = () => {
        setType("danger");
        setMessage("The phone number can't be Deleted. There isn't any information to remove");
        setStatus(1);
    }
    const errorAlertCantSavePreference = () => {
        setType("danger");
        setMessage("No configuration found, can't save preference");
        setStatus(1);
    }

    const updatePhone = (data) => {
        setLoading(true);

        data.attributes = "phone_number;" + data.phone
        data.email = getNameUser();

        let user = getCurrentUser();
        let endpoint = url +"users/update_attribute";

        let options = {
            body: data,
            headers: {
                "content-type":"application/json",
                "Authorization": user ? "Bearer " + user : "Bearer " + getJwtToken(),
            },
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setNewPhone(data.phone)
                setPhone(data.phone)
                setLoading(false)
                setType("success");
                setMessage("Phone number updated!");
                setStatus(1);
            }else{
                setLoading(false);
                setType("danger");
                setMessage("You must type a valid phone number. Please, correct it and try again");
                setStatus(1);
            }
            return;
        })
    }

    const updateMfaPreference = () => {
        const strCurrent = JSON.stringify(currentPreference);
        const strNew = JSON.stringify(newPreference);

        const wrongPreference1 = JSON.stringify({
            smsEnable: "",
            smsPreferred: "true",
            softwareEnable: "true",
            softwarePreferred: ""
        })
        const wrongPreference2 = JSON.stringify({
            smsEnable: "true",
            smsPreferred: "",
            softwareEnable: "",
            softwarePreferred: "true"
        })


        if (!strNew) {
            setLoading(false);
            return
        }

        if (strCurrent === strNew) {
            setType("info");
            setMessage("No preference changed!");
            setStatus(1);
            setLoading(false);
            return;
        } else {

            if (strNew === wrongPreference1) {
                setType("info");
                setMessage("Can't change preference to SMS, no phone saved");
                setStatus(1);
                setLoading(false);
                return;
            } else if (strNew === wrongPreference2) {
                setType("info");
                setMessage("Can't change preference to SoftwareToken, you haven't set it up yet");
                setStatus(1);
                setLoading(false);
                return;
            }

            newPreference.accessToken = getBearerAcessToken();

            let endpoint = url +"users/mfa_preference";

            let options = {
                body: newPreference,
                headers: {"content-type":"application/json"},
                login: true
            }
            api.post(endpoint, options).then((res) => {
                if(!res.err){
                    if (res.ResponseMetadata.HTTPStatusCode == 200){
                        setLoading(false)
                        getMfaPreference();
                        setType("success");
                        setMessage("Preference Saved");
                        setStatus(1);
                    }
                }else{
                    setLoading(false);
                    setType("danger");
                    setMessage("Currently the service is presenting interruptions. Try again later, if error persists please send an email to HensallLoginSupport@hdc.on.ca with subject: 'Service interruption Error OL-04'.");
                    setStatus(1);
                }
                return;
            })
        }
    }


    const getMfaPreference = () => {
        const token = getJwtToken();

        let endpoint = url +"users/token_verification";
        let options = {
            body: { accessToken: token },
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err && (res.PreferredMfaSetting || res.UserMFASettingList || res.UserAttributes)){
                setMfaPreference(res.PreferredMfaSetting)
                setMfaTypes(res.UserMFASettingList)
                res.UserAttributes.map((attr) => {
                    if (attr.Name === "phone_number") {
                        setPhone(attr.Value);
                        setNewPhone(attr.Value);
                    }
                });
            }else{
                setMfaTypes(undefined)
                setMfaPreference(undefined)
                setLoading(false);
                setType("info");
                setMessage("MFA unavailable or optional, no preference found.");
                setStatus(1);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setType('danger')
            setMessage("Can't load data")
            setStatus(1)
        });
    }

    const data = { getMfaPreference, updatePhone, errorAlertIvalidPhone, errorAlertCantDeletePhone, errorAlertCantSavePreference,
                   setNewPhone, setCurrentPreference, setNewPreference,
                   phone, newPhone, mfaPreference, mfaTypes, 
                   currentPreference};

    return <ProfileContext.Provider value={data}>{children}</ProfileContext.Provider>;
}

export { ProfileProvider };
export default ProfileContext;