/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AuthHeader.js
# Description: This file contains the header of the Auth section/layout, including the main texts and the background gradient.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 01/27/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

import NotificationAlert from "react-notification-alert";
import Notification from "../../services/notification";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import Loading from "components/Loading/Loading.js";
import '../../assets/css/custom/bg-gradient.css'
import '../../assets/css/custom/scroll.css'

function AuthHeader({ title, lead1, lead2 }) {

  const notificationAlertRef = useRef(null);

  const { status, type, message, setStatus } = useContext(NotificationContext);
  const [notificationCreated, setNotificationCreated] = useState(false);

  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    if(status){
        Notification.viewNotification(type, message, notificationAlertRef);
        setNotificationCreated(true);
        setStatus(0);
    }
  },[status]);

  useEffect(() => {
    if (notificationCreated) {
      const notificationContainer = document.getElementById("notificationWrapper");
      if (notificationContainer) {
        const allNotifications = notificationContainer.getElementsByClassName("col-sm-4");
        for (let i = 0; i < allNotifications.length; i++) {
          allNotifications[i].classList.replace("col-sm-4", "col-lg-5");
        }
      }
      // Reset the notificationCreated state to false
      setNotificationCreated(false);
    }
  }, [notificationCreated])

  return (
    <>
      <div className="rna-wrapper" id="notificationWrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {loading ? <Loading />:""}
        <Container>
        <div className="header-body text-center pt-4">
            <Row className="justify-content-center">
              <Col className="px-5 text-white"  sm='12' md="9" lg="7" xl="6">
                {title ? <h1 className="text-white mb-3">{title}</h1> : null}
                {lead1 ? <p className="text-lead">{lead1}</p> : null}
                {lead2 ? <p className="text-lead">{lead2}</p> : null}
              </Col>
            </Row>
          </div>
        </Container>
        {/* <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div> */}
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
