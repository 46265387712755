/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: List.jsx
# Description: This is Audit main list base component with pagination and functional buttons.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 01/02/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import AuditContext from "../../../context/AuditContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import HttpCodes from "./HttpCodes";
import NotificationContext from "context/NotificationContext";

import { useForm } from "hooks/useForm";
import useAuth from "hooks/useAuth";
import AuditFilterFormValidate from "../../../services/auditFilterForm.js";

import {
  Card,
  CardHeader,
  Alert,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  Form,
  Input
} from "reactstrap";

import { Link } from "react-router-dom";

const initialForm = {
  date_initial: "",
  date_final: "",
};

const { SearchBar } = Search;

function List( ) {

  const { db:data, setDetail, setToUpdate, fetchDataFilter, fetchDataDownload, page, setPage, loadModalHttpCodes } = useContext(AuditContext);
  const { setMessage, setStatus, setType } = useContext(NotificationContext);
  const { checkTokenExpiry } = useAuth();

  let emptysearchFlag = false;

  //paginator
  const initialSizePerPage = parseInt(localStorage.getItem('sizePerPage')) || 10;
  const [sizePerPage, setSizePerPage] = useState(initialSizePerPage);
  
  useEffect(() => {
    localStorage.setItem('sizePerPage', sizePerPage);
  }, [sizePerPage]);

  const pagination = paginationFactory({
    sizePerPage: sizePerPage,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              value={currSizePerPage}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                onSizePerPageChange(value, 1); // Reset page to 1 when size changes
                setSizePerPage(value); // Update state and local storage
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const {
    form,
    errors,
    setForm,
    setErrors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, AuditFilterFormValidate.validationsForm);

  useEffect(() => {
    checkTokenExpiry();
    setForm(data);
    setErrors(initialForm);
    setDetail({});
    setToUpdate(0);
  },[]);

  const handleFilter = (e) => {
    e.preventDefault();
    let valid = handleSubmit(e);
    if(valid){
      fetchDataFilter(form);
    }
  }
  const handleDownload = (e) => {
    e.preventDefault();
    let valid = handleSubmit(e);
    if(valid){
      fetchDataDownload(form);
    }
  }

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
          <Link className='btn btn-hensall btn-sm'
            color="hensall"
            to={"/admin/audit/detail/"+row.id}
          >
          Detail
          </Link>
        </>
    );
  };

  const handleFilterChange = (txt) => {
    if (txt !== '') form.search = txt;
    return;
  }

  const NoDataIndication = () => (
    <Alert color="secondary" className="mt-2 mb-1 text-center">
      Search input didn't match any register of the table.
    </Alert>
  );

  return (
    <>
    <SimpleHeader name="Audit" toUrl="audit" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
            <CardHeader>
              <div className="align-items-center row">
                <div className="col-12 col-md-5 col-lg-4 col-xl-4">
                  <h3 className="mb-0">Audit Result List</h3>
                  <p className="text-sm mb-0">
                    This is a list of Audit results where can be reviewed actions performed in the system.
                  </p>
                </div>
                <div className="col-12 mt-2 col-md-7 col-lg-4 col-xl-5">
                  <Form>
                    <Row className="align-items-center">
                      <Col className="text-left col-12 col-lg-4 col-xl-1 align-items-center my-1">
                        <label
                          className="form-control-label my-0"
                          htmlFor="input-date_initial"
                          >
                          Start Date
                        </label>
                      </Col>
                      <Col className="col-12 col-lg-8 col-xl-5 px-3 px-md-2 px-lg-4">
                        <Input
                          className="form-control"
                          id="input-date_initial"
                          placeholder=""
                          type="date"
                          name="date_initial"
                          required="required"
                          invalid={errors.date_initial !== ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          />
                          <div className="invalid-feedback">
                            {errors.date_initial}
                          </div>
                      </Col>
                      <Col className="text-left col-12 col-lg-4 col-xl-1 align-items-center my-1">
                        <label
                          className="form-control-label my-0"
                          htmlFor="input-date_final"
                          >
                          End Date
                        </label>
                      </Col>
                      <Col className="col-12 col-lg-8 col-xl-5 px-3 px-md-2 px-lg-4">
                        <Input
                          className="form-control"
                          id="input-date_final"
                          placeholder=""
                          type="date"
                          name="date_final"
                          required="required"
                          invalid={errors.date_final !== ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          />
                          <div className="invalid-feedback">
                            {errors.date_final}
                          </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="text-center align-itmes-center col-12 mt-3 my-lg-auto text-md-right text-lg-center col-lg-4 col-xl-3">
                  <Button
                    className="mx-2 my-0 my-xl-1"
                    color="hensall"
                    href=""
                    onClick={handleFilter}
                    >
                      Filter
                  </Button>
                  <Button
                    className="mx-2 my-0 my-xl-1"
                    color="hensall"
                    href=""
                    onClick={handleDownload}
                    >
                      Download
                  </Button>
                </div>
              </div>
            </CardHeader>
            {data.length > 0 ? ( 
              <>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "ID",
                    sort: true,
                  },
                  {
                    dataField: "date_operation",
                    text: "DATE",
                    sort: true,
                    formatter: (cell) => {
                      const wholeDate = cell.split('T');
                      const newDate = wholeDate[0]?.split('-'); 
                      return <>{
                        newDate
                        ? [newDate[1], newDate[2], newDate[0]].join('/') + ' ' + wholeDate[1]
                        : cell.replace('T', ' ')
                      }</>
                    },                  
                  },
                  {
                    dataField: "user_operation",
                    text: "USER",
                    sort: true,
                  },
                  {
                    dataField: "method_operation",
                    text: "METHOD",
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "STATUS",
                    sort: true,
                    style: {
                      cursor: "pointer",
                    },
                    events: {
                        onClick:(e) => {
                          e.preventDefault();
                          loadModalHttpCodes();
                        },
                    }
                  },
                  {
                    dataField: "system",
                    text: "SYSTEM",
                    sort: true,
                  },
                  {
                    dataField: "action",
                    text: "ACTION",
                    formatter: linkFollow,
                    sort: false,
                  },
                  {
                    dataField: "url",
                    text: "URL",
                    formatter: linkFollow,
                    hidden: true,
                  }
                ]}
                search
                >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                          onSearch={(filterText) => {
                            props.searchProps.onSearch(filterText);
                            handleFilterChange(filterText);
                          }}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      noDataIndication={NoDataIndication}
                    />
                  </div>
                )}
              </ToolkitProvider>
              </> 
            ) : (
              <CardBody>
                  <Alert color="secondary" className="m-2">
                    There is no information to display in this section of <strong>Audit !</strong>
                  </Alert>
                </CardBody>
            )}
            </Card>
          </div>
        </Row>
        </Container>
        <HttpCodes />
    </>
  );
}

export default List;