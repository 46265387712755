/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: 
# Description: This helper file creates the http-request base structure and provides functions per http method using a customized Fetch.
               This methods are used on audit module to download a file from the browser.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import authService from "services/auth";

export const helpHttpFile = () => {
    const customFecth = (endpoint, options) => {

        if(!options.login){
            authService.checkTokenExpiry();
        }

        const deaultHeader = {
            accept: "application/json"
        }

        let user = authService.getCurrentUser()

        if (user === JSON.parse(localStorage.getItem("token_hensall"))){
            user = null;
        }
        const authorizationHeader = {
            Authorization : user ? "Bearer " + authService.getCurrentUser() : user
        }

        const controller = new AbortController();
        options.signal = controller.signal;

        options.method = options.method || "GET";
        options.headers = options.headers ? {...deaultHeader, ...options.headers} : deaultHeader;

        if(!options.bearer){
            options.headers = {...options.headers, ...authorizationHeader};
        }        


        options.body = JSON.stringify(options.body) || false;
        if(!options.body) delete options.body;

        options.maxContentLength = Infinity
        options.maxBodyLength = Infinity

        setTimeout(() => controller.abort(), 10000);

        return fetch(endpoint, options)
        .then((res) => 
            res.text().then((text) => {
                return text
            })
        )
        .catch(err=>{
            return Promise.reject({
                err: true,
                body: err,
                status: "500",
                statusText: err.message || "Ocurrio un Error"
            })
        })

    }
    const get = (url = {}) => {
        let options = {
            method: "GET"
        };
        return customFecth(url, options);
    }
    const post = (url, options = {}) => {
        options.method = "POST";
        return customFecth(url, options);
    }
    return {
        get,
        post
    }
}