/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AuthFooter.js
# Description: This file is the footer that appears at the authentication section/layout.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import '../../assets/css/custom/bg-gradient.css'

function AuthFooter({ clss }) {
  return (
    <>
      <footer className={clss} id="footer-main">
        <Row className="w-100 m-0 px-1 px-sm-5">
          <Col col="12" md="5" lg="4" className="my-2 my-md-0">
            <div className="copyright text-center text-md-left text-lg-right text-white row">
              <span className="col-12">
                © Copyright {new Date().getFullYear()}{" "} 
                <a
                  className="ml-1 footer-links"
                  href="https://hensallco-op.ca/"
                  target="_blank"
                >
                  Hensall Co-op.
                </a>
              </span>
              <span className="col-12">
                All Rights Reserved.
              </span>
            </div>
          </Col>
          <Col col="12" md="3" lg="4" className="my-2 my-md-0">
            <div className="copyright text-center text-white">
              Hensall Login App v.1.1
            </div>
          </Col>
          <Col col="12" md="4" className="my-2 my-md-0">
            <Nav className="nav-footer text-center justify-content-center justify-content-md-end justify-content-lg-start">
              <NavItem className="px-1">
                <NavLink
                  className="footer-links px-0"
                  href="https://hensallco-op.ca/Privacy-Policy.htm"
                  target="_blank"
                >
                  Privacy Policy
                </NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink
                  className="footer-links px-0"
                  href="https://hensallco-op.ca/Legal.htm"
                  target="_blank"
                >
                  Legal
                </NavLink>
              </NavItem>
              <NavItem className="px-1">
                <NavLink
                  className="footer-links px-0"
                  to="/auth/FAQs"
                  tag={Link}
                >
                  FAQs
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    </>
  );
}

export default AuthFooter;
