/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: NotificationContext.js
# Description: This is a base template file that contains logic and functions to provide the notifications functionality.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createContext, useState } from "react";

const NotificationContext = createContext();

const NotificationProvider = ({children}) => {

    const [status, setStatus] = useState(0);
    const [message, setMessage] = useState();
    const [type, setType] = useState();
    const data = { status, type, message, setMessage, setStatus, setType };
    return <NotificationContext.Provider value={data}>{children}</NotificationContext.Provider>;
}

export { NotificationProvider };
export default NotificationContext;