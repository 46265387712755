import {useEffect} from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import { useForm } from "hooks/useForm";
import FieldsFormValidate from "../../../services/optionForm";
const initialForm = {
    name: "",
    field_id: 0,
    id: 0
  };
function UpdateOption({load, setLoad, handleModalUpdate, updateOption, dataField}) {

    const {
        form,
        errors,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, FieldsFormValidate.validationsForm);

    const handleUpdateOption = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            updateOption(form);
            setLoad(!load)
        }
    }

    useEffect(()=> {
        const {id, field_id} = dataField
        setForm({...form, id, field_id})
    }, [form.name])

    useEffect(()=> {
        setErrors(initialForm)
    }, [])

    return(
        <>
        <Modal className="modal-dialog-centered" size="md" isOpen={load} toggle={handleModalUpdate} > <div className="modal-header bg-gradient-hensall">
            <h6 className="modal-title text-white" id="modal-title-default">Edit option</h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div>
                        <Row>
                            <Col lg="12">
                            <FormGroup>
                                    <label className="form-control-label" htmlFor="input-username">Name option</label>
                                    <Input 
                                        className="form-control"
                                        id="input-option"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={errors.name !== ""}
                                        defaultValue={dataField?.name}
                                    >
                                    </Input>
                                    <div className="invalid-feedback">{errors.name}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button color="hensall" onClick={handleUpdateOption}>Save</Button>
                    <Button className="btn btn-hensall-cancel" color="default" onClick={handleModalUpdate}>Cancel</Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default UpdateOption;