/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: addUsersAppForm.js
# Description: This file contains a basic form validation for a login form. It is not used.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/29/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {

    let errores = {};

    if (!form.username?.trim()) {
        errores.username = "Please the field is required.";
    }else{
        errores.username = "";
    }

    if (!form.password?.trim()) {
        errores.password = "Please the field is required.";
    }else{
        errores.password = "";
    }

    return errores;
    
};

const AddUsersAppForm = {
    validationsForm,
};
  
export default AddUsersAppForm;