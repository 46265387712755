/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: LoadingContext.js
# Description: This is the base context that provides the loading animation for specific routes.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createContext, useState } from "react";

const LoadingContext = createContext();

const LoadingProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const data = { loading, setLoading };
    return <LoadingContext.Provider value={data}>{children}</LoadingContext.Provider>;
}

export { LoadingProvider };
export default LoadingContext;