/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: notifications.js
# Description: This file contains a notification validation and prebuild for display.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

const viewNotification = (type, message, notificationAlertRef) => {

    function handleResize() {
      // Update the state with the new screen size
      if (window.innerWidth <= 768) {
          // It's a small screen (mobile)
          return('tc');
      } else {
          // It's a large screen
          return('tr');
      }
    }

    let options = {
      place: handleResize(),
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Information
          </span>
          <span data-notify="message">
            { message }
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
};

const Notification = {
  viewNotification,
};

export default Notification;