import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";
import Form from "./Form";

import { FieldsProvider } from "context/FieldsContext";
// import { UsersProvider } from "context/UsersContext";

const Index = () => {

    return(
        <>
        <FieldsProvider>
        <Switch>
            <Route exact path="/admin/fields">
                <List />
            </Route>
            <Route exact path="/admin/fields/detail/:id" >
                <Form />
            </Route>
            <Route exact path="/admin/fields/add" >
                <Form />
            </Route>
            <Redirect from="*" to="/" />
        </Switch>
        </FieldsProvider>
        </>
    );
}

export default Index;