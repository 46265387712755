/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AuditContext.js
# Description: This is the context provider of the audit module. Containig logic, API calls, and variables needed.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 01/02/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { helpHttpFile } from "helpers/helpHttpFile";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { TYPES } from "actions/genericAction";
import { genericReducer, genericInitialState } from "../reducers/genericReducer";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";
import { useAuth } from "hooks/useAuth";

const AuditContext = createContext();

const AuditProvider = ({children}) => {

    const { getNameUser } = useAuth();

    const [toDetail, setToDetail] = useState();
    const [toUpdate, setToUpdate] = useState();
    const [detail, setDetail] = useState({});
    const [module, setModule] = useState();

    const history = useHistory();
    const [page, setPage] = useState(1);

     // stastes for HTTPCodes modal
     const [viewModalHttpCodes, setViewModalHttpCodes] = useState();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    const { REACT_APP_API_URL } = process.env;
    const [state, dispatch] = useReducer(genericReducer, genericInitialState);
    const { db } = state;

    let api = helpHttp();
    let apiFile = helpHttpFile();
    let url = REACT_APP_API_URL+"audit";
    let urlFetch = REACT_APP_API_URL+"audit";

    useEffect(() => {
        fetchData();
    },[]);

    useEffect(() => {
        if(toUpdate && toUpdate != 0){
            fetchDataDetail();
        }
    },[toUpdate]);

    // Open and close HTTPCodes modal functions
    const loadModalHttpCodes = () => {
        setViewModalHttpCodes(true);
    }
    const closeModalHttpCodes = () => {
        setViewModalHttpCodes(false);
    }

    const fetchData = () => {
        setLoading(true);
         api.get(url).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
            }else{
                dispatch({ type: TYPES.NO_DATA });
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setType('danger')
            setMessage("Can't load data")
            setStatus(1)
        });
    };

    const fetchDataFilter = (data) => {
        setLoading(true);
        let endpoint = url+"/list";
        let newData = data;
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
                setType("success");
                setMessage("The search result has been loaded correctly.");
                setStatus(1);
            }else{

            }
            setLoading(false);
        })
    };
    const fetchDataDownload = (data) => {
        setLoading(true);
        let endpoint = url+"/download";
        let newData = data;
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        apiFile.post(endpoint, options).then((res) => {
            if(res){
                saveData(res, "Audit_results_from_"+ data.date_initial + "_to_"+ data.date_final +".csv")
                setType("success");
                setMessage("The search result has been downloaded.");
                setStatus(1);
            }
            setLoading(false);
        }).catch(err => {
            if (err.err) {
                setType("danger");
                setMessage("Currently the service is presenting interruptions. Try again later, if error persists please send an email to HensallLoginSupport@hdc.on.ca with subject: 'Service interruption Error OL-05'.");
                setStatus(1);
            }
            setLoading(false);
        })
    };

    const saveData = (function () {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
            const blob = new Blob([data], {type: "octet/stream"}),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());

    const fetchDataDetail = () => {
        setLoading(true);
        url = url+"/"+toUpdate;
        api.get(url).then((res) => {
            setDetail(res);
            setLoading(false);
        });
    };

    const data = {db, detail, setToDetail, setToUpdate, module, setModule, setDetail, fetchDataFilter, fetchDataDownload, page, setPage,
                  viewModalHttpCodes, setViewModalHttpCodes, loadModalHttpCodes, closeModalHttpCodes, };

    return <AuditContext.Provider value={data}>{children}</AuditContext.Provider>;
}

export { AuditProvider };
export default AuditContext;