/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: InitContext.js
# Description: This is the UAM initialization context, it contains API calls, variables and logic needed for initiate the UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/28/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/16/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext } from "react";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";

import { useAuth } from "hooks/useAuth";

const InitContext = createContext();

const InitProvider = ({children}) => {

    const history = useHistory();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);

    const { REACT_APP_API_URL } = process.env;

    const { isAuthenticated, setAuth } = useAuth();

    let api = helpHttp();
    let url = REACT_APP_API_URL;

    const init = (token) => {
        if(!isAuthenticated()){
            setLoading(true);
            let endpoint = url+"auth/init/hlogin/"+token;
            let options = {
                bearer: true
            }
            api.get(endpoint, options).then((res) => {
                if(!res.err){
                    if(res.access_token){
                       localStorage.setItem("token_hensall_login", JSON.stringify(res.access_token));
                       history.push('/admin/home/');
                       setAuth(true);
                    }else{
                        setType("danger");
                        setMessage("Error in Login Process");
                        setStatus(1);
                        history.push('/auth/login');
                    }
                }else{
                    setType("danger");
                    setMessage("Error in Login Process");
                    setStatus(1);
                    history.push('/auth/login');
                }
            });
        }else{
            history.push('/auth/home');
        }
    }; 

    const data = { init };

    return <InitContext.Provider value={data}>{children}</InitContext.Provider>;
}

export { InitProvider };
export default InitContext;