/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Form.jsx
# Description: This is the detail per register of Permissions of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/13/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
  } from "reactstrap";
  
  import SimpleHeader from "components/Headers/SimpleHeader.js";
  import NotificationContext from "context/NotificationContext";
  import { Link, useParams } from "react-router-dom";
  import PermissionsContext from "context/PermissionsContext";
  import PermissionsFormValidate from "../../../services/permissionsForm";
  import { useForm } from "hooks/useForm";
  import useAuth from "hooks/useAuth";

  const initialForm = {
    description: "",
  };


  const Formulario = ( ) => {

    const { detail:data, updateData, saveData, setModule, module, setToDetail,setDetail, setToUpdate,
    applications, toApplications, setToApplications } = useContext(PermissionsContext);

    const {checkTokenExpiry} = useAuth();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, PermissionsFormValidate.validationsForm);

    const { id } = useParams();

    useEffect(() => {
        checkTokenExpiry();
        if(id){
            setToDetail(id);
            setToUpdate(id);
            setModule("update");
        }else{
            setModule("add");
        }
    },[]);

    useEffect(() => {
        setForm(data);
        setErrors(initialForm);
    },[data]);

    const handleSelectApp = (e) => {
        const { value } = e.target;
        setToApplications(value);
    }
    
    const handleUpdate = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            if (JSON.stringify(data) === JSON.stringify(form)){
                setType('info')
                setMessage("No changes detected. Please cancel the process or change the information to update");
                    setStatus(1);
            } else {
                updateData(form);
            }
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            saveData(form);
        }
    }

    return (
      <>
        <SimpleHeader name={"Permission " + (module ? (module[0].toUpperCase() + module.slice(1)) : "")} parentName="Permissions" toUrl="permissions" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                <Card>
                    <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Permission Form</h3>
                              <p className="text-sm mb-0">
                                This is a Permission form where you can create or update a Permission.
                            </p>
                          </Col>
                          <Col className="text-right" xs="4">
                            <Input 
                              className="form-control"
                              id="input-type"
                              type="select"
                              name="type"
                              value={toApplications}
                              onChange={handleSelectApp}
                              onBlur={handleSelectApp}
                              disabled={module == "update" ? true : false}
                              >
                              <option value="" hidden>Select an Application</option>
                              {applications.map(item => (
                                  <option key={item.id} value={item.id}>
                                      {item.text}
                                  </option>
                              ))};
                            </Input>
                          </Col>
                        </Row>
                      </CardHeader>
                    <CardBody>
                        <Form>
                            <h6 className="heading-small text-muted mb-4">
                            Information
                            </h6>
                            <div className="pl-lg-4">
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-description"
                                        >
                                        Description
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-description"
                                        placeholder=""
                                        type="text"
                                        name="description"
                                        required="required"
                                        invalid={errors.description !== ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        defaultValue={data.description}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.description}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="col justify-content-end">
                                {module == "update" ? (
                                    <Button
                                        color="hensall"
                                        href=""
                                        onClick={handleUpdate}
                                        >
                                        Update
                                    </Button>
                                ) : (
                                    <Button
                                        color="hensall"
                                        href=""
                                        onClick={handleSave}
                                        >
                                        Save 
                                    </Button>
                                )}
                                <Link
                                    className="btn btn-hensall-cancel"
                                    color="default"
                                    to={"/admin/permissions"}
                                    >
                                    Cancel
                                </Link>
                            </Row>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </Container>
      </>
    );
  };
  
  export default Formulario;