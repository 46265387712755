/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Menu.jsx
# Description: This is FAQS main list menu, base component with articles list and redirections.
# Created by: Juan David Olivares Padilla
# Creation Date: 02/06/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Collapse,
} from "reactstrap";

function Menu() {
    
    const [openedCollapses, setOpenedCollapses] = useState(["collapseOne"])
    
    const collapsesToggle = (collapse) => {
        let openedCollapsesCopy = openedCollapses;
        if (openedCollapsesCopy.includes(collapse)) {
            setOpenedCollapses([])
        } else {
            setOpenedCollapses([collapse])
        }
    };
    return(
        <>
            <Container
                style={{
                    paddingTop: "3em",
                }}
                >    
            <div className="accordion"
                style={{
                    backgroundColor: "white",
                    borderRadius: "0.375em",
                    padding: "2em",
                    marginTop: "0.5em"
                }}>
                <h1 className="text-center mb-4">Frequently Asked Questions(FAQs)</h1>
                <Card className="card-plain">
                    <CardHeader
                    role="tab"
                    onClick={() => collapsesToggle("collapseOne")}
                    aria-expanded={openedCollapses.includes(
                        "collapseOne"
                    )}
                    >
                    <h3 className="mb-0">Multi-factor Authentication(MFA)</h3>
                    </CardHeader>
                    <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes("collapseOne")}
                    >
                        <ListGroup>
                            <ListGroupItem>
                                <Link to="/auth/FAQs/article1"
                                    className="color-primary">
                                    How to setup MFA by Software Token on Firefox browser.
                                </Link>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Link to="/auth/FAQs/article2"
                                    className="color-primary">
                                    How to setup MFA by Software Token on Google Chrome browser.
                                </Link>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Link to="/auth/FAQs/article3"
                                    className="color-primary">
                                    How to setup MFA by Software Token on Microsoft Edge browser.
                                </Link>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Link to="/auth/FAQs/article4"
                                    className="color-primary">
                                    How to setup MFA by Software Token on Safari browser.
                                </Link>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Link to="/auth/FAQs/article5"
                                    className="color-primary">
                                    How to setup MFA by Software Token on IOS phones.
                                </Link>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Link to="/auth/FAQs/article6"
                                    className="color-primary">
                                    How to setup MFA by Software Token on Android phones.
                                </Link>
                            </ListGroupItem>
                        </ListGroup>
                    </Collapse>
                </Card>
            </div>
            </Container>
        </>
    );

}

export default Menu;