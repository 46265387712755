/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Admin.js
# Description: This file contains the graphic setup of the application layout after login, including sidebar logic, navigation and more.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/29/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { LoginProvider } from "context/LoginContext";
import { NotificationProvider } from "context/NotificationContext";
import { LoadingProvider } from "context/LoadingContext";
import { useAuth } from "../hooks/useAuth";
import "../assets/css/custom/app-card.css"

import routes from "routes.js";

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const { isAuthenticated, isAuthenticatedApp, validatePermissionApp, checkTokenExpiry,  handleOnlineStatusChange, BSAlert, isOnlineAlert } = useAuth();

  React.useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiry();
      handleOnlineStatusChange();
    }, 1200000);

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
        window.removeEventListener('online', handleOnlineStatusChange);
        window.removeEventListener('offline', handleOnlineStatusChange);

        clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    };
  }, [])

  

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (isAuthenticatedApp() && validatePermissionApp(prop.roles)) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };
  return (
    <>
      {BSAlert.alert}
      {isOnlineAlert.alert}
      {isAuthenticated() ? 
        (<Sidebar
            routes={routes}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            logo={{
              innerLink: "/",
              imgSrc: require("assets/img/brand/logo-hensall.png").default,
              imgAlt: "...",
            }}
        />)
        : ("")
      }
      
      <LoadingProvider>
      <NotificationProvider >
      <LoginProvider>
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {isAuthenticatedApp() ? getRoutes(routes):""}
          <Redirect from="*" to="/auth/login" />
        </Switch>
        <AdminFooter />
      </div>
      </LoginProvider>
      </NotificationProvider>
      </LoadingProvider>
      
    </>
  );
}

export default Admin;
