/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Index.js
# Description: This is React routes configuration file to provide the context & endpoints used on Profile section.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/21/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Profile from "./Profile";
import { ProfileProvider } from "context/ProfileContext";

const Index = () => {

    return(
        <>
        <ProfileProvider>
        <Switch>
            <Route exact path="/admin/mfa-settings">
                <Profile />
            </Route>
            <Redirect from="*" to="/" />
        </Switch>
        </ProfileProvider>
        </>
    );
}

export default Index;