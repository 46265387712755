/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: SimpleHeader.js
# Description: This file is a basic example from the template
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import Notification from "../../services/notification";

import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";

import Loading from "components/Loading/Loading.js";

function TimelineHeader({ name, parentName, toUrl="" }) {

  const notificationAlertRef = useRef(null);

  const { status, type, message, setStatus } = useContext(NotificationContext);
  const [notificationCreated, setNotificationCreated] = useState(false);

  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    if(status){
        Notification.viewNotification(type, message, notificationAlertRef);
        setNotificationCreated(true);
        setStatus(0);
    }
  },[status]);

  useEffect(() => {
    if (notificationCreated) {
      const notificationContainer = document.getElementById("notificationWrapper");
      if (notificationContainer) {
        const allNotifications = notificationContainer.getElementsByClassName("col-sm-4");
        for (let i = 0; i < allNotifications.length; i++) {
          allNotifications[i].classList.replace("col-sm-4", "col-lg-5");
        }
      }
      // Reset the notificationCreated state to false
      setNotificationCreated(false);
    }
  }, [notificationCreated])
  
  return (
    <>
      <div className="rna-wrapper" id="notificationWrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {loading ? <Loading />:""}
      <div className="header header-dark bg-gradient-hensall pb-6 pt-3 mt--1">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center pt-0 pb-4">
              <Col lg="6" xs="7">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <Link
                      to={"/admin/"+toUrl} >
                      <i className="fas fa-home breadcrumb-links breadcrumb-dark mx-1" />
                      <h6 className="fullcalendar-title h2 text-white d-inline-block mx-1 mb-0">
                        {parentName ? parentName : name}
                      </h6>
                    </Link>
                  </BreadcrumbItem>
                  {parentName
                  ? <BreadcrumbItem aria-current="page" className="active mt-2">
                      {name}
                    </BreadcrumbItem>
                  : ""}
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
