/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: sendEmailForm.js
# Description: This file contains a form validation logic and errors for the setup/validate email form.
# Created by: Juan David Olivares Padilla
# Creation Date: 01/20/2023

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    let errores = {};

    let regexAvoidSpecial = /^[A-Za-z0-9\ ]*$/;

    if (!form.application?.trim()) {
        errores.application = "This Field is required";
    }else{
        errores.application = "";
    }
    
    if (!form.hdcName?.trim()) {
        errores.hdcName = "This Field is required";
    } else if (form.hdcName.trim().length > 20) {
        errores.hdcName = "This Field must be only 20 characters long";
    } else if (!regexAvoidSpecial.test(form.hdcName.trim())) {
        errores.hdcName = "This Field doesn't accept special characters";
    }else{
        errores.hdcName = "";
    }
    
    if (form.hdcNumber === 0 || form.hdcNumber === '') {
        errores.hdcNumber = "This Field is required";
    } else if (form.hdcNumber < 0) {
        errores.hdcNumber = "This Field must be always positive";
    }else if (form.hdcNumber.toString().length > 6) {
        errores.hdcNumber = "This Field must be only 6 digits long";
    } else{
        errores.hdcNumber = "";
    }
    
    if ((form.application === 'ar@hdc.on.ca' && !form.accountBalance)) {
        errores.accountBalance = "This Field is required";
    } else if (form.accountBalance.toString().length > 10) {
        errores.accountBalance = "This Field must be only 10 digits long";
    }  else{
        errores.accountBalance = "";
    }

    if (!form.phone || form.phone === '+1') {
        errores.phone = "This Field is required";
    }else{
        errores.phone = "";
    }
    
    if (!form.username?.trim()) {
        errores.username = "This Field is required";
    } else if (form.username.trim().length > 20) {
        errores.username = "This Field must be only 20 characters long";
    } else{
        errores.username = "";
    }

    if (form.additionalComments.trim().length > 250) {
        errores.additionalComments = "This Field must be only 250 characters long";
    } else {
        errores.additionalComments = "";
    }

    return errores;
};


const SendEmailForm = {
    validationsForm,
};
  
export default SendEmailForm;