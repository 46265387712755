/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Users.jsx
# Description: This is the Fieldtrace username selection form for redirection to the app.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/31/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 12/07/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect } from 'react';
import AppContext from "context/AppContext";
import { useForm } from "../../../hooks/useForm";
import UsersAppForm from "../../../services/usersAppForm";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    username: "",
  };
  
function Users() {

    const { viewModalUsers, setViewModalUsers, setViewModalAddUsers, users, initFieldTrace } = useContext(AppContext);

    const {
        form,
        errors,
        setForm,
        setErrors,
        handleChangeCombo,
        handleBlurCombo,
        handleSubmit,
    } = useForm(initialForm, UsersAppForm.validationsForm);

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

     useEffect(() => {
        if(users.length > 0){
            setViewModalUsers(true);
        }
    },[users]);

    useEffect(() => {
        if(users.length === 1){
            form.username = users[0];
        }
    },[form.username]);

    const handleStart = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            initFieldTrace(form.username);
            setViewModalUsers(false);
        }
        if (users.length === 1){
            initFieldTrace(users[0]);
            setViewModalUsers(false);
        }
    }

    const handleAdd = (e) => {
        e.preventDefault();
        setViewModalUsers(false);
        setViewModalAddUsers(true)
    }

    const closeModal = (e) => {
        e.preventDefault();
        setViewModalUsers(false);
    }

    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalUsers}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    FieldTrace
                </h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div className="pl-lg-4">
                        <h3 className='text-center'>Choose FieldTrace Account</h3>
                        <p className='text-center'>Your email appears to be connected to these accounts. Select which FieldTrace user you wish to login with:</p>
                        <Row>
                            <Col lg="12">
                            <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                    >
                                    Username
                                    </label>
                                    <Input 
                                        className="form-control"
                                        id="input-username"
                                        type="select"
                                        name="username"
                                        onChange={handleChangeCombo}
                                        onBlur={handleBlurCombo}
                                        defaultValue={users.length === 1 ? users[0] : form.username}
                                        invalid={errors.username !== ""}
                                        >
                                        <option 
                                            value=''
                                            hidden
                                        >
                                        </option>
                                        {users.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.username}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleStart}
                    >
                        Continue
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleAdd}
                    >
                        Link New Account
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default Users;