/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: roles/Permissions.jsx
# Description: This permissions view is a modal with a select form to choose the permissions assigned to a rol. Used in the Roles section of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/13/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import RolesContext from "context/RolesContext";
import { useForm } from "../../../hooks/useForm";
import RolePermissionsForm from "../../../services/rolePermissionsForm";
import Select2 from "react-select2-wrapper";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    permissions: "",
  };
  
function Permissions() {

    const { module, addAssetSingle, viewModalPermissions, setViewModalPermissions, permissions, addPermissions } = useContext(RolesContext);

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleChangeCombo,
        handleChecked,
        handleBlur,
        handleBlurCombo,
        handleSubmit,
    } = useForm(initialForm, RolePermissionsForm.validationsForm);

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            addPermissions(form)
            setViewModalPermissions(false);
            reset();
        }
    }

    const closeModal = (e) => {
        e.preventDefault();
        setViewModalPermissions(false);
        reset();
    }

    const reset = () => {
        setTimeout(function(){ 
            setErrors(initialForm);
            setForm(initialForm);
            setValidateInit(false);
        }, 500);
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalPermissions}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Add Permissions
                </h6>
            </div>
            <div className="modal-body">
                <Form>
                    <Row className="px-2">
                        <Col lg="12">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-permissions"
                                >
                                Permissions
                                </label>
                                <Select2 
                                    className="form-control is-invalid"
                                    id="input-permissions"
                                    name="permissions"
                                    data={permissions}
                                    onChange={handleChangeCombo}
                                    onBlur={handleBlurCombo}
                                    defaultValue={form.permissions}
                                    value={form.permissions}
                                    invalid={errors.permissions !== ""}
                                    options={{
                                        placeholder: "Select User"
                                    }}
                                    
                                />
                                <div className="invalid-feedback">
                                    {errors.permissions}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="modal-footer pt-0 pb-3">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default Permissions;