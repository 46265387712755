/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: HttpCodes.jsx
# Description: This is an informative modal window with a list and description of HTTP codes
# Created by: Juan David Olivares Padilla
# Creation Date: 02/15/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/21/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from 'react';
import AuditContext from "../../../context/AuditContext";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/custom/table-wrapper.css"
import {
    Button,
    Modal,
    Row,
  } from "reactstrap";
  
function HttpCodes() {

    const { closeModalHttpCodes, viewModalHttpCodes } = useContext(AuditContext);

    const closeModal = (e) => {
        e.preventDefault();
        closeModalHttpCodes();
    }

    const data = [
        {
            code: 200,
            code_status: "OK",
            description: "The request succeeded."
        },
        {
            code: 201,
            code_status: "Created",
            description: "The request succeeded, and a new resource was created as a result. This is typically the response sent after POST requests, or some PUT requests."
        },
        {
            code: 202,
            code_status: "Accepted",
            description: "The request has been received but not yet acted upon. It is intended for cases where another process or server handles the request, or for batch processing."
        },
        {
            code: 203,
            code_status: "Non-Authoritative Information",
            description: "This response code means the returned metadata is not exactly the same as is available from the origin server, but is collected from a local or a third-party copy."
        },
        {
            code: 204,
            code_status: "No Content",
            description: "There is no content to send for this request, but the headers may be useful. The user agent may update its cached headers for this resource with the new ones."
        },
        {
            code: 205,
            code_status: "Reset Content",
            description: "Tells the user agent to reset the document which sent this request."
        },
        {
            code: 206,
            code_status: "Partial Content",
            description: "This response code is used when the Range header is sent from the client to request only part of a resource."
        },
        {
            code: 400,
            code_status: "Bad Request",
            description: "The server cannot or will not process the request due to something that is perceived to be a client error (e.g., malformed request syntax, invalid request message framing, or deceptive request routing)."
        },
        {
            code: 401,
            code_status: "Unauthorized",
            description: "Although the HTTP standard specifies 'unauthorized', semantically this response means 'unauthenticated'. That is, the client must authenticate itself to get the requested response."
        },
        {
            code: 402,
            code_status: "Payment Required Experimental",
            description: "This response code is reserved for future use. The initial aim for creating this code was using it for digital payment systems, however this status code is used very rarely and no standard convention exists."
        },
        {
            code: 403,
            code_status: "Forbidden",
            description: "The client does not have access rights to the content; that is, it is unauthorized, so the server is refusing to give the requested resource. Unlike 401 Unauthorized, the client's identity is known to the server."
        },
        {
            code: 404,
            code_status: "Not Found",
            description: "The server cannot find the requested resource. In the browser, this means the URL is not recognized. In an API, this can also mean that the endpoint is valid but the resource itself does not exist."
        },
        {
            code: 405,
            code_status: "Method Not Allowed",
            description: "The request method is known by the server but is not supported by the target resource. For example, an API may not allow calling DELETE to remove a resource."
        },
        {
            code: 406,
            code_status: "Not Acceptable",
            description: "This response is sent when the web server, after performing server-driven content negotiation, doesn't find any content that conforms to the criteria given by the user agent."
        },
        {
            code: 407,
            code_status: "Proxy Authentication Required",
            description: "This is similar to 401 Unauthorized but authentication is needed to be done by a proxy."
        },
        {
            code: 408,
            code_status: "Request Timeout",
            description: "This response is sent on an idle connection by some servers, even without any previous request by the client. It means that the server would like to shut down this unused connection."
        },
        {
            code: 409,
            code_status: "Conflict",
            description: "This response is sent when a request conflicts with the current state of the server."
        },
        {
            code: 410,
            code_status: "Gone",
            description: "This response is sent when the requested content has been permanently deleted from server, with no forwarding address. Clients are expected to remove their caches and links to the resource."
        },
        // {
        //     code: 411,
        //     code_status: "Length Required",
        //     description: "Server rejected the request because the Content-Length header field is not defined and the server requires it."
        // },
        // {
        //     code: 412,
        //     code_status: "Precondition Failed",
        //     description: "The client has indicated preconditions in its headers which the server does not meet."
        // },
        // {
        //     code: 413,
        //     code_status: "Payload Too Large",
        //     description: "Request entity is larger than limits defined by server. The server might close the connection or return an Retry-After header field."
        // },
        // {
        //     code: 414,
        //     code_status: "URI Too Long",
        //     description: "The URI requested by the client is longer than the server is willing to interpret."
        // },
        // {
        //     code: 415,
        //     code_status: "Unsupported Media Type",
        //     description: "The media format of the requested data is not supported by the server, so the server is rejecting the request."
        // },
        // {
        //     code: 416,
        //     code_status: "Range Not Satisfiable",
        //     description: "The range specified by the Range header field in the request cannot be fulfilled. It's possible that the range is outside the size of the target URI's data."
        // },
        // {
        //     code: 417,
        //     code_status: "Expectation Failed",
        //     description: "This response code means the expectation indicated by the Expect request header field cannot be met by the server."
        // },
        // {
        //     code: 418,
        //     code_status: "I'm a teapot",
        //     description: "The server refuses the attempt to brew coffee with a teapot."
        // },
        // {
        //     code: 421,
        //     code_status: "Misdirected Request",
        //     description: "The request was directed at a server that is not able to produce a response. This can be sent by a server that is not configured to produce responses for the combination of scheme and authority that are included in the request URI."
        // },
        // {
        //     code: 425,
        //     code_status: "Too Early Experimental",
        //     description: "Indicates that the server is unwilling to risk processing a request that might be replayed."
        // },
        // {
        //     code: 426,
        //     code_status: "Upgrade Required",
        //     description: "The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol. The server sends an Upgrade header in a 426 response to indicate the required protocol(s)."
        // },
        // {
        //     code: 428,
        //     code_status: "Precondition Required",
        //     description: "The origin server requires the request to be conditional. This response is intended to prevent the 'lost update' problem, where a client GETs a resource's state, modifies it and PUTs it back to the server, when meanwhile a third party has modified the state on the server, leading to a conflict."
        // },
        // {
        //     code: 429,
        //     code_status: "Too Many Requests",
        //     description: "The user has sent too many requests in a given amount of time ('rate limiting')."
        // },
        // {
        //     code: 431,
        //     code_status: "Request Header Fields Too Large",
        //     description: "The server is unwilling to process the request because its header fields are too large. The request may be resubmitted after reducing the size of the request header fields."
        // },
        // {
        //     code: 451,
        //     code_status: "Unavailable For Legal Reasons",
        //     description: "The user agent requested a resource that cannot legally be provided, such as a web page censored by a government."
        // },
        {
            code: 500,
            code_status: "Internal Server Error",
            description: "The server has encountered a situation it does not know how to handle."
        },
        {
            code: 501,
            code_status: "Not Implemented",
            description: "The request method is not supported by the server and cannot be handled. The only methods that servers are required to support (and therefore that must not return this code) are GET and HEAD."
        },
        {
            code: 502,
            code_status: "Bad Gateway",
            description: "This error response means that the server, while working as a gateway to get a response needed to handle the request, got an invalid response."
        },
        {
            code: 503,
            code_status: "Service Unavailable",
            description: "The server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded."
        },
        {
            code: 504,
            code_status: "Gateway Timeout",
            description: "This error response is given when the server is acting as a gateway and cannot get a response in time."
        },
        {
            code: 505,
            code_status: "HTTP Version Not Supported",
            description: "The HTTP version used in the request is not supported by the server."
        },
        {
            code: 506,
            code_status: "Variant Also Negotiates",
            description: "The server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself, and is therefore not a proper end point in the negotiation process."
        },
        {
            code: 510,
            code_status: "Not Extended",
            description: "Further extensions to the request are required for the server to fulfill it."
        },
        {
            code: 511,
            code_status: "Network Authentication Required",
            description: "Indicates that the client needs to authenticate to gain network access."
        },
    ]
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={viewModalHttpCodes}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    HTTP Codes
                </h6>
            </div>
            <div className="modal-body pb-0" style={{width: '100%',}}>
            <BootstrapTable
                wrapperClasses="table_wrapper"
                data={data}
                keyField='code'
                columns={[
                  {
                    dataField: "code",
                    text: "Code",
                  },
                  {
                    dataField: "code_status",
                    text: "Status",
                  },
                  {
                    dataField: "description",
                    text: "Description",
                  },
                ]}
            />
              
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default HttpCodes;