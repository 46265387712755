/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: SoftwareToken_SetupFt.jsx
# Description: This MFA by Software Token - Setup for First time(Ft) modal window form. For the Cognito authentication challenge resolve used during login.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/18/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/25/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import LoginContext from 'context/LoginContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NotificationContext from "context/NotificationContext";
import { useForm } from "../../../hooks/useForm";
import smsRacForm from "../../../services/smsRacForm";
import QRCode from 'react-qr-code';
import "../../../assets/css/custom/copy-to-clipboard.css"
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    verificationCode: "",
  };
  
  const { REACT_APP_ENV } = process.env;
  const issuer = "Hensall Login" + (REACT_APP_ENV === "PRODUCTION" ? "" : " " + REACT_APP_ENV.toLocaleLowerCase());

function SoftwareToken_SetupFt() {

    const { stValidateSetupFt, viewModalStSetupFt, closeModalStSetupFt, mfaSecretCode } = useContext(LoginContext);
    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    
    const {
        form,
        errors,
        setValidateInit,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, smsRacForm.validationsForm);

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

    const closeModal = (e) => {
        e.preventDefault();
        closeModalStSetupFt();
        reset();
    }

    const reset = () => {
        setTimeout(function(){ 
            setErrors(initialForm);
            setForm({});
            setValidateInit(false);
        }, 500);
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleSend(event);
        }
    };

    const handleSend = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        let data = {
            user_code: form.verificationCode,
        }
        if (valid){
            stValidateSetupFt(data)
        }
    }

    const handleCopy = () => {
        setType("success");
        setMessage("Text copied to clipboard!");
        setStatus(1);
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalStSetupFt}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Software Token Multi-factor Authentication
                </h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div>
                    <p className='title'>Please, scan this QR code in your authentication app (Google Authenticator, Authy). Then use the verification code generated to complete the setup.</p>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    {/* <CopyToClipboard text={mfaSecretCode}>
                                        <span onClick={handleCopy} 
                                            className="secretCode">{mfaSecretCode}
                                            <button
                                                type="button"
                                                className="btn"
                                                style={{backgroundColor: "#fff", padding: "0.4em .8em", margin: "0.4em 0.5em"}}
                                                onClick={handleCopy} >
                                                <i className='ni ni-single-copy-04' />
                                            </button>
                                        </span>
                                    </CopyToClipboard> */}
                                    <QRCode
                                        title={"Hensall Login " + REACT_APP_ENV}
                                        value={`otpauth://totp/${issuer}:?secret=${mfaSecretCode}&issuer=${issuer}`}
                                        bgColor={'#FFFFFF'}
                                        fgColor={'#000000'}
                                        size={256}
                                        style={{
                                            display: "block",
                                            height: "180px",
                                            width: "180px",
                                            margin: ".8em auto",
                                            padding: ".5em",
                                            border: "1px solid #4444",
                                            borderRadius: "10px"
                                        }}
                                    />
                                    <label
                                    className="form-control-label"
                                    htmlFor="verificationCode"
                                    >
                                    Verification Code
                                    </label>
                                    <Input 
                                        className="form-control"
                                        id="verificationCode"
                                        type="text"
                                        name="verificationCode"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyDown={handleKeyDown}
                                        invalid={errors.verificationCode !== ""}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.verificationCode}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleSend}
                    >
                        Send
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default SoftwareToken_SetupFt;