/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: genericAction.js
# Description: This is a base template configuration file
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

=========================================================
*/
export const TYPES = {
    READ_ALL_DATA: "READ_ALL_DATA",
    CREATE_DATA: "CREATE_DATA",
    UPDATE_DATA: "UPDATE_DATA",
    DELETE_DATA: "DELETE_DATA",
    NO_DATA: "NO_DATA",
};