/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Form.jsx
# Description: This is the detail of a register on Audit section from UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 01/02/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
  } from "reactstrap";
  
  import SimpleHeader from "components/Headers/SimpleHeader.js";
  import { Link, useParams } from "react-router-dom";
  import AuditContext from "context/AuditContext";
  import useAuth from "hooks/useAuth";

  const initialForm = {
    date_reading: "",
    truck: "",
    bulk_tank: "",
    meter_reading: "",
  };

  const Formulario = ( ) => {

    const { detail:data, setModule, module, setToDetail,setDetail, setToUpdate } = useContext(AuditContext);
    const { checkTokenExpiry } = useAuth();

    const { id } = useParams();

    useEffect(() => {
        checkTokenExpiry();
        if(id){
            setToDetail(id);
            setToUpdate(id);
            setModule("update");
        }else{
            setModule("add");
        }
    },[]);

    const dateOperation = (data) => {
        if(data) {
            const wholeDate = data.split('T');
            const newDate = wholeDate[0]?.split('-'); 

            return newDate
                    ? [newDate[1], newDate[2], newDate[0]].join('/') + ' ' + wholeDate[1]
                    : data.replace('T', ' ')
        }
    }
    

    return (
      <>
        <SimpleHeader name={"Audit Detail"} parentName="Audit" toUrl="audit" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                <Card>
                    
                    <CardBody>
                        <Form>
                            <h6 className="heading-small text-muted mb-4">
                            Information
                            </h6>
                            <div className="pl-lg-4">
                            <Row>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-id"
                                        >
                                        ID
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-id"
                                        placeholder=""
                                        type="text"
                                        name="id"
                                        disabled
                                        defaultValue={data.id}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-date_operation"
                                        >
                                        Date Operation
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-date_operation"
                                        placeholder=""
                                        type="text"
                                        name="date_operation"
                                        disabled
                                        defaultValue={dateOperation(data.date_operation)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-user_operation"
                                        >
                                        User Operation
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-user_operation"
                                        placeholder=""
                                        type="text"
                                        name="user_operation"
                                        disabled
                                        defaultValue={data.user_operation}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-method_operation"
                                        >
                                        Method Operation
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-method_operation"
                                        placeholder=""
                                        type="text"
                                        name="method_operation"
                                        disabled
                                        defaultValue={data.method_operation}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-status"
                                        >
                                        Status
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-status"
                                        placeholder=""
                                        type="text"
                                        name="status"
                                        disabled
                                        defaultValue={data.status}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-system"
                                        >
                                        System
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-system"
                                        placeholder=""
                                        type="text"
                                        name="system"
                                        disabled
                                        defaultValue={data.system}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-type"
                                        >
                                        Type
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-type"
                                        placeholder=""
                                        type="text"
                                        name="type"
                                        disabled
                                        defaultValue={data.type}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="3">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-uuid"
                                        >
                                        UUID
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-uuid"
                                        placeholder=""
                                        type="text"
                                        name="uuid"
                                        disabled
                                        defaultValue={data.uuid}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-endpoint"
                                        >
                                        Endpoint
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-endpoint"
                                        placeholder=""
                                        type="text"
                                        name="endpoint"
                                        disabled
                                        defaultValue={data.endpoint}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-url"
                                        >
                                        Url
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-url"
                                        placeholder=""
                                        type="text"
                                        name="url"
                                        disabled
                                        defaultValue={data.url}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-data"
                                        >
                                        Data
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-data"
                                        placeholder=""
                                        type="textarea"
                                        name="data"
                                        disabled
                                        defaultValue={data.data}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="col justify-content-end">
                                <Link
                                    className="btn btn-hensall-cancel"
                                    color="default"
                                    to={"/admin/audit"}
                                    >
                                    Cancel
                                </Link>
                            </Row>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </Container>
      </>
    );
  };
  
  export default Formulario;