/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Login.jsx
# Description: This is the Login form with all triggers of modals and authentication methods.
# Created by: Juan David Olivares Padilla
# Creation Date:  11/08/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/28/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import '../../../assets/css/custom/login-recaptcha.css'
import '../../../assets/css/custom/hide-show.css'
// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import AuthHeader from "components/Headers/AuthHeader.js";
import AuthFooter from "components/Footers/AuthFooter";

import NotificationContext from "../../../context/NotificationContext";
import LoginContext from "../../../context/LoginContext";

import LoginFormValidate from "../../../services/loginForm";
import { useForm } from "hooks/useForm";
import { useAuth } from "hooks/useAuth";

import NewPasswordReq from "./NewPasswordReq";
import MfaSetup_RAC from "../MFA/MfaSetup_RAC";
import SoftwareToken_SetupFt from "../MFA/SoftwareToken_SetupFt";
import SoftwareToken_Setup from "../MFA/SoftwareToken_Setup";
import SelectMfaType_RAC from "../MFA/SelectMfaType_RAC";
import SMS_RAC from "../MFA/SMS_RAC";
import SoftwareToken_RAC from "../MFA/SoftwareToken_RAC";

const { REACT_APP_API_URL, REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const initialForm = {
  email: "",
  password: "",
  prelogin: true
};

function Login() {

  const { prelogin, setPrelogin, ssoError, setSsoError } = useAuth();

  const { setMessage, setStatus, setType } = useContext(NotificationContext);
  const { login } = useContext(LoginContext);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const toggleVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };
  const captcha = useRef(null);

  const {
    form,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, LoginFormValidate.validationsForm);

  useEffect(()=> {
    const em = localStorage.getItem("HLOGIN_EMAIL")
    const check = document.getElementById("customCheckLogin")
    if(check && em && em != '') {
      form.email = em;
      check.checked = true;
    }
  }, [])

  useEffect(()=> {
    const emailInput = document.getElementById("input-email")
    if (emailInput) {
      emailInput.value = form.email
    }
  }, [form.email])

  useEffect(()=> {
    
    if (ssoError && ssoError === "404") {
      setType("danger");
      setMessage("SSO authentication error: Access denied. You do not have the necessary permissions to access the application.");
      setStatus(1);
      setSsoError("")
    } else if (ssoError === "502") {
      setType("danger");
      setMessage("SSO authentication error: Failed to retrieve user information. Please try logging in again or contact an administrator.");
      setStatus(1);
      setSsoError("")
    } else if (ssoError === "500") {
      setType("danger");
      setMessage("SSO authentication Error: An unexpected error occurred during the authentication process. Please try again later.");
      setStatus(1);
      setSsoError("")
    }
  }, [ssoError])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const rmbr = document.getElementById("customCheckLogin")
      rmbr.checked ? (rmbr.checked = false) : (rmbr.checked = true)
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let valid = handleSubmit(e);
    const rmbr = document.getElementById("customCheckLogin")
    if(valid) {
      if (rmbr?.checked){
        localStorage.setItem("HLOGIN_EMAIL", form.email);
      } else {
        localStorage.removeItem("HLOGIN_EMAIL");
      }
      if (captcha.current.getValue()) {
        form.recaptcha = captcha.current.getValue()
        login(form);
        captcha.current.props.grecaptcha.reset();
      } else {
        setType("danger");
        setMessage("You must click on the reCAPTCHA checkbox. Please, click on it and try again.");
        setStatus(1);
      }
    }
  }

  const handleContinue = (e) => {
    e.preventDefault();    
    let valid = handleSubmit(e);
    if(valid) {
      // if (form.email.split("@")[1].trim() == "hdc.on.ca") {
      //   window.location.href = REACT_APP_API_URL + "sso/login";
      // } else {
        form.prelogin = false;
        setPrelogin(false);
      // }
    }
  }

  return (
    <>
      <div className="bg-gradient-custom">
      {prelogin ? (
        <AuthHeader
          title="Welcome to Hensall Login!"
          lead1="Please click on Single Sign-On (SSO), if your user account is from Hensall network (username@hdc.on.ca)."
          lead2="On the contrary, please type in your email and continue with the authentication process."
        />
      ) : (
        <AuthHeader
          title="Welcome to Hensall Login!"
          lead1="Please, enter your email and password."
          lead2="If you don't have an account, please go to Setup/Validate in the navigation bar."
        />
      )}
      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col xl="6" lg="7" md="9">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  {prelogin ? (<>
                    <div className="custom-control custom-control-alternative pl-3">
                      <Row className="d-flex align-items-center mr-0">
                        <Button 
                          className="col-12 btn sso"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = REACT_APP_API_URL + "sso/login";
                          }}>
                            Login using Hensall SSO
                        </Button>
                      </Row>
                    </div>
                    <div className="divider">
                      <span className="line"></span>
                      <span className="or">or</span>
                      <span className="line"></span>
                    </div>  
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="input-email"
                          placeholder="Email"
                          autoComplete="email"
                          type="text"
                          name="email"
                          value={form.email}
                          required="required"
                          invalid={errors.email !== ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="invalid-feedback">
                          {errors.email}
                        </div>
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <button type="submit" className="my-1 btn btn-hensall" onClick={handleContinue}>Continue</button>
                    </div>  
                  </>) : <>
                    <FormGroup >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="input-email"
                          placeholder="Email"
                          autoComplete="email"
                          type="text"
                          name="email"
                          value={form.email}
                          required="required"
                          invalid={errors.email !== ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="invalid-feedback">
                          {errors.email}
                        </div>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="input-password"
                          placeholder="Password"
                          autoComplete="current-password"
                          type={passwordVisible ? "text" : "password"}
                          name="password"
                          required="required"
                          invalid={errors.password !== ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />                     
                        <button className="btn hide-show" onClick={toggleVisibility}>{passwordVisible ? <img src={require("assets/img/icons/hide_show_eye/eye-open.png").default} alt="Hide" /> : <img src={require("assets/img/icons/hide_show_eye/eye-closed.png").default} alt="Show" />}</button>
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      </InputGroup>
                    </FormGroup>
                    {/* <div className="custom-control custom-control-alternative align-baseline pl-2">
                      <Row className="d-flex align-items-center mr-0">
                        <Col col="6">
                          <input
                            className="custom-control-input-checkbox"
                            id="customCheckLogin"
                            name="customCheckLogin"
                            onKeyDown={handleKeyDown}
                            type="checkbox"
                          />
                          <label
                            className="m-0 p-0"
                            htmlFor="customCheckLogin"
                            style={{cursor: "pointer"}}
                          >
                            <span className="text-muted">Remember me</span>
                          </label>
                        </Col>
                        <Button 
                          className="col-6 btn sso"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = REACT_APP_API_URL + "sso/login";
                          }}>
                            Login using Azure AD
                        </Button>
                      </Row>
                    </div> */}
                    {<ReCAPTCHA
                      ref={captcha}
                      sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                      className="recaptcha"
                    />}
                    <div className="text-center">
                      <button type="submit" className="my-4 btn btn-hensall" onClick={handleLogin}>Log in</button>
                      {/* <button type="cancel" className="my-4 btn btn-hensall-cancel" onClick={()=>{setPrelogin(true); form.prelogin = true}}>Cancel</button> */}
                    </div>
                  </>}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AuthFooter clss="auth-footer-custom w-100"/>
      </div>
      <NewPasswordReq />
      <MfaSetup_RAC />
      <SoftwareToken_SetupFt />
      <SoftwareToken_Setup />
      <SelectMfaType_RAC />
      <SMS_RAC />
      <SoftwareToken_RAC />
    </>
  );
}

export default Login;
