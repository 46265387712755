/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AuthNavbar.js
# Description: This is the navigation bar component that appears in the authentication section/layout.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/02/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useAuth } from "hooks/useAuth";

function AdminNavbar() {

  const { prelogin } = useAuth();

  const navLinkTrigger = (e) => {
    document.getElementById('navbar-collapse').click();
  }

  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        style={
          {
            backgroundColor: "#D92632"
          }
        }
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img
              style={{height:"60px"}}
              alt="..."
              src={require("assets/img/brand/logo-hensall-white.png").default}
            />
          </NavbarBrand>
          {!prelogin ? (
            <>
            <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler responsiveMenu"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/admin/dashboard">
                      <img
                        alt="..."
                        src={require("assets/img/brand/logo-hensall-black.jpg").default}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="mr-auto w-100 justify-content-end" navbar>
                <NavItem>
                  <NavLink to="/auth/login" tag={Link} onClick={navLinkTrigger}>
                    <span className="nav-link-inner--text">Login</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/auth/forgot-password" tag={Link} onClick={navLinkTrigger}>
                    <span className="nav-link-inner--text">Forgot Password</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/auth/validate-account" tag={Link} onClick={navLinkTrigger}>
                    <span className="nav-link-inner--text">Setup/Validate your Hensall Login account</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
            </>
          ) : ""}
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
