/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: SendEmail.jsx
# Description: This is a Send Email modal window. Used in the Setup/Validate to request a new account if user is not found neither on database or Cognito.
# Created by: Juan David Olivares Padilla
# Creation Date: 01/03/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/24/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import LoginContext from 'context/LoginContext';
import NotificationContext from "context/NotificationContext";
import SendEmailForm from 'services/sendEmailForm';
import PhoneInput from 'react-phone-number-input'
import { useForm } from "hooks/useForm";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    Row,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";

const initialForm = {
    email: '',
    emailTo: '',
    application: '',
    hdcName: '',
    hdcNumber: '',
    accountBalance: '',
    phone: '',
    username: '',
    additionalComments: '',
};
  
function SendEmail() {

    const { sendEmail, viewModalSetupAccount, closeModalSetupAccount, email } = useContext(LoginContext);
    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const [emailTo, setEmailTo ] = useState('')
    const [phone, setPhone ] = useState()
    const [additionalComments, setAdditionalComments] = useState('Hello, Hensall admin team,')

    useEffect(() => {
        let phoneField = document.getElementById("phone")
        if (phoneField){
            if (!phoneField.classList.contains("form-control")) { 
                phoneField.classList.add("form-control"); 
            }
        }
    }, []);
    useEffect(() => {
        let phoneField = document.getElementById("phone")
        if (phoneField){
            if (!phoneField.classList.contains("form-control")) { 
                phoneField.classList.add("form-control"); 
            }
        }
        form.phone = phone;
    }, [phone]);

    const {
        form,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, SendEmailForm.validationsForm);

    const closeModal = (e) => {
        e.preventDefault();
        closeModalSetupAccount();
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let valid = handleSubmit(e);
            if (valid) {
                handleSend(e);
            }
        }
    }

    const handleSend = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            form.email = email
            form.emailTo = emailTo

            if (emailTo === 'cissupport@hdc.on.ca') {
                form.application = 'CIS'
            } else if (emailTo === 'FieldTraceSupport@hdc.on.ca') {
                form.application = 'FieldTrace'
            }

            if (form.accountBalance === 0) {
                form.accountBalance = null
            }

            if (additionalComments.trim() === 'Hello, Hensall admin team,') {
                form.additionalComments = ''
            }

            let data = {
                email: form.emailTo,
                subject: "Setup new Hensall Login account",
                email_message: 
                    ("<h1>Hensall Login new " + form.application + " account</h1>" +
                    "<br/><p>Hello Hensall team, this is an automated message from Hensall Login application. We received a new account request for a user with the following information:</p>" +
                    "<br/><p>Email address: " + form.email +
                    "<br/>To: " + form.emailTo +
                    "<br/>Application: " + form.application +
                    "<br/>" + (form.accountBalance ? "Account Balance: $" + form.accountBalance  : "") + 
                    (form.accountBalance ? "<br/>" : "") + "HDC Name: " + form.hdcName +
                    "<br/>HDC Number: " + form.hdcNumber +
                    "<br/>Phone: " + form.phone +
                    "<br/>Desired username: " + form.username +
                    (form.additionalComments ? "<br/>Additional comments: " + form.additionalComments : "") +
                    "</p><br/><br/>" +
                    "Best regards," +
                    "<br/><strong>Hensall CO-OP</strong>" +
                    "<br/>This message was sent from an unmonitored email address. Please do not reply to this message." +
                    "<br/><img src=\"https://hensallco-op.ca/images/logo.png\" alt=\"Hensall logo\" height=\"50px\"></img>")
            }
            sendEmail(data)
        } else {
            if (!form.phone) {
                setType("danger")
                setMessage("Invalid data, make sure to type a valid phone number.")
                setStatus(1)                
            } else {
                setType("danger")
                setMessage("Invalid data, please check the fields information provided.")
                setStatus(1)
            }

        }
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={viewModalSetupAccount}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Send administrators an email to setup your account
                </h6>
            </div>
            <div className="modal-body pb-0">
                <p>Your email appears to be new to Hensall. Please fill out this form to send an account creation request to the administration team.</p>
                <Form>
                    <FormGroup>
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="email">Email</label>
                                    <Input 
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        type="email"
                                        required="required"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={email}
                                        readOnly
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.email}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="application">Application</label>
                                    <Input 
                                        className="form-control"
                                        name="application"
                                        id="application"
                                        type="select"
                                        required="required"
                                        onChange={(e) => { setEmailTo(e.target.value); }}
                                        onBlur={handleBlur}
                                        onKeyDown={handleKeyDown}
                                        invalid={Boolean(errors.application)}
                                        >
                                            <option value=''>Select an application</option>
                                            <option value='cissupport@hdc.on.ca'>CIS</option>
                                            <option value='FieldTraceSupport@hdc.on.ca'>FieldTrace</option>
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.application}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="hdcName">Name on HDC Account</label>
                                    <Input 
                                        className="form-control"
                                        name="hdcName"
                                        id="hdcName"
                                        type="text"
                                        required="required"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyDown={handleKeyDown}
                                        invalid={Boolean(errors.hdcName)}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.hdcName}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="hdcNumber">HDC Account #</label>
                                    <Input 
                                        className="form-control"
                                        name="hdcNumber"
                                        id="hdcNumber"
                                        type="number"
                                        min="0"
                                        max="1000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
                                        required="required"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyDown={handleKeyDown}
                                        invalid={Boolean(errors.hdcNumber)}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.hdcNumber}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        {emailTo === 'cissupport@hdc.on.ca'
                            ? (<Row className='mb-3'>
                                <Col>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor="accountBalance">Account Balance</label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                            </InputGroupAddon>
                                            <Input 
                                                className="form-control"
                                                name="accountBalance"
                                                id="accountBalance"
                                                type="number"
                                                required="required"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onKeyDown={handleKeyDown}
                                                invalid={Boolean(errors.accountBalance)}
                                                >
                                            </Input>
                                            <div className="invalid-feedback">
                                                {errors.accountBalance}
                                            </div>
                                        </InputGroup>
                                    </FormGroup>                                    
                                </Col>
                            </Row>)
                            : null
                        }
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="phone">Phone</label>
                                    <PhoneInput
                                        className="pr-0"
                                        name="phone"
                                        id="phone"
                                        required="required"
                                        international
                                        defaultCountry="CA"
                                        onChange={setPhone}
                                        value={phone}
                                        onKeyDown={handleKeyDown}
                                        >
                                    </PhoneInput>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="username">Desired Username</label>
                                    <Input 
                                        className="form-control"
                                        name="username"
                                        id="username"
                                        type="text"
                                        required="required"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyDown={handleKeyDown}
                                        invalid={Boolean(errors.username)}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.username}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor="additionalComments">Additional Comments</label>
                                    <Input 
                                        className="form-control"
                                        name="additionalComments"
                                        id="additionalComments"
                                        type="textarea"
                                        onChange={(e) => { setAdditionalComments(e.target.value) }}
                                        onBlur={handleBlur}
                                        value={additionalComments}
                                        onKeyDown={handleKeyDown}
                                        invalid={Boolean(errors.additionalComments)}
                                        >
                                    </Input>
                                    <div className="invalid-feedback">
                                        {errors.additionalComments}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        onClick={handleSend}
                    >
                        Send
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default SendEmail;