/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: users/RoleSystems.jsx
# Description: This role-systems view is a modal with a select form to choose the system-role assigned to a user. Used in the Users section of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 11/01/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/13/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import UsersContext from "context/UsersContext";
import { useForm } from "../../../hooks/useForm";
import RoleSystemsForm from "../../../services/roleSystemsForm";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    role: "",
  };
  
function RoleSystems() {

    const { module, addRoleSystems, viewModalRoles, setViewModalRoles, roles } = useContext(UsersContext);

    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleChangeCombo,
        handleChecked,
        handleBlur,
        handleBlurCombo,
        handleSubmit,
    } = useForm(initialForm, RoleSystemsForm.validationsForm);

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            addRoleSystems(form)
            setViewModalRoles(false);
            reset();
        }
    }

    const closeModal = (e) => {
        e.preventDefault();
        setViewModalRoles(false);
        reset();
    }

    const reset = () => {
        setTimeout(function(){ 
            setErrors(initialForm);
            setForm({});
            setValidateInit(false);
        }, 500);
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={viewModalRoles}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Add Permissions
                </h6>
            </div>
            <div className="modal-body">
                <Form>
                    <div>
                        <Row>
                            <Col lg="12">
                                <label
                                className="form-control-label"
                                htmlFor="input-role"
                                >
                                Role
                                </label>
                                <Input 
                                    className="form-control"
                                    id="input-role"
                                    type="select"
                                    name="role"
                                    value={form.role}
                                    onChange={handleChangeCombo}
                                    onBlur={handleBlurCombo}
                                    invalid={errors.role !== ""}
                                    >
                                    <option value="" hidden></option>
                                    {roles.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {item.text}
                                        </option>
                                    ))};
                                </Input>
                                <div className="invalid-feedback">
                                    {errors.role}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default RoleSystems;