/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AuthProvider.js
# Description: This is the authentication provider, it contains API calls, variables and the logic to handle the users authentication.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createContext, useState, useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

//import { decodeToken } from "react-jwt";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [prelogin, setPrelogin] = useState(true);
    const [ssoError, setSsoError] = useState("");

    useEffect(() => {
        handleOnlineStatusChange();
    }, [])

    useEffect(() => {
        handleOnlineStatusChange();
        if (!isOnline) {
            setIsOnlineAlert({
                alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={"Connection lost!"}
                    onConfirm={() => {hideAlertIsOnline()}}
                    confirmBtnBsStyle="hensall"
                    confirmBtnText="Understood!"
                    btnSize=""
                >
                    {"Please, check your internet connection. You can restart your router or check your wifi settigns."}
                </ReactBSAlert>
                )
            });
        } else {
            // console.log("online");
        }
    }, [isOnline])

    const handleOnlineStatusChange = () => {
        setIsOnline(navigator.onLine);
    };

    const [auth, setAuth] = useState();
    const[alertProc, setAlertProc] = useState(false);
    const[BSAlert, setBSAlertState] = useState({});
    const[isOnlineAlert, setIsOnlineAlert] = useState({});

    const hideAlert = () => {
        setBSAlertState({ alert: null });
    };
    const hideAlertIsOnline = () => {
        setIsOnlineAlert({ alert: null });
    };

    function parseJwt (token) {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        } catch (error) {
            logout();
            window.location.href = "/auth/login";
        }
    
        return JSON.parse(jsonPayload);
    }

    const logout = () => {
        setPrelogin(true);
        localStorage.removeItem("token_hensall_login");
        localStorage.removeItem("token_hensall");
        localStorage.removeItem("app_hensall");
    };

    const isAuthenticated = () => {
        return (getCurrentUser()) ? true : false;
    };

    const isAuthenticatedApp = () => {
        return (getCurrentUserApp()) ? true : false;
    };

    const getCurrentUser = () => {
        if(localStorage.getItem("token_hensall_login")){
            return JSON.parse(localStorage.getItem("token_hensall_login"));
        }
    };

    const getCurrentUserApp = () => {
        if(localStorage.getItem("token_hensall")){
            return JSON.parse(localStorage.getItem("token_hensall"));
        }else{
            return false;
        }
    };

    const getAcessToken = () => {
        const access = getDecodeTokenApp()?.token.AuthenticationResult.AccessToken;
        return parseJwt(access);
    };

    const getBearerAcessToken = () => {
        const access = getDecodeTokenApp()?.token.AuthenticationResult.AccessToken;
        return access;
    };

    const getIdToken = () => {
        const id = getDecodeTokenApp()?.token.AuthenticationResult.IdToken;
        return parseJwt(id);
    };

    const getJwtToken = () => {
        const jwt = getDecodeTokenApp()?.token_jwt;
        return jwt;
    };

    const getRefreshToken = () => {
        const token = getDecodeTokenApp() ? getDecodeTokenApp().token : null
        const refresh = token?.AuthenticationResult.RefreshToken
                      ? token.AuthenticationResult.RefreshToken
                      : null
        if (!refresh) return refresh;
        return refresh;
    };

    const getDecodeToken = () => {
        return parseJwt(getCurrentUser());
    }

    const getDecodeTokenApp = () => {
        if(getCurrentUserApp()){
            return parseJwt(getCurrentUserApp());
        }else{
            return false;
        }
    }
      
    const getNameUser = () => {
        let decode = getDecodeTokenApp();
        return decode?.sub ? decode.sub : "";
    }

    const getNameRole = () => {
        let decode = getDecodeToken();
        return decode?.roles ? decode.roles : "";
    }

    const getExpToken = () => {
        let decode = getDecodeToken();
        return decode.exp;
    }

    const getExpTokenApp = () => {
        let decode = getDecodeTokenApp();
        return decode.exp;
    }

    const getPermissions = () => {
        let decode = getDecodeToken();
        return decode?.permissions;
    }

    const getPermissionsApp = () => {
        let decode = getDecodeTokenApp();
        return decode?.permissions;
    }

    const validatePermission = (roles) => {
        let contador;
        let permissions = getPermissions();
        let exist = false
        if(!roles){
            exist = true;
        }else if(roles.length > 0){
        roles.map((role, key) => {
            contador = permissions.filter(permission => permission.includes(role)).map(filteredPermission => (
                filteredPermission
            ));
            if(contador.length > 0){
                exist = true;
            }
        });
        }
        return exist;
    }

    const validatePermissionApp = (roles) => {
        let contador;
        let permissions = getPermissionsApp();
        let exist = false
        if(!roles){
            exist = true;
        }else if(roles.length > 0){
        roles.map((role, key) => {
            contador = permissions.filter(permission => permission.includes(role)).map(filteredPermission => (
                filteredPermission
            ));
            if(contador.length > 0){
                exist = true;
            }
        });
        }
        return exist;
    }

    const checkTokenExpiry = () => {
        let timeExpired = getExpToken();
        let expired =  (timeExpired-1000) < ((Date.now() - 1000 * 60 * 5) / 1000);

        let tokentemp = new Date(timeExpired*1000);
        let timeNow = new Date(((Date.now() - 1000 * 60 * 5) / 1000) * 1000);
        let diff = (tokentemp.getTime() - timeNow.getTime()) / 1000;

        if (expired) {
            localStorage.removeItem("token_hensall_login");
            checkTokenExpiryApp();
            window.location.href = "/admin/app";
        } else if (Math.round(diff / 60) <= 30){
            checkTokenExpiryApp();
            if(!alertProc) {
                setBSAlertState({
                    alert: (
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={"Session about to expire!"}
                        onConfirm={() => {setAlertProc(true); hideAlert()}}
                        confirmBtnBsStyle="hensall"
                        confirmBtnText="Understood!"
                        btnSize=""
                    >
                        {"Your session is about to expire in "+ Math.round(diff / 60) +" minutes. The application will ask for login again when the time expires."}
                    </ReactBSAlert>
                    )
                });
            }
        }
    }

    const checkTokenExpiryApp = () => {
      return new Promise((resolve,reject)=>{
        let timeExpired = getExpTokenApp();
        let expired =  (timeExpired-1000) < ((Date.now() - 1000 * 60 * 5) / 1000);
        if (expired) {
            localStorage.removeItem("token_hensall_login");
            localStorage.removeItem("token_hensall");
            window.location.href = "/auth/login";
        }
        resolve();
      });
    }

    const getPhone = () => {
        const id = getIdToken();
        return id.phone_number ? id.phone_number : "";
    }

    const getSystems = () => {
        let decode = getDecodeTokenApp();
        return decode.system;
    }

    const setApp = (app) => {
        localStorage.setItem("app_hensall", app);
    }

    const getApp = () => {
        return localStorage.getItem("app_hensall");   
    }

    const isAzureUser = () => {
        let decode = getDecodeTokenApp();
        return decode?.azure ? true : false;
    }
      
    const data = { auth, setAuth, logout, parseJwt, getAcessToken, getBearerAcessToken, getRefreshToken, getIdToken, getJwtToken,
        isAuthenticated, isAuthenticatedApp, getNameUser, getNameRole, checkTokenExpiry, isAzureUser,
        checkTokenExpiryApp, getCurrentUser, getCurrentUserApp, validatePermission, validatePermissionApp, getSystems, 
        setApp, getApp, getPhone, BSAlert, isOnlineAlert, handleOnlineStatusChange, prelogin, setPrelogin, ssoError, setSsoError};

    return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}

export default AuthContext;