/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: List.jsx
# Description: This is Roles main list base component with pagination and functional buttons.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Yan Carlo Angarita Sanguino
# Last Modification Date: 11/13/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import RolesContext from "../../../context/RolesContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationContext from "context/NotificationContext";
import useAuth from "hooks/useAuth";

import {
  Card,
  CardHeader,
  Alert,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  Input
} from "reactstrap";

import { Link } from "react-router-dom";
import Login from "../login/Login";


const { SearchBar } = Search;

function List( ) {

  const { db:data, setDetail,  setToDetail, setToUpdate, setViewModal, setModule, deleteData, 
    applications, toApplications, setToApplications } = useContext(RolesContext);
  
  const { setMessage, setStatus, setType } = useContext(NotificationContext);

  const { checkTokenExpiry } = useAuth();

  let emptysearchFlag = false;

  //paginator
  const initialSizePerPage = parseInt(localStorage.getItem('sizePerPage')) || 10;
  const [sizePerPage, setSizePerPage] = useState(initialSizePerPage);
  
  useEffect(() => {
    localStorage.setItem('sizePerPage', sizePerPage);
  }, [sizePerPage]);

  const pagination = paginationFactory({
    sizePerPage: sizePerPage,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              value={currSizePerPage}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                onSizePerPageChange(value, 1); // Reset page to 1 when size changes
                setSizePerPage(value); // Update state and local storage
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const[state, setState] = useState({});
  const[idDelete, setIdDelete] = useState();

  const hideAlert = () => {
    setState({
      alert: null
    });
  };

  const confirmAlert = (id) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onCancel={() => hideAlert()}
          onConfirm={() => {setIdDelete(id); hideAlert();}}
          showCancel
          confirmBtnBsStyle="hensall-cancel"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="hensall"
          cancelBtnText="Cancel"
          btnSize=""
        >
          You won't be able to revert this!
        </ReactBSAlert>
      )
    });
  };

  useEffect(() => {
    checkTokenExpiry();
    setDetail({});
    setToUpdate(0);
    setModule('list');
    // setToApplications('all');
  },[]);

  useEffect(() => {
    if(idDelete){
      deleteData(idDelete);
    }
  },[idDelete]);

  const handleSelectApp = (e) => {
    const { value } = e.target;
    setToApplications(value);
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    confirmAlert(id);
  }

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
         <Link className='btn btn-hensall btn-sm'
            color="hensall"
            to={"/admin/roles/detail/"+row.id}
        >
        Detail
        </Link>
        <Button
            className='btn btn-hensall-cancel btn-sm'
            onClick={e => handleDelete(e, row.id)}
        >
            Delete
        </Button>
        </>
    );
  };

  const NoDataIndication = () => (
    <Alert color="secondary" className="mt-2 mb-1 text-center">
      Search input didn't match any register of the table.
    </Alert>
  );

  return (
    <>
    {state.alert}
    <SimpleHeader name="Roles" toUrl="roles" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Roles Result List</h3>
                      <p className="text-sm mb-0">
                      This is a list of Roles where can be managed new and existing Roles.
                    </p>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Input 
                      className="form-control"
                      id="input-type"
                      type="select"
                      name="type"
                      value={toApplications}
                      onChange={handleSelectApp}
                      onBlur={handleSelectApp}
                      >
                      <option value="" hidden>Select an Application</option>
                      {/* <option value="all" >All</option> */}
                      {applications.map(item => (
                          <option key={item.id} value={item.id}>
                              {item.text}
                          </option>
                      ))};
                    </Input>
                  </Col>
                </Row>
              </CardHeader>
              {data.length > 0 ? ( 
              <>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  // {
                  //   dataField: "id",
                  //   text: "#",
                  //   sort: true,
                  // },
                  {
                    dataField: "description",
                    text: "DESCRIPTION",
                    sort: true,
                  },
                  // {
                  //   dataField: "status",
                  //   text: "STATUS",
                  //   sort: true,
                  // },
                  {
                  dataField: "action",
                  text: "ACTION",
                  formatter: linkFollow,
                  sort: false,
                  }
                ]}
                search
                >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      noDataIndication={NoDataIndication}
                    />
                  </div>
                )}
              </ToolkitProvider>
              </> 
            ) : (
              <CardBody>
                  <Alert color="secondary" className="m-2">
                    There is no information to display in this section of <strong>Roles !</strong>
                  </Alert>
                </CardBody>
            )}
            <div className="m-3">
              <Link className='btn btn-hensall'
                color="hensall"
                to={"/admin/roles/add"}
              >
                Add Role
              </Link>
            </div>
            </Card>
          </div>
        </Row>
        </Container>
    </>
  );
}

export default List;