/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AdminNavbar.js
# Description: This file is the navigation bar component that appears inside the application.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 10/07/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import { useState, useEffect } from 'react';
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useHistory } from "react-router";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  } from "reactstrap";
import ModalPassword from "./ModalPassword";
import { useAuth } from "hooks/useAuth";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {

  const history = useHistory();
  const { logout, getNameUser, isAzureUser } = useAuth();

  const [azure, setAzure] = useState(isAzureUser());

  useEffect(() => {
    setAzure(isAzureUser())
  },[])
  
  const logOut = () => {
    logout();
    history.push('/');
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand",
          { "navbar-dark bg-gradient-hensall": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar style={{cursor: "pointer"}}>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/default-user.png").default}
                      />
                    </span>
                    <Media className="ml-2">
                      <span className="mb-0 text-sm font-weight-bold">
                        {getNameUser().toUpperCase()}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    href=""
                    onClick={() => {history.push('/admin/app')}}
                  >
                    <i className="ni ni-shop" />
                    <span>Home</span>
                  </DropdownItem>
                  
                  {azure
                    ? ""
                    : <DropdownItem
                      href=""
                      onClick={() => {history.push('/admin/mfa-settings')}}
                    >
                      <i className="ni ni ni-circle-08" />
                      <span>MFA Settings</span>
                    </DropdownItem>
                  }
                  
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={logOut}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;