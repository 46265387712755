/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Index.js
# Description: This is React routes configuration file to provide the contexts & endpoints used on FAQs section.
# Created by: Juan David Olivares Padilla
# Creation Date: 02/06/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Menu from "./Menu";
import MenuAdmin from "./MenuAdmin";
import Article1 from "./Article1";
import Article2 from "./Article2";
import Article3 from "./Article3";
import Article4 from "./Article4";
import Article5 from "./Article5";
import Article6 from "./Article6";

const Index = () => {

    return(
        <>
        <Switch>
            <Route exact path="/auth/FAQs">
                <Menu />
            </Route>
            <Route exact path="/auth/FAQs/article1">
                <Article1 />
            </Route>
            <Route exact path="/auth/FAQs/article2">
                <Article2 />
            </Route>
            <Route exact path="/auth/FAQs/article3">
                <Article3 />
            </Route>
            <Route exact path="/auth/FAQs/article4">
                <Article4 />
            </Route>
            <Route exact path="/auth/FAQs/article5">
                <Article5 />
            </Route>
            <Route exact path="/auth/FAQs/article6">
                <Article6 />
            </Route>
            <Route exact path="/admin/FAQs">
                <MenuAdmin />
            </Route>
            <Route exact path="/admin/FAQs/article1">
                <Article1 />
            </Route>
            <Route exact path="/admin/FAQs/article2">
                <Article2 />
            </Route>
            <Route exact path="/admin/FAQs/article3">
                <Article3 />
            </Route>
            <Route exact path="/admin/FAQs/article4">
                <Article4 />
            </Route>
            <Route exact path="/admin/FAQs/article5">
                <Article5 />
            </Route>
            <Route exact path="/admin/FAQs/article6">
                <Article6 />
            </Route>
            <Redirect from="*" to="/" />
        </Switch>
        </>
    );
}

export default Index;