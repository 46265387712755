/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: ProfileHeader.js
# Description: This is the Header that appears in the profile page.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/22/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useRef, useContext, useEffect, useState} from 'react';

import NotificationAlert from "react-notification-alert";
import Notification from "../../services/notification";
import NotificationContext from "context/NotificationContext";

import LoadingContext from "context/LoadingContext";
import Loading from "components/Loading/Loading.js";

import { Container, Row, Col } from "reactstrap";

function ProfileHeader() {

  const notificationAlertRef = useRef(null);

  const [notificationCreated, setNotificationCreated] = useState(false);

  const { status, type, message, setStatus } = useContext(NotificationContext);
  const { loading } = useContext(LoadingContext);
  
  useEffect(() => {
    if(status){
        Notification.viewNotification(type, message, notificationAlertRef);
        setNotificationCreated(true);
        setStatus(0);
    }
  },[status]);

  useEffect(() => {
    if (notificationCreated) {
      const notificationContainer = document.getElementById("notificationWrapper");
      if (notificationContainer) {
        const allNotifications = notificationContainer.getElementsByClassName("col-sm-4");
        for (let i = 0; i < allNotifications.length; i++) {
          allNotifications[i].classList.replace("col-sm-4", "col-lg-5");
        }
      }
      // Reset the notificationCreated state to false
      setNotificationCreated(false);
    }
  }, [notificationCreated])


  return (
    <>
      <div className="rna-wrapper w-100" id="notificationWrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {loading ? <Loading />:""}
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "300px",
          backgroundImage:
            'url("' +
            require("assets/img/theme/membership-callout-background.jpg").default +
            '")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-dark opacity-2" />

        <Container className="col-12 w-100" fluid>
          <Row className="w-100 p-0 m-0">
            <Col className="col-12 col-md-10 col-lg-7 px-0 pl-sm-2 pl-md-5 pl-lg-7">
              <h1 className="display-2 text-center text-md-left text-white">MFA Settings</h1>
              <p className="text-center text-md-left text-white mt-0 mb-5">
                These are your MFA configurations. Here you can see your current setup and change this configuration to adjust your MFA methods and preferences.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;
