export const validationsForm = (form) => {
    let errores = {};

    if (!form.name?.trim()) {
        errores.name = "Please the field is required.";
    }else{
        errores.name = "";
    }

    return errores;
};

const OptionForm = {
    validationsForm,
};
  
export default OptionForm;