/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: systemRolesForm.js
# Description: This file contains a form validation logic and errors for the systems form in UAM
# Created by: Yan Carlo angarita Sanguino
# Creation Date: 11/02/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    let errores = {};

    if (!form.role) {
        errores.role = "Please the field is required.";
    }else if (form.role.trim().length > 50) {
        errores.role = "The role can't be more than 50 charachters long.";
    }else{
        errores.role = "";
    }

    return errores;
};

const SystemRolesForm = {
    validationsForm,
  };
  
  export default SystemRolesForm;