/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: useForm.js
# Description: This file defines a React hook that helps to provide forms inputs functions to create the validations and input-error display
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState } from "react";

export const useForm = (initialForm, validateForm) => {

  const [validateInit, setValidateInit] = useState(false);  
  const [validate, setValidate] = useState(true);
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleChangeCombo = (e) => {
    const { name, value } = e.target;
    const text = e.target.options[e.target.selectedIndex].text;
    setForm({
      ...form,
      [name]: value,[name+"_text"]: text,
    });
  };

  const handleChecked = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const handleBlur = (e) => {
    handleChange(e);
    if(validateInit){
        setErrors(validateForm(form));
    }
  };

  const handleBlurCombo = (e) => {
    handleChangeCombo(e);
    if(validateInit){
        setErrors(validateForm(form));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let cont = 0;
    setValidateInit(true);
    let erroresTemp = validateForm(form);
    setErrors(erroresTemp);
    Object.entries(erroresTemp).forEach(([key, value]) => {
        if(value){
            cont++;
            setValidate(false);
        }
    });
    if(cont){
        return false;
    }else{
        return true;
    }
  };

  return {
    validateInit,
    validate,
    form,
    errors,
    setValidateInit,
    setValidate,
    setForm,
    setErrors,
    handleChange,
    handleChangeCombo,
    handleChecked,
    handleBlur,
    handleBlurCombo,
    handleSubmit,
  };
};