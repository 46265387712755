/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Index.js
# Description: This is React routes configuration file to provide the context & endpoints used on Roles section in the UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";
import Form from "./Form";

import { RolesProvider } from "context/RolesContext";

const Index = () => {

    return(
        <>
        <RolesProvider>
        <Switch>
            <Route exact path="/admin/roles">
                <List />
            </Route>
            <Route exact path="/admin/roles/detail/:id" >
                <Form />
            </Route>
            <Route exact path="/admin/roles/add" >
                <Form />
            </Route>
            <Redirect from="*" to="/" />
        </Switch>
        </RolesProvider>
        </>
    );
}

export default Index;