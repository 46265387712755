/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: ApplicationList.jsx
# Description: This is Users main list base component with pagination and functional buttons.
# Created by: Juan David Olivares Padilla
# Creation Date: 16/05/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 17/05/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import UsersContext from "../../../context/UsersContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationContext from "context/NotificationContext";
import useAuth from "hooks/useAuth";

import {
  Card,
  CardHeader,
  Alert,
  Button,
  Container,
  Row,
  Col,
  CardBody
} from "reactstrap";

import { useParams, useHistory } from "react-router-dom";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function ApplicationList() {

  const { db:data, systems, setToApplications, setToUpdate, fetchDataApplications, fetchUserApplications, applications} = useContext(UsersContext);

  const { setMessage, setStatus, setType } = useContext(NotificationContext);

  const { checkTokenExpiry } = useAuth();

  const[userName, setUserName] = useState("User's");
  
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkTokenExpiry();
    fetchDataApplications();
    fetchUserApplications(id);
    setToUpdate(id)
  },[]);

  useEffect(() => {
    data.map((user) => {
      if (user.id === id){
        setUserName(user.name);
      }
    })
  },[data]);

  useEffect(() => {
    if (userName.at(-1) === 's' && userName.at(-2) !== '\''){
      setUserName(userName + "'")
    } else if (userName.at(-1) !== 's' && userName.at(-1) !== '\''){
      setUserName(userName + "'s")
    }
  },[userName]);

  useEffect(() => {
    if (applications && applications.length > 0){
      setToApplications(applications[0].id)
    }
  },[applications]);

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
          className='btn btn-hensall btn-sm'
          onClick={e => {
            e.preventDefault();
            let flag = 0
            applications.map((app) => {
              if (app.id == row.syst_uuid){
                setToApplications(row.syst_uuid);
                history.push('/admin/users/detail/' + id);
                flag = 1
              }
            });
            if (flag ==! 1){
              setType('info')
              setMessage("You don't have permissions for the selected application")
              setStatus(1)
            }
          }}
      >
          Detail
      </Button>
    );
  };

  return (
    <>
    <SimpleHeader name="User's Applications List" parentName="Users" toUrl="users" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{userName} Applications List</h3>
                      <p className="text-sm mb-0">
                      This is the list of applications that the user has access to.
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              {systems.length > 0 ? ( 
              <>
              <ToolkitProvider
                data={systems}
                keyField="syst_uuid"
                columns={[
                  {
                    dataField: "name_system",
                    text: "APPLICATION",
                    sort: true,
                  },
                  {
                    dataField: "role",
                    text: "ROLE",
                    sort: true,
                  },
                  {
                    dataField: "syst_uuid",
                    text: "System ID",
                    sort: true,
                  },
                  {
                  dataField: "action",
                  text: "ACTION",
                  formatter: linkFollow,
                  sort: false,
                  },
                ]}
                search
                >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
              </> 
            ) : (
              <CardBody>
                  <Alert color="secondary" className="m-2">
                    There is <strong>no applications assigned</strong> to the user!
                  </Alert>
                </CardBody>
            )}
            </Card>
          </div>
        </Row>
        </Container>
    </>
  );
}

export default ApplicationList;