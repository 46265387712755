export const validationsForm = (form) => {

    let errores = {};

    if (!form.lines) {
        errores.lines = "Field is required.";
    }else{
        errores.lines = "";
    }

    return errores;

};

const LogLineFilterForm = {
    validationsForm,
};
  
export default LogLineFilterForm;