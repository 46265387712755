/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: NewPasswordReq.jsx
# Description: This is a modal window form for the Cognito authentication challenge resolve.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/16/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/06/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import LoginContext from 'context/LoginContext';
import { useForm } from "../../../hooks/useForm";
import NewPasswordReqForm from "../../../services/newPasswordReqForm";
import "../../../assets/css/custom/new-password-form.css"
import '../../../assets/css/custom/hide-show.css'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    Row,
  } from "reactstrap";

  const initialForm = {
    newPassword : "",
    repeatPassword: "",
  };
  
function NewPasswordReq() {

    const { newPasswordReq, viewModalNewPasswordReq, closeModalNewPasswordReq  } = useContext(LoginContext);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisibleRP, setPasswordVisibleRP] = useState(false);
    const toggleVisibility = (e) => {
        e.preventDefault();
        setPasswordVisible(!passwordVisible);
        };
    const toggleVisibilityRP = (e) => {
        e.preventDefault();
        setPasswordVisibleRP(!passwordVisibleRP);
        };
    const {
        validateInit,
        validate,
        form,
        errors,
        setValidateInit,
        setValidate,
        setForm,
        setErrors,
        handleChange,
        handleChangeCombo,
        handleChecked,
        handleBlur,
        handleBlurCombo,
        handleSubmit,
    } = useForm(initialForm, NewPasswordReqForm.validationsForm);

    useEffect(() => {
        setForm(initialForm);
        setErrors(initialForm);
    },[]);

    const closeModal = (e) => {
        e.preventDefault();
        closeModalNewPasswordReq();
        reset();
    }

    const reset = () => {
        setTimeout(function(){ 
            setErrors(initialForm);
            setForm({});
            setValidateInit(false);
        }, 500);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSave(e);
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        let data = {
            new_password: form.newPassword
        }
        if (valid){
            newPasswordReq(data)
        }
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalNewPasswordReq}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Create new Password
                </h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div>
                        <p className='title'>You have to define a new password, please define your new password and write twice to confirm</p>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="newPassword"
                                    >
                                        New password
                                    </label>
                                    <div className="row px-3">
                                        <Input 
                                            className="col form-control"
                                            id="newPassword"
                                            type={passwordVisible ? "text" : "password"}
                                            name="newPassword"
                                            required="required"
                                            autoComplete='new-password'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            invalid={errors.newPassword !== ""}
                                            >
                                        </Input>
                                        <button className="btn hide-show" onClick={toggleVisibility}>{passwordVisible ? <img src={require("assets/img/icons/hide_show_eye/eye-open.png").default} alt="Hide" /> : <img src={require("assets/img/icons/hide_show_eye/eye-closed.png").default} alt="Show" />}</button>
                                    </div>
                                    <div className="invalid-feedback d-block">
                                        {errors.newPassword}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="repeatPassword"
                                    >
                                        Repeat password
                                    </label>
                                    <div className="row px-3">
                                        <Input 
                                            className="col form-control"
                                            id="repeatPassword"
                                            type={passwordVisibleRP ? "text" : "password"}
                                            name="repeatPassword"
                                            required="required"
                                            autoComplete='new-password'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            invalid={errors.repeatPassword !== ""}
                                            >
                                        </Input>
                                        <button className="btn hide-show" onClick={toggleVisibilityRP}>{passwordVisibleRP ? <img src={require("assets/img/icons/hide_show_eye/eye-open.png").default} alt="Hide" /> : <img src={require("assets/img/icons/hide_show_eye/eye-closed.png").default} alt="Show" />}</button>
                                    </div>
                                    <div className="invalid-feedback d-block">
                                        {errors.repeatPassword}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div >
                                    <p className='mb-0'>Password policies:</p>
                                    <ul className='password-policies-list'>
                                        <li>Requires numbers</li>
                                        <li>Requires lowercase letters</li>
                                        <li>Requires uppercase letters</li>
                                        <li>Requires one special character <span style={{fontWeight: "normal"}}>(?!@#%&$^*.,':;~`=+-/|_[]{}())</span></li>
                                        <li>Minimum 8 characters long</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default NewPasswordReq;