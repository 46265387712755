/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: auth.js
# Description: This file contains different authentication validation functions.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/24/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from "axios";
// import { decodeToken } from "react-jwt";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL+"auth";

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const getCurrentUser = () => {
  if(localStorage.getItem("token_hensall_login")){
      return JSON.parse(localStorage.getItem("token_hensall_login"));
  }else{
      return JSON.parse(localStorage.getItem("token_hensall"));
  }
};

const getDecodeToken = () => {
  return parseJwt(getCurrentUser());
}

const getNameUser = () => {
  let decode = getDecodeToken();
  return decode?.sub ? decode.sub : "";
}

const getExpToken = () => {
  let decode = getDecodeToken();
  return decode.exp;
}

const checkTokenExpiry = () => {
  return new Promise((resolve,reject)=>{
    let timeExpired = getExpToken();
    let expired =  (timeExpired-1000) < ((Date.now() - 1000 * 60 * 5) / 1000);
    if (expired) {
      localStorage.removeItem("token_hensall_login");
      window.location.href = "/auth/login";
    }  
    resolve();
  });
}

const authService = {
  getCurrentUser,
  getDecodeToken,
  getNameUser,
  checkTokenExpiry
};

export default authService;