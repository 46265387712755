/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Dashboard.jsx
# Description: This is the base component for the charts 
# Created by: Juan David Olivares Padilla
# Creation Date: 11/21/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 09/20/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect } from "react";
import DashboardsContext from "context/DashboardsContext";
import LoadingContext from "context/LoadingContext";
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardTitle,
    CardHeader,
    Form,
    Input,
  } from "reactstrap";
  import '../../../assets/css/custom/mfa-preference.css';

import SimpleHeader from "components/Headers/SimpleHeader.js";
import Chart from 'chart.js';
import useAuth from "hooks/useAuth";

import { useForm } from "hooks/useForm";
import DashboardsFilterForm from "../../../services/dashboardsFilterForm";

const { REACT_APP_ENV } = process.env;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dic"
]

let today = Date.now();
today = new Date(today);

const initialForm = {
  date_initial: "",
  date_final: "",
};

let CWMetrics;
let first = true;

const Dashboard = () => {

    const { getCWMetrics, getLastMonthsMetrics, signinSuccesses, lastMonths } = useContext(DashboardsContext);
    const { setLoading } = useContext(LoadingContext);
    const { checkTokenExpiry } = useAuth();

    const {
      form,
      errors,
      setForm,
      setErrors,
      handleChange,
      handleBlur,
      handleSubmit,
    } = useForm(initialForm, DashboardsFilterForm.validationsForm);

    const addOneDayToDate = (date) => {
      var newDate = new Date(date);
      newDate.setUTCDate(newDate.getUTCDate() + 1);
      return newDate;
    }

    function sameDayPreviousMonth(date) {
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();
  
      // Create a new date for the same day of the previous month.
      var newDate = new Date(year, month - 1, day);
  
      // If the day of the new date isn't the same as the original date,
      // that means that the previous month didn't have that day, so set
      // the date to be the last day of the previous month.
      if (newDate.getDate() !== day) {
          newDate = new Date(year, month, 0);
      }
  
      // Format the date as "YYYY-MM-DD".
      year = newDate.getFullYear();
      month = newDate.getMonth() + 1;  // JavaScript months are 0-11, so we add 1 to get 1-12.
      day = newDate.getDate();
  
      // Pad the month and day with leading zeros if they are less than 10.
      if (month < 10) month = '0' + month;
      if (day < 10) day = '0' + day;
  
      return `${year}-${month}-${day}`;
    }

    function formatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // JavaScript months are 0-11, so we add 1 to get 1-12.
      var day = date.getDate();
  
      // Pad the month and day with leading zeros if they are less than 10.
      if(month < 10) month = '0' + month;
      if(day < 10) day = '0' + day;
  
      return `${year}-${month}-${day}`;
    }

    const handleFilter = (e) => {
      e.preventDefault();
      let valid = handleSubmit(e);
      if(valid){
        handleGetCWMetrics()
      }
    }

    const handleGetCWMetrics = () => {
      var parts = form.date_final.split("-");
      var final = new Date(parts[0], parts[1] - 1, parts[2]);

      getCWMetrics({ 
        Id: "m1",
        Namespace: "AWS/Cognito",
        MetricName: "SignInSuccesses",
        Period: 86400,
        StartTime: (form.date_initial ? form.date_initial : sameDayPreviousMonth(today)),
        EndTime: (form.date_final ? formatDate(addOneDayToDate(final)) : formatDate(addOneDayToDate(today))),
      });
    }

    const handleGetLastMonthMetrics = () => {
      // if (REACT_APP_ENV === "TESTING" || REACT_APP_ENV === "DEVELOPMENT") {
      //   getLastMonthsMetrics({ 
      //     Id: "m1",
      //     Namespace: "AWS/Cognito",
      //     MetricName: "SignInSuccesses",
      //     Period: 2678400,
      //     StartTimes: ["2022-07-1",
      //                  "2023-01-1",
      //                  "2024-01-1",
      //                 ],
      //     EndTimes: ["2022-12-31",
      //                "2023-12-31",
      //                "2024-03-30"
      //               ],
      //   });
      // } else {
        getLastMonthsMetrics({ 
          Id: "m1",
          Namespace: "AWS/Cognito",
          MetricName: "SignInSuccesses",
          Period: 2678400,
          StartTimes: ["2023-07-1",
                        "2024-01-1",
                      ],
          EndTimes: ["2023-12-31",
                      "2024-12-31",
                    ],
        });
      // }
    }

    useEffect( () => {
      checkTokenExpiry();
      setErrors(initialForm);
      setLoading(true);
      setForm({
        date_initial: sameDayPreviousMonth(today),
        date_final: formatDate(today),
      });
      first = true;
      handleGetCWMetrics();
      handleGetLastMonthMetrics();
    } ,[]);

    useEffect(()=> {
      if (signinSuccesses !== undefined) {
        // Parse AWS Date response
        let labels = [];
        signinSuccesses.timestamps.map((label) => {
          let temp = new Date(label);
          labels.push(months[temp.getUTCMonth()] + "-" + temp.getUTCDate())
        })
        
        if (first) {
          // Draw information in chart
          CWMetrics = new Chart(
            document.getElementById('SigninSuccesses'),
            {
              type: 'line',
              data: {
                labels: labels.reverse(),
                datasets: [{
                  label: 'Login Request',
                  data: signinSuccesses.values.reverse(),
                  backgroundColor: 'rgba(75, 192, 192, 0.25)',
                  borderColor: 'rgba(75, 192, 192)',
                  pointHoverBackgroundColor: 'rgba(75, 192, 192)',
                  borderWidth: 1,
                  yAxisId: 'y',
                  pointRadius: 4,
                  pointHoverRadius: 8,
                }],
              },
              options: {
                scales: {
                  display: true,
                  yAxes:[{
                    scaleLabel:{
                      display: true,
                      labelString: 'Number of Logins',
                      fontSize: 16,
                      fontColor: '#444',
                      lineHeight: 1,
                    },
                    ticks: {
                      min: 0,
                      max: 80,
                      stepSize: 20
                    }
                  }],
                  xAxes:[{
                    scaleLabel:{
                      display: true,
                      labelString: 'Days',
                      fontSize: 16,
                      fontColor: '#444',
                      lineHeight: 1,
                    }
                  }]
                }
              }
            }
          )
          first = false;
        } else {
          if (CWMetrics){
            CWMetrics.destroy();
            CWMetrics = new Chart(
              document.getElementById('SigninSuccesses'),
              {
                type: signinSuccesses.timestamps.length < 4 ? 'bar' : 'line',
                data: {
                  labels: labels.reverse(),
                  datasets: [{
                    label: 'Login Request',
                    data: signinSuccesses.values.reverse(),
                    backgroundColor: 'rgba(75, 192, 192, 0.25)',
                    borderColor: 'rgba(75, 192, 192)',
                    pointHoverBackgroundColor: 'rgba(75, 192, 192)',
                    borderWidth: 1,
                    yAxisId: 'y',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                  }],
                },
                options: {
                  scales: {
                    display: true,
                    yAxes:[{
                      scaleLabel:{
                        display: true,
                        labelString: 'Number of Logins',
                        fontSize: 16,
                        fontColor: '#444',
                        lineHeight: 1,
                      },
                      ticks: {
                        min: 0,
                        max: 80,
                        stepSize: 20
                      }
                    }],
                    xAxes:[{
                      scaleLabel:{
                        display: true,
                        labelString: 'Days',
                        fontSize: 16,
                        fontColor: '#444',
                        lineHeight: 1,
                      }
                    }]
                  }
                }
              }
            )
            
            CWMetrics.update();            
          }
        }
      }
    }, [signinSuccesses])

    useEffect(()=> {
      if (lastMonths && lastMonths.length === 3) {
        // Parse AWS Date response
        let labels = [];
        lastMonths[0].timestamps.map((label) => {
          let temp = new Date(label);
          labels.push(months[(temp.getUTCMonth())])
        })

        //Get current date
        let temp = Date.now();
        temp = new Date(temp)
        
        // Draw information in chart
        new Chart(
          document.getElementById('LastMonths'),
          {
            type: 'bar',
            data: {
              labels: labels,
              datasets: [
                {
                  label: 'Login requests for ' + (temp.getFullYear() - 2),
                  data: lastMonths[0].values.reverse(),
                  fill: false,
                  backgroundColor: 'rgba(153, 102, 255, 0.2)',
                  borderColor: 'rgba(153, 102, 255)',
                  borderWidth: 1,
                },
                {
                  label: 'Login requests for ' + (temp.getFullYear() - 1),
                  data: lastMonths[1].values.reverse(),
                  fill: false,
                  backgroundColor: 'rgba(255, 159, 64, 0.2)',
                  borderColor: 'rgba(255, 159, 64)',
                  borderWidth: 1,
                },
                {
                  label: 'Login requests for ' + (temp.getFullYear()),
                  data: lastMonths[2].values,
                  fill: false,
                  backgroundColor: 'rgba(75, 192, 192, 0.25)',
                  borderColor: 'rgba(75, 192, 192)',
                  borderWidth: 1,
                },
            ]
            },
            options: {
              responsive: true,
              scales: {
                yAxes:[{
                  scaleLabel:{
                    display: true,
                    labelString: 'Number of Logins',
                    fontSize: 16,
                    fontColor: '#444',
                    lineHeight: 1,
                  },
                  ticks: {
                    min: 0,
                    // max: 400,
                    stepSize: 100
                  }
                }],
                xAxes:[{
                  scaleLabel:{
                    display: true,
                    labelString: 'Months',
                    fontSize: 16,
                    fontColor: '#444',
                    lineHeight: 1,
                  }
                }]
              }
            }
          }
        )
      } else if (lastMonths && lastMonths.length === 2) {
          // Parse AWS Date response
          let labels = [];
          lastMonths[0].timestamps.map((label) => {
            let temp = new Date(label);
            labels.push(months[(temp.getUTCMonth())])
          })
  
          //Get current date
          let temp = Date.now();
          temp = new Date(temp)
          
          // Draw information in chart
          new Chart(
            document.getElementById('LastMonths'),
            {
              type: 'bar',
              data: {
                labels: labels,
                datasets: [
                  {
                    label: 'Login requests for ' + (temp.getFullYear() - 1),
                    data: lastMonths[0].values.reverse(),
                    fill: false,
                    backgroundColor: 'rgba(255, 159, 64, 0.2)',
                    borderColor: 'rgba(255, 159, 64)',
                    borderWidth: 1,
                  },
                  {
                    label: 'Login requests for ' + (temp.getFullYear()),
                    data: lastMonths[1].values,
                    fill: false,
                    backgroundColor: 'rgba(75, 192, 192, 0.25)',
                    borderColor: 'rgba(75, 192, 192)',
                    borderWidth: 1,
                  },
              ]
              },
              options: {
                responsive: true,
                scales: {
                  yAxes:[{
                    scaleLabel:{
                      display: true,
                      labelString: 'Number of Logins',
                      fontSize: 16,
                      fontColor: '#444',
                      lineHeight: 1,
                    },
                    ticks: {
                      min: 0,
                      // max: 400,
                      stepSize: 100
                    }
                  }],
                  xAxes:[{
                    scaleLabel:{
                      display: true,
                      labelString: 'Months',
                      fontSize: 16,
                      fontColor: '#444',
                      lineHeight: 1,
                    }
                  }]
                }
              }
            }
          )
      }
    }, [lastMonths])

    return (
      <>
        <SimpleHeader name="Statistics Dashboards" toUrl="statistics-dashboards" />
        <Container className="mt--5" fluid>
          <Card className="col-12 mx-auto px-0">
            <CardHeader className="align-items-center px-3">
              <h3 className="mb-0">Login Metric Charts</h3>
              <p className="text-sm mb-0">
                
              </p>
            </CardHeader>
            <CardBody className="px-3">
              <Row className="px-0">
                <Col className="px-3 col-12">
                    <Card className="px-0">
                      <CardBody className="px-3">
                        <CardTitle className="mb-3">Login Requests</CardTitle>
                        <canvas className="w-100 h-100 m-0 p-0" id="SigninSuccesses"></canvas>
                        <Form className="m-0 mt-3 p-0 w-100">
                          <Row>
                            <Col className="p-1 my-auto col-2 text-right">
                              <label
                                className="form-control-label m-0"
                                htmlFor="input-date_initial"
                                >
                                Start Date
                              </label>
                            </Col>
                            <Col className="p-1 col-4 col-md-3">
                              <Input
                                className="form-control"
                                id="input-date_initial"
                                placeholder=""
                                type="date"
                                name="date_initial"
                                required="required"
                                invalid={errors.date_initial !== ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={form.date_initial}
                                />
                                <div className="invalid-feedback">
                                  {errors.date_initial}
                                </div>
                            </Col>
                            <Col className="p-1 my-auto col-2 text-right">
                              <label
                                className="form-control-label m-0"
                                htmlFor="input-date_final"
                                >
                                End Date
                              </label>
                            </Col>
                            <Col className="p-1 col-4 col-md-3">
                              <Input
                                className="form-control"
                                id="input-date_final"
                                placeholder=""
                                type="date"
                                name="date_final"
                                required="required"
                                invalid={errors.date_final !== ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={form.date_final}
                                />
                                <div className="invalid-feedback">
                                  {errors.date_final}
                                </div>
                            </Col>
                            <Col className="py-2 py-md-0 w-100 text-center mt-1 col-12 col-md-2">
                              <Button
                                id="filterSubmit"
                                className="btn"
                                color="hensall"
                                onClick={handleFilter}
                              >
                                Filter
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                </Col>
                <Col className="px-3 col-12">
                    <Card className="px-0">
                      <CardBody className="px-3">
                        <CardTitle>Previous Years Login Activity</CardTitle>
                        <canvas className="w-100 h-100 m-0 p-0" id="LastMonths"></canvas>
                      </CardBody>
                    </Card>
                </Col>
              </Row>
            </CardBody>
            {/* <hr />
            <CardHeader className="align-items-center mt-0 pt-0">
              <h3 className="my-0">Database Statistics</h3>
              <p className="text-sm mb-0">
                These are some metric gathered from the API and database requests.
              </p>
            </CardHeader> */}
          </Card>
        </Container>
      </>
    );
  };
  
  export default Dashboard;