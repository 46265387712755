/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AppFooter.js
# Description: This file is the footer that appears in the Home with the application cards.
# Created by: Juan David Olivares Padilla
# Creation Date: 10/31/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import '../../assets/css/custom/bg-gradient.css'

function AppFooter({ clss }) {
  return (
    <>
      <footer className={"pb-3 pt-3 pt-md-4 bottom-0 " + clss} id="footer-main" style={{textShadow: "1px 1px 1px #111111"}}>
        <Container style={{height: "100%"}}>
          <Row className="align-items-center justify-content-center justify-content-lg-between">
            <Col sm="11" md="10" lg="6">
              <div className="copyright text-center text-xl-left text-white">
                  © Copyright {new Date().getFullYear()}{" "} 
                  <a
                    className="ml-1 footer-links"
                    href="https://hensallco-op.ca/"
                    target="_blank"
                  >
                    Hensall Co-op.
                  </a>
                  <p className="ml-2">All Rights Reserved.</p>
              </div>
            </Col>
            <Col sm="11" md="10" lg="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end text-center text-xl-start">
                <NavItem>
                  <NavLink
                    className="footer-links"
                    href="https://hensallco-op.ca/Privacy-Policy.htm"
                    target="_blank"
                  >
                    Privacy Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="footer-links"
                    href="https://hensallco-op.ca/Legal.htm"
                    target="_blank"
                  >
                    Legal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="footer-links"
                    to="/admin/FAQs"
                    tag={Link}
                  >
                    FAQs
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AppFooter;
