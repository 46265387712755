/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Form.jsx
# Description: This is the detail per register of Users of UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 17/05/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Form,
    Table,
    Input,
    Alert,
    Button,
    CardBody,
    FormGroup,
    Container,
    CardHeader,
    UncontrolledTooltip
  } from "reactstrap";
  
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationContext from "context/NotificationContext";
import PhoneInput from 'react-phone-number-input'
import { Link, useParams } from "react-router-dom";
import UsersContext from "context/UsersContext";
import UsersFormValidate from "../../../services/usersForm";
import { useForm } from "hooks/useForm";
import RoleSystems from "./RoleSystems.jsx";
import AdditionalInformationSystems from "./AdditionalInformationSystems.jsx";
import ReactBSAlert from "react-bootstrap-sweetalert";
import TableCustomFields from "./TableCustomFields";
import useAuth from "hooks/useAuth";

  const initialForm = {
    name: "",
	username: "",
    email: "",
  };

  const viewFieldsAcronymList = [
    "crm",
    "hsm",
    "contracts",
    "dispatch",
    "mantable",
    "mantables"
  ];

  const regexPhoneLenght = /^\+?[0-9]{11,21}$/;
  const regexPhoneOnlycc = /^\+?([0-9]{1,3})$/;

  const Formulario = ( ) => {

    const { 
        phone,
        oldPhone,
        module,
        setPhone,
        saveData,
        setModule,
        updateData,
        fieldUsers,
        detail:data,
        setToDetail,
        setToUpdate,
        roleSystems,
        alertProc,
        setAlertProc,
        deleteRoles,
        deleteField,
        adminSystems,
        applications,
        enableDisable,
        mfaPreference,
        currentPreference,
        setCurrentPreference,
        deleteSystems,
        toApplications,
        addAdminSystems,
        fetchFieldsUsers,
        setViewModalRoles,
        setToApplications,
        setViewModalAdditionalInformation,
    } = useContext(UsersContext);

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { checkTokenExpiry } = useAuth();
    
    const {
        form,
        errors,
        setForm,
        setErrors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm(initialForm, UsersFormValidate.validationsForm);

    const { id } = useParams();

    const bottomRef = useRef(null);

    const[state, setState] = useState({});
    const[idDelete, setIdDelete] = useState();
    const[option, setOption] = useState();
    const[customFields, setCustomFields] = useState([]);

    const mfaElem = document.querySelector("#mfa-config")

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const inputCheck = document.getElementById("customCheckAzure")
            inputCheck.checked ? (inputCheck.checked = false) : (inputCheck.checked = true)
        }
    };

    const hideAlert = () => {
        setState({ alert: null });
    };

    const confirmAlert = (id, option) => {
        setState({
            alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onCancel={() => hideAlert()}
                onConfirm={() => {setIdDelete(id); setOption(option); hideAlert();}}
                showCancel
                confirmBtnBsStyle="hensall-cancel"
                confirmBtnText="Yes, delete it!"
                cancelBtnBsStyle="hensall"
                cancelBtnText="Cancel"
                btnSize=""
            >
                You won't be able to revert this!
            </ReactBSAlert>
            )
        });
    };

    const confirmAlertEnableDisable = (item) => {
        if (!state.alert){
            setState({
                alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onCancel={() => hideAlert()}
                    onConfirm={() => {enableDisable(item); hideAlert();}}
                    showCancel
                    confirmBtnBsStyle="hensall-cancel"
                    confirmBtnText="Ok"
                    cancelBtnBsStyle="hensall"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    {data.mfa_config?.StatusCognito == 'Enable'
                    ? "You are about to Deactivate a User. This action will deny the user access to Hensall Login app. Do you wish to proceed?"
                    : "You are about to Activate a User. This action will grant the user access to Hensall Login app. Do you wish to proceed?"}
                </ReactBSAlert>)
            });
        }
    };
    
    useEffect(() => {
        checkTokenExpiry();
        let phoneField = document.getElementById("input-phone")
        if (!phoneField.classList.contains("form-control")) { 
            phoneField.classList.add("form-control");
        }
    }, []);

    useEffect(() => {
        setPhone(data.phone ? data.phone : '')
    },[data.phone]);

    useEffect(() => {
        if (toApplications === 'all' || toApplications === 'unassigned') {
            if (applications && applications[0]) {
                if (applications[0].id) {
                    setToApplications(applications[0].id)
                }
            }
        }
    },[]);

    useEffect(() => {
       if (alertProc) {
            setState({
                alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={"User created successfully!"}
                    onConfirm={() => {setAlertProc(false); hideAlert()}}
                    confirmBtnBsStyle="hensall"
                    confirmBtnText="Understood!"
                    btnSize=""
                >
                    {"Please, continue with the roles and permissions assignation. Using the new options enabled"}
                </ReactBSAlert>
                )
            });
       }
    },[alertProc]);

    useEffect(() => {
        applications.map((app)=> {
            if (viewFieldsAcronymList.includes(app.acronym)) {
                if (!customFields.includes(app.id)){
                    setCustomFields([app.id, ...customFields])
                }
            }
        })
    },[applications,toApplications, customFields]);

    useEffect(() => {
        if(data && Object.keys(data).length > 0 ) 
            fetchFieldsUsers(data.id)
    },[data]);

    useEffect(() => {
        if(id){
            setToDetail(id);
            setToUpdate(id);
            setModule("update");
        }else{
            setModule("add");
        }
    },[]);

    useEffect(() => {
        setForm(data);
        setErrors(initialForm);
    },[data]);

    useEffect(() => {
        if (data.mfa_config) {
            if (data.mfa_config.UserMFASettingList instanceof Array) {
                document.getElementById("radioNone")?.removeAttribute('disabled');
                if (data.mfa_config.UserMFASettingList.length > 1){
                    document.getElementById("radioSMS")?.removeAttribute('disabled');
                    document.getElementById("radioSt")?.removeAttribute('disabled');

                    if (data.mfa_config.PreferredMfaSetting === 'SMS_MFA') {
                        if (document.getElementById("radioSMS")){
                            document.getElementById("radioSMS").checked = true;
                        }
                        setCurrentPreference({
                            smsEnable: "true",
                            smsPreferred: "true",
                            softwareEnable: "true",
                            softwarePreferred: ""
                        })
                    }
                    if (data.mfa_config.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA') {
                        if (document.getElementById("radioSt")){
                            document.getElementById("radioSt").checked = true;
                        }
                        setCurrentPreference({
                            smsEnable: "true",
                            smsPreferred: "",
                            softwareEnable: "true",
                            softwarePreferred: "true"
                        })
                    }
                    if (data.mfa_config.UserMFASettingList.length === 2){
                        document.getElementById("radioChose")?.removeAttribute('disabled');
                        if (data.mfa_config.PreferredMfaSetting === '' && document.getElementById("radioChose")) {                        
                            document.getElementById("radioChose").checked = true;
                        }
                    }
                } else if (data.mfa_config.UserMFASettingList.length == 1){
                    if (data.mfa_config.PreferredMfaSetting === 'SMS_MFA') {
                        document.getElementById("radioSMS")?.removeAttribute('disabled');
                        if (document.getElementById("radioSMS")){
                            document.getElementById("radioSMS").checked = true;
                        }
                        setCurrentPreference({
                            smsEnable: "true",
                            smsPreferred: "true",
                            softwareEnable: "",
                            softwarePreferred: ""
                        })
                    }
                    if (data.mfa_config.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA') {
                        document.getElementById("radioSt")?.removeAttribute('disabled');
                        if (data.phone) {
                            document.getElementById("radioSMS")?.removeAttribute('disabled');
                        }
                        if (document.getElementById("radioSt")) {
                            document.getElementById("radioSt").checked = true;
                        }
                        setCurrentPreference({
                            smsEnable: "",
                            smsPreferred: "",
                            softwareEnable: "true",
                            softwarePreferred: "true"
                        })
                    }
                }
            } else {
                if (document.getElementById("radioNone")){
                    document.getElementById("radioNone")?.removeAttribute('disabled');
                    if (document.getElementById("radioNone")){
                        document.getElementById("radioNone").checked = true;
                    }
                }
            }
        }
    }, [data]);

    useEffect(() => {
        applications.map((app) => {
            if (app.acronym === 'hlogin') {
                mfaElem?.classList.remove('d-none');
            } 
            if (customFields.includes(app.id) && toApplications === app.id) {
                if (bottomRef.current) {
                    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        })
    }, [applications, mfaElem, toApplications]);

    useEffect(() => {
        if (applications) {
            applications.map((app) => {
                if (customFields.includes(app.id) && toApplications === app.id) {
                    if (bottomRef.current) {
                        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            })
        }
    }, [toApplications]);

    const handleEnableDisable = (e) => {
        e.preventDefault();
        confirmAlertEnableDisable(data);
    }

    const handleSelectApp = (e) => {
        const { value } = e.target;
        setToApplications(value);
    }
    
    const handleUpdate = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        if(valid){
            if (regexPhoneLenght.test(phone) || !phone){
                let newform = {...form}
                let newdata = {...data}

                newform.phone = phone ? phone : ''
                delete newform.fields
                delete newform.mfa_config

                newdata.phone = oldPhone ? oldPhone : ''
                delete newdata.fields
                delete newdata.mfa_config

                if (JSON.stringify(newdata) === JSON.stringify(newform)){
                    setType("info");
                    setMessage("No changes detected. Please cancel the process or change the information to save");
                    setStatus(1);
                } else {
                    updateData(newform);
                }
            } else if (regexPhoneOnlycc.test(phone)) {
                let newform = form
                newform.phone = ''
                delete newform.fields
                delete newform.mfa_config
                updateData(newform);
            }else {
                setType('danger')
                setMessage('Invalid phone')
                setStatus(1)
            }
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        let valid = handleSubmit(e);
        const inputCheck = document.getElementById("customCheckAzure")
        form.azure = inputCheck?.checked.toString();
        if(valid){
            if (regexPhoneLenght.test(phone) || !phone){
                let data = form
                data.phone = phone ? phone : ''
                saveData(data);
            } else if (regexPhoneOnlycc.test(phone)) {
                let data = form
                data.phone = ''
                saveData(data);
            } else {
                setType('danger')
                setMessage('Invalid phone')
                setStatus(1)
            }
        }
    }

    const loadModalRoles = (e) => {
        if (toApplications){
            if (roleSystems) {
                setType('danger')
                setMessage('Users can only have one role per application. Delete the previous role to add a new role.')
                setStatus(1)
            } else {
                setViewModalRoles(true);
            }
        } else {
            setType('info')
            setMessage('Please select an application in the top-right filter. The roles or permissions will be added to the selected application')
            setStatus(1)
        }
    }

    const loadModalAdditionalInformation = (e) => {
        setViewModalAdditionalInformation(true);
    }

    const handleAddSystems = (e) => {
        let appSelectedName;

        applications.map((app) => {
            if (app.id === toApplications){
                appSelectedName = app.name;
            }
        })


        if (adminSystems) {
            setType('info')
            setMessage('This user already has UAM permissions for the selected application')
            setStatus(1)
        } else if (toApplications) {
            setState({
                alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={"Are you sure?"}
                    onConfirm={() => {addAdminSystems(); hideAlert();}}
                    onCancel={() => hideAlert()}
                    showCancel
                    confirmBtnBsStyle="hensall-cancel"
                    confirmBtnText="Yes, I'm sure!"
                    cancelBtnBsStyle="hensall"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    {"Do you want to give access to \"" + appSelectedName + "\" application?"}
                </ReactBSAlert>
                )
            });
        } else {
            setType('info')
            setMessage('Please select an application in the top-right filter. The roles or permissions will be added to the selected application')
            setStatus(1)
        }
        
    }

    const hableDeleteField = async (idField) => {
        await deleteField(idField)
        if(data && Object.entries(data).length > 0 ) {
            await fetchFieldsUsers(data.id)
        }
    }

    useEffect(() => {
        if(idDelete){
            if(option === "role"){
                handleDeleteRoles(idDelete);
            } else if(option === "customField") {
                hableDeleteField(idDelete)
            } else {
                handleDeleteSystems(idDelete);
            }
        }
    },[idDelete]);

    const handleDeleteRoles = (id) => {
        deleteRoles(id);
    }

    const handleDeleteSystems = (id) => {
        deleteSystems(id);
    }

    const handleSaveMFA = () => {
        let rtn;

        if (document.getElementById("radioNone").checked) {
            rtn = {
                smsEnable: "",
                smsPreferred: "",
                softwareEnable: "",
                softwarePreferred: ""
            }
        } if (document.getElementById("radioSMS").checked) {
            rtn = {
                smsEnable: "true",
                smsPreferred: "true",
                softwareEnable: "",
                softwarePreferred: ""
            }
            if (data.mfa_config.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA') {
                rtn.softwareEnable = "true";
            }
        } if (document.getElementById("radioSt").checked) {
            rtn = {
                smsEnable: "",
                smsPreferred: "",
                softwareEnable: "true",
                softwarePreferred: "true"
            }
            if (data.phone) {
                rtn.smsEnable = "true";
            }
        } if (document.getElementById("radioChose").checked) {
            rtn = {
                smsEnable: "true",
                smsPreferred: "",
                softwareEnable: "true",
                softwarePreferred: ""
            }
        }

        if (JSON.stringify(rtn) !== JSON.stringify(currentPreference)) {
            applications.map((app) => {
                if (app.acronym === 'hlogin') {
                    rtn ["email"] = data.email;
                    mfaPreference(rtn);
                }
            })
        } else {
            setType('info')
            setMessage("Preference hasn't changed")
            setStatus(1)
        }
    }

    return (
      <>
        {state.alert}
        <SimpleHeader name={"User " + (module ? (module[0].toUpperCase() + module.slice(1)) : "")} parentName="Users" toUrl="users" />
        <Container className="mt--6" fluid>
            <Row>
                <div className="col">
                <Card>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col>
                                <h2 className="mb-0">Users Form</h2>
                                <p className="text-sm mb-0">
                                    This is a form to create a new user or update its information.
                                </p>
                            </Col>
                            {module === "add" ? ("") : (
                                <Col className="text-right" xs="4">
                                    <Input 
                                    className="form-control"
                                    id="input-type"
                                    type="select"
                                    name="type"
                                    value={toApplications}
                                    onChange={handleSelectApp}
                                    onBlur={handleSelectApp}
                                    >
                                    <option value="" hidden>Select an Application</option>
                                    {applications.map(item => (
                                        <option key={item.id} value={item.id}>{item.text}</option>
                                    ))};
                                    </Input>
                                </Col>)
                            }
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <h3 className="heading-small text-muted mb-3 col-7 col-sm-6 col-md-9 col-lg-10">Information</h3>
                                {(module === "update" && data.mfa_config?.StatusAccount == "CONFIRMED"
                                ?  <Button 
                                        className="h-75 py-1 px-0 col-5 col-sm-6 col-md-3 col-lg-2"
                                        color={data.mfa_config?.StatusCognito == 'Enable' ? "hensall-cancel" : "hensall"}
                                        onClick={handleEnableDisable}>
                                        {data.mfa_config?.StatusCognito == 'Enable' ? "Deactivate User" : "Activate User"}
                                    </Button>
                                : "")}
                            </Row>
                            <div>
                                <Row>
                                    {module === "update" ? (
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-name" >Status</label>
                                                <Input
                                                    className="form-control"
                                                    id="input-name"
                                                    placeholder=""
                                                    type="text"
                                                    name="status"
                                                    readOnly={true}
                                                    value={(data.mfa_config?.StatusCognito === "Disable")
                                                        ? "Inactive"
                                                        : "Active"}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )
                                    : ""}
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-name" >Name</label>
                                            <Input
                                                className="form-control"
                                                id="input-name"
                                                placeholder=""
                                                type="text"
                                                name="name"
                                                required="required"
                                                invalid={errors.name !== ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={data.name}
                                            />
                                            <div className="invalid-feedback">{errors.name}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-username" >Username</label>
                                            <Input
                                                className="form-control"
                                                id="input-username"
                                                placeholder=""
                                                type="text"
                                                name="username"
                                                required="required"
                                                invalid={errors.username !== ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={data.username}
                                            />
                                            <div className="invalid-feedback">{errors.username}</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-email">Email</label>
                                            <Input
                                                className="form-control"
                                                id="input-email"
                                                placeholder=""
                                                type="text"
                                                name="email"
                                                required="required"
                                                invalid={errors.email !== ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={data.email}
                                            />
                                            <div className="invalid-feedback">{errors.email}</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-phone">Phone</label>
                                            <PhoneInput
                                                name="input-phone"
                                                id="input-phone"
                                                international
                                                defaultCountry="CA"
                                                className="pr-0"
                                                onChange={setPhone}
                                                value={phone}
                                            >
                                            </PhoneInput>
                                            <div className="invalid-feedback">{errors.phone}</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {module == "add" ? (
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <input
                                                    className="custom-control-input-checkbox"
                                                    id="customCheckAzure"
                                                    name="customCheckAzure"
                                                    onKeyDown={handleKeyDown}
                                                    type="checkbox"
                                                />
                                                <label
                                                    className="m-0 p-0"
                                                    htmlFor="customCheckAzure"
                                                    style={{cursor: "pointer"}}
                                                >
                                                    <span className="text-muted">Hensall network user</span>
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                ) : ""}
                                <Row className="col justify-content-end mb-4">
                                    {module == "update" 
                                        ? <Button color="hensall" onClick={handleUpdate}>Update</Button>
                                        : <Button color="hensall" onClick={handleSave}>Save</Button>
                                    }
                                    <Link
                                        color="default"
                                        to={"/admin/users"}
                                        className="btn btn-hensall-cancel"
                                    >
                                        Cancel
                                    </Link>
                                </Row>
                                {module == "update" ? (
                                <>
                                    <hr className="mt-0 mb-3"></hr>
                                    <Row className="align-items-center mb-4">
                                        <Col>
                                            <h2 className="heading-small text-muted ml-2 mb-0">
                                                Roles & Permissions Assignation
                                            </h2>
                                        </Col>
                                        {/* <Col className="text-right" xs="4">
                                            <Input 
                                            className="form-control"
                                            id="input-type"
                                            type="select"m
                                            name="type"
                                            value={toApplications}
                                            onChange={handleSelectApp}
                                            onBlur={handleSelectApp}
                                            >
                                            <option value="" hidden>Select an Application</option>
                                            {applications.map(item => (
                                                <option key={item.id} value={item.id}>{item.text}</option>
                                            ))};
                                            </Input>
                                        </Col> */}
                                    </Row>
                                    <Row className="mt-3">
                                        <Col lg="6">
                                            <Card>
                                                <CardHeader className="border-0">
                                                    <Row>
                                                        <Col xs="6"><h3 className="mb-0">Application Roles</h3></Col>
                                                        <Col className="text-right" xs="6">
                                                            <a 
                                                                onClick={e => loadModalRoles(e)} 
                                                                className="btn-round btn-icon btn btn-hensall btn-sm"
                                                            >
                                                                <span className="btn-inner--icon mr-1"><i className="ni ni-fat-add"></i></span>
                                                                <span className="">Add Application Role</span>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <Table className="align-items-center table-flush table-permissions" responsive>
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th>Application</th>
                                                        <th>Role</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {roleSystems ? (
                                                        <tr key={roleSystems.id}>
                                                            <td>{roleSystems.system.name}</td>
                                                            <td>{roleSystems.role.description}</td>
                                                            <td className="table-actions">
                                                                <Button
                                                                    className='btn btn-hensall-cancel btn-sm'
                                                                    onClick={e => confirmAlert(roleSystems.id, "role")}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        ):
                                                        (
                                                            <tr>
                                                                <td>
                                                                    <Alert color="secondary" className="m-2">
                                                                        There is no information of <strong>Application Roles !</strong>
                                                                    </Alert>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Card>
                                        </Col>
                                        <Col lg="6">
                                            <Card>
                                                <CardHeader className="border-0">
                                                    <Row>
                                                        <Col xs="6"><h3 className="mb-0">UAM permissions</h3></Col>
                                                        <Col className="text-right" xs="6">
                                                            <a 
                                                                onClick={e => handleAddSystems(e)} 
                                                                className="btn-round btn-icon btn btn-hensall btn-sm"
                                                            >
                                                                <span className="btn-inner--icon mr-1"><i className="ni ni-fat-add"></i></span>
                                                                <span className="">Add UAM permissions</span>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <Table className="align-items-center table-flush table-permissions" responsive>
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th>Application</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {adminSystems ? (
                                                        <tr key={adminSystems.id}>
                                                            <td>{adminSystems.name+" - "+adminSystems.acronym}</td>
                                                            <td className="table-actions">
                                                                <Button
                                                                    className='btn btn-hensall-cancel btn-sm'
                                                                    onClick={e => confirmAlert(adminSystems.id, "system")}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        ): (
                                                            <tr>
                                                                <td>
                                                                    <Alert color="secondary" className="m-2">
                                                                        There is no information of <strong>Applications Admin!</strong>
                                                                    </Alert>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <h2 className="heading-small text-muted ml-2 mb-3">
                                                Additional information
                                            </h2>
                                        </Col>
                                        {/* {customFields.includes(toApplications) ? */}
                                        <Col lg="6">
                                            <Card>
                                                <CardHeader className="border-0">
                                                    <Row>
                                                        <Col xs="6"><h3 className="mb-0">Custom fields</h3></Col>
                                                        <Col className="text-right" xs="6">
                                                            <a 
                                                                className="btn-round btn-icon btn btn-hensall btn-sm"
                                                                onClick={e => loadModalAdditionalInformation(e)}
                                                            >
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="ni ni-fat-add"></i>
                                                                </span>
                                                                <span className="">Add Custom field</span>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <TableCustomFields id={data.id} confirmAlert={confirmAlert} />
                                            </Card>
                                        </Col>
                                        {/* : ""} */}
                                        <Col lg="6" id="mfa-config" className="d-none">
                                            <Card>
                                                <CardHeader className="border-0">
                                                    <Row>
                                                        <Col xs="6"><h3 className="mb-0">MFA Configuration</h3></Col>
                                                        <Col className="text-right" xs="6">
                                                            <a 
                                                                onClick={handleSaveMFA} 
                                                                className="btn-round btn-icon btn btn-hensall btn-sm"
                                                            >
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="ni ni-settings-gear-65"></i>
                                                                </span>
                                                                Save MFA Preference
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <Col className="px-0">
                                                    <div className="bg-secondary px-4 py-2 " 
                                                        style={{
                                                            borderTop: "solid 1px rgb(233, 236, 239)",
                                                            borderBottom: "solid 1px rgb(233, 236, 239)",
                                                        }}
                                                    >
                                                        <p className='form-control-label text-gray m-0'>Choose MFA preference type:</p>
                                                    </div>
                                                    <div className="m-4 pb-3">
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="radioNone"
                                                                name="mfaPreference"
                                                                type="radio"
                                                                value="NONE"
                                                                disabled
                                                            />
                                                            <label className="custom-control-label" htmlFor="radioNone">
                                                                None
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="radioSMS"
                                                                name="mfaPreference"
                                                                type="radio"
                                                                value="SMS_MFA"
                                                                disabled
                                                            />
                                                            <label className="custom-control-label" htmlFor="radioSMS">
                                                                By SMS
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input
                                                                className="custom-control-input"
                                                                id="radioSt"
                                                                name="mfaPreference"
                                                                type="radio"
                                                                value="SOFTWARE_TOKEN_MFA"
                                                                disabled
                                                            />
                                                            <label className="custom-control-label" htmlFor="radioSt">
                                                                By authentication software
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                className="custom-control-input"
                                                                id="radioChose"
                                                                name="mfaPreference"
                                                                type="radio"
                                                                value="CHOSE_PREFRENCE"
                                                                disabled
                                                            />
                                                            <label className="custom-control-label" htmlFor="radioChose" id="mfa-info">
                                                                Chose a preference on login <span className="m-0 p-0" style={{ color: "#D92632" }}>&#128712;</span>
                                                            </label>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                placement="right"
                                                                target="mfa-info"
                                                                >
                                                                To enable this option, set up both MFA methods at least once.
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                                ):("")}
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </Container>
        <RoleSystems />
        <AdditionalInformationSystems data={data} />
        <div ref={bottomRef}></div>
      </>
    );
  };
  
  export default Formulario;