/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Article4.jsx
# Description: This is the content of a FAQs article, with images, styling and content.
# Created by: Juan David Olivares Padilla
# Creation Date: 02/09/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

import { Container } from "reactstrap";

import "assets/vendor/nucleo/css/nucleo.css";

function Article4() {
    const path = window.location.pathname
    const pathArray = path.split('/')
    let redirect = "/auth/FAQs"
    
    if (pathArray[1] === 'admin') {
        redirect = "/admin/FAQs"
    }
    
    return(
        <>
            <Container
                style={{
                    padding: "3em 0 2em 0"
                }}
                >    
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: "0.375em",
                    padding: "2em",
                    marginTop: "0.5em"
                }}>
                <div>
                    <Link to={redirect}><i className="ni ni-bold-left"></i> <strong>Back</strong> </Link>
                    <h1 className="text-center mb-6">How to setup MFA by Software Token on MacOS/Safari browser</h1>
                </div>
                <h3>
                    1. Open the AppStore on your computer, the application woth this icon"
                    <span> <img alt="PlayStore_logo" src="/FAQs/appstore_icon.png" style={{height: "30px", width: "30px", boxShadow:".25px .25px 5px"}}/> </span>
                    ", type "Authenticator" in the search bar, and search:
                </h3>
                <div className="mb-6">
                    <img src="/FAQs/Article4/screenshot1.png" alt="Screenshot" width="100%"/>
                </div>
                <h3>
                    2. You will find plenty of options you can set-up. But we strongly recommend the one named just “Authen” and has this icon "
                    <span> <img alt="Authenticator_logo" src="/FAQs/authenticator_icon.png" style={{height: "30px", width: "30px", boxShadow:"1.5px 1.5px 5px", border:"1.5px solid #000"}}/> </span>
                    ", open it and click the "Get" button:
                </h3>
                <div className="mb-6">
                    <img src="/FAQs/Article4/screenshot2.png" alt="Screenshot" width="100%"/>
                </div>
                <h3>
                    3. And also click the "Install" button:
                </h3>
                <div className="mb-6">
                    <img src="/FAQs/Article4/screenshot3.png" alt="Screenshot" width="100%"/>
                </div>
                <h3>
                    4. Complete any security or authorization process, wait for the application to install and the click the "Open" button:
                </h3>
                <div className="mb-3">
                    <img src="/FAQs/Article4/screenshot4.png" alt="Screenshot" width="100%"/>
                </div>
                <h3>
                    5. Now you can use the Authenticator on any compatible app, in our case we will show how to use it on Hensall Login but you can set it up for many apps:
                </h3>
                <ul>
                    <li className="mb-4">
                    <h4>When you are setting up your MFA by software inside Hensall Login. First, you will receive a secret key. You must click over it to copy the text to the clipboard and use it later:</h4>
                        <div className="mb-6 text-center">
                            <img src="/FAQs/Article4/screenshot10.png" alt="Screenshot" width="50%"/>
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>
                            Open your Safari browser. Next to the search bar you will see the "Authen" extension added with the icon "
                            <span> <img alt="Authenticator_logo" src="/FAQs/authenticator_icon.png" style={{height: "30px", width: "30px", boxShadow:"1.5px 1.5px 5px", border:"1.5px solid #000"}}/> </span>
                            ", open it and also click the pencil icon to edit:
                        </h4>
                        <div className="mb-6">
                            <img src="/FAQs/Article4/screenshot5.png" alt="Screenshot" width="100%"/>
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>Click on the "+" icon to add a new register:</h4>
                        <div className="mb-6 text-center">
                            <img src="/FAQs/Article4/screenshot6.png" alt="Screenshot" width="100%"/>
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>Then click on 'Manual Entry':</h4>
                        <div className="mb-6 text-center">
                            <img src="/FAQs/Article4/screenshot7.png" alt="Screenshot" width="100%"/>
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>Paste the key in the 'secret' field. Then choose a name you could remember the site this belongs to and type it on the 'issuer' field, example: 'Hensall Login'. And finally click 'Ok' to confirm:</h4>
                        <div className="text-center">
                            <img src="/FAQs/Article4/screenshot8.png" alt="Screenshot" width="100%" />
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>Finally, click the Temporary One-Time Password(TOTP) code the Authenticator generates. Then, back to the application, paste the code on the 'Verification code' field and click on 'Send':</h4>
                        <div className="text-center align-items-center">
                            <img src="/FAQs/Article4/screenshot9.png" alt="Screenshot" width="35%" className="px-2"/>
                            <img src="/FAQs/Article4/screenshot12.png" alt="Screenshot" width="55%" className="px-2"/>
                        </div>
                    </li>
                    <li className="mb-4">
                        <h4>For now on whenever you are asked for SoftwareToken MFA verification code you must provide the code generated on the Authenticator.</h4>
                    </li>
                </ul>
            </div>
            </Container>
        </>
    );

}

export default Article4;