/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: ForgotPassword.jsx
# Description: This is the Forgot Password form with all triggers of modals and authentication methods.
# Created by: Juan David Olivares Padilla
# Creation Date: 10/28/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/16/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useContext, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import AuthHeader from "components/Headers/AuthHeader.js";
import AuthFooter from "components/Footers/AuthFooter";

import NotificationContext from "../../../context/NotificationContext";
import LoadingContext from "../../../context/LoadingContext";
import LoginContext from "../../../context/LoginContext";

import ForgotPasswordForm from "../../../services/forgotPasswordForm";

import NewPassword from "./NewPassword";

import { useForm } from "hooks/useForm";


const initialForm = {
  email: "",
};

export default function ForgotPassword() {

  const { setMessage, setStatus, setType } = useContext(NotificationContext);
  const { checkEmailExistence } = useContext(LoginContext);
  const { setLoading } = useContext(LoadingContext);

  const {
      validateInit,
      validate,
      form,
      errors,
      setValidateInit,
      setValidate,
      setForm,
      setErrors,
      handleChange,
      handleBlur,
      handleSubmit,
    } = useForm(initialForm, ForgotPasswordForm.validationsForm);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSend(e);
    }
  }

  const handleSend = (e) => {
    e.preventDefault();
    let valid = handleSubmit(e);
    if(valid){
      checkEmailExistence(form);
    }
  }

  return (
    <>
      <div className="bg-gradient-custom">
      <AuthHeader
        title="Forgot password"
        lead1="Please enter your email. A verification code will be sent to your inbox to continue process and create new password."
      />
      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="input-email"
                        placeholder="Email"
                        type="text"
                        name="email"
                        required="required"
                        invalid={errors.email !== ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                      />
                      <div className="invalid-feedback">
                        {errors.email}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <button type="submit" className="my-2 btn btn-hensall" onClick={handleSend}>Send</button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AuthFooter clss="auth-footer-custom2 w-100"/>
      </div>
      <NewPassword />
    </>
  )
}
