import { helpHttp } from "helpers/helpHttp";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { TYPES } from "actions/genericAction";
import {
  genericReducer,
  genericInitialState,
} from "../reducers/genericReducer";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";
import { useAuth } from "hooks/useAuth";

const FieldsContext = createContext();

const FieldsProvider = ({ children }) => {
  const { getNameUser, setApp, getApp } = useAuth();
  
  const [field, setField] = useState();
  const [toDetail, setToDetail] = useState();
  const [toUpdate, setToUpdate] = useState();
  const [detail, setDetail] = useState({});
  const [module, setModule] = useState();

  const [applications, setApplications] = useState([]);
  const [toApplications, setToApplications] = useState();

  const [viewModal, setViewModal] = useState();

  const history = useHistory();

  const { setMessage, setStatus, setType } = useContext(NotificationContext);
  const { setLoading } = useContext(LoadingContext);
  const { REACT_APP_API_URL } = process.env;
  const [state, dispatch] = useReducer(genericReducer, genericInitialState);
  const { db } = state;

  let api = helpHttp();
  let url = REACT_APP_API_URL + "field";
  let urlFetch = REACT_APP_API_URL + "field";

  useEffect(() => {
    fetchDataApplications();
    if (getApp) {
      setToApplications(getApp);
    }
  }, []);

  useEffect(() => {
    if (toUpdate && toUpdate != 0) {
      fetchDataDetail();
    }
  }, [toUpdate]);

  useEffect(() => {
    if (toApplications) {
      if (toApplications === "all" || toApplications === "unassigned") {
        setToApplications("");
        setApp("");
      } else {
        setApp(toApplications);
        fetchDataByApp();
      }
    }
  }, [toApplications]);

  const fetchDataByApp = () => {
    setLoading(true);
    let endpoint = REACT_APP_API_URL + "field";
    api.get(endpoint).then((res) => {
      if (!res.err) {
        dispatch({ type: TYPES.READ_ALL_DATA, payload: res.data });
      } else {
        dispatch({ type: TYPES.NO_DATA });
      }
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
  });
  };

  const fetchDataApplications = () => {
    url = REACT_APP_API_URL + "admins/current/systems";
    api.get(url).then((res) => {
      var data = res.map(function (obj) {
        obj.text = obj.text || obj.name;
        return obj;
      });
      setApplications(data);
    }).catch((err) => {
      setLoading(false);
      setType('danger')
      setMessage("Can't load data")
      setStatus(1)
  });
  };

  const fetchDataDetail = () => {
    setLoading(true);
    url = REACT_APP_API_URL + "field";
    url = url + "/" + toUpdate;
    api.get(url).then((res) => {
      setDetail(res);
      setLoading(false);
    });
  };

  const fetchField = async (field_id) => {
    const res = await api.get(`${REACT_APP_API_URL}field/${field_id}`)
    setField(res);
  };

  const saveData = (data) => {
    setLoading(true);
    let endpoint = url;
    let newData = data;
    delete newData.id;
    let options = {
      body: newData,
      headers: { "content-type": "application/json" },
    };
    api.post(endpoint, options).then((res) => {
      if (!res.err) {
        dispatch({ type: TYPES.CREATE_DATA, payload: res });
        history.push("/admin/fields");
        setType("success");
        setMessage("The registry was updated correctly");
        setStatus(1);
      }
      setLoading(false);
    });
  };

  const saveOption = async (data) => {
    let newData = data;
    let options = {
      body: newData,
      headers: { "content-type": "application/json" },
    };
    const res = await api.post(`${url}/option`, options)
    if (!res.err) {
      dispatch({ type: TYPES.CREATE_DATA, payload: res });
      setType("success");
      setMessage("The registry was updated correctly");
      setStatus(1);
    }
    setLoading(false);
  };

  const updateOption = (data) => {
    setLoading(true);
    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };
    api.put(`${url}/option`, options).then((res) => {
      if (!res.err) {
        api.get(`${url}/${data.field_id}`).then((response) => {
          if (!response.err) {
            setDetail(response);
            dispatch({ type: TYPES.UPDATE_DATA, payload: res });
            setType("success");
            setMessage("The registry was updated correctly");
            setStatus(1);
          }
        });
      }
      setLoading(false);
    });
  };

  const updateData = (data) => {
    setLoading(true);
    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };
    api.put(url, options).then((res) => {
      if (!res.err) {
        setDetail(res);
        dispatch({ type: TYPES.UPDATE_DATA, payload: res });
        history.push("/admin/fields");
        setType("success");
        setMessage("The registry was updated correctly");
        setStatus(1);
      } else {
      }
      setLoading(false);
    });
  };

  const deleteData = (id) => {
    setLoading(true);
    let endpoint = url + "/" + id;
    let options = {
      body: "",
      headers: { "content-type": "application/json" },
    };
    api.del(endpoint, options).then((res) => {
      if (!res.err) {
        dispatch({ type: TYPES.DELETE_DATA, payload: id });
        setType("success");
        setMessage("The registry was deleted correctly");
        setStatus(1);
      }
      setLoading(false);
    });
  };

  const deleteOption = async (id) => {
    setLoading(true);
    let endpoint = url + "/option/" + id;
    let options = {
      body: "",
      headers: { "content-type": "application/json" },
    };
    const res = await api.del(endpoint, options)
    if (!res.err) {
      dispatch({ type: TYPES.DELETE_DATA, payload: id });
      setType("success");
      setMessage("The registry was deleted correctly");
      setStatus(1);
    }
    setLoading(false);
  };

  const data = {
    db,
    detail,
    setToDetail,
    setToUpdate,
    updateData,
    updateOption,
    saveData,
    deleteData,
    module,
    deleteOption,
    setModule,
    setDetail,
    viewModal,
    saveOption,
    setViewModal,
    applications,
    toApplications,
    setToApplications,
    fetchField,
    field,
    setField,
    setLoading,
  };

  return (
    <FieldsContext.Provider value={data}>{children}</FieldsContext.Provider>
  );
};

export { FieldsProvider };
export default FieldsContext;
