/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Auth.js
# Description: This file contains the graphic setup of the application layout before login, including sidebar logic, navigation and more.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/06/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
//import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import { NotificationProvider } from "context/NotificationContext";
import { LoadingProvider } from "context/LoadingContext";
import { LoginProvider } from "context/LoginContext";
import { useAuth } from "../hooks/useAuth";

function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    //document.body.classList.add("bg-default");
    document.body.classList.add("custom-body");
    // Specify how to clean up after this effect:
    // return function cleanup() {
    //   document.body.classList.remove("bg-default");
    // };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isAuthenticatedApp, handleOnlineStatusChange,isOnlineAlert } = useAuth();

  React.useEffect(() => {
    handleOnlineStatusChange();
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
        window.removeEventListener('online', handleOnlineStatusChange);
        window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [])
  return (
    <>
      {isOnlineAlert.alert}
      <div className="main-content bg-gradient-custom2 h-100" ref={mainContentRef}>
      <LoadingProvider>
      <NotificationProvider >
      <LoginProvider >
        <AuthNavbar />
        <Switch>
          {!isAuthenticatedApp() ? getRoutes(routes):""}
          <Redirect from="*" to="/admin/app" />
        </Switch>
      </LoginProvider >
      </NotificationProvider>
      </LoadingProvider>
      </div>
    </>
  );
}

export default Auth;
