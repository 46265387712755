/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: MfaSetup_RAC.jsx
# Description: This MFA Setup Respond to Auth Challenge(RAC) is a modal window form. For the Cognito authentication challenge resolve used during login.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/18/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/29/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect } from 'react';
import LoginContext from 'context/LoginContext';
import NotificationContext from "context/NotificationContext";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import "../../../assets/css/custom/new-password-form.css"
import {
    Button,
    Col,
    Form,
    FormGroup,
    Modal,
    Row,
  } from "reactstrap";
  
function MfaSetup_RAC() {

    const { updatePhone, viewModalMfaSetup, closeModalMfaSetup, phone, setPhone, stSetupFt, stSetup, session, temptToken,temptJwtToken } = useContext(LoginContext);
    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    useEffect(() => {
        let phoneField = document.getElementById("phone")
        if (phoneField){
            if (!phoneField.classList.contains("form-control")) { 
                phoneField.classList.add("form-control"); 
            }
        }
    }, []);
    useEffect(() => {
        let phoneField = document.getElementById("phone")
        if (phoneField){
            if (!phoneField.classList.contains("form-control")) { 
                phoneField.classList.add("form-control"); 
            }
        }
    }, [phone]);

    const closeModal = () => {
        closeModalMfaSetup();
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleSend(event);
        }
    };

    const handleSend = (e) => {
        e.preventDefault();
        let phoneregex = /^[+]*[0-9]{10,13}$/g
        if (phoneregex.test(phone.trim())){
            updatePhone({phone: phone}, temptJwtToken)
        } else {
            setType("danger");
            setMessage("Error invalid phone number");
            setStatus(1);
        }
    }

    const handleNoPhone = () => {
        if (session) {
            closeModal();
            stSetupFt();
        } else {
            closeModal();
            stSetup(temptToken);
        }
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalMfaSetup}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    MFA Setup
                </h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div>
                        <p className='title'>Please introduce your phone number to activate MFA by SMS. Or setup software(Google Authenticator, Authy, etc) if you don't have a phone number .</p>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="phone"
                                    >
                                    Phone
                                    </label>
                                    <PhoneInput
                                        name="phone"
                                        id="phone"
                                        international
                                        defaultCountry="CA"
                                        value={phone}
                                        onChange={setPhone}
                                        className="pr-0"
                                        onKeyDown={handleKeyDown}
                                        >
                                    </PhoneInput>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleSend}
                    >
                        Send
                    </Button>
                    <Button
                        className="btn btn-hensall"
                        color="hensall"
                        onClick={handleNoPhone}
                    >
                        Use Software
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default MfaSetup_RAC;