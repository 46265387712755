export const validationsForm = (form) => {
    let errores = {};

    if (!form.name?.trim()) {
        errores.name = "Please the field is required.";
    } else if (form.name?.trim().length > 30){
        errores.name = "The field must be less than 30 characters.";
    } else{
        errores.name = "";
    }

    if (!form.description?.trim()) {
        errores.description = "Please the field is required.";
    } else if (form.description?.trim().length > 30){
        errores.description = "The field must be less than 30 characters.";
    } else{
        errores.description = "";
    }

    if (!form.type?.trim()) {
        errores.type = "Please the field is required.";
    }else{
        errores.type = "";
    }

    return errores;
};

const FieldsForm = {
    validationsForm,
};
  
export default FieldsForm;