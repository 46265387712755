/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: SelectMFAType_RAC.jsx
# Description: This Select MFA Type - Respond Auth Challenge(RAC) is a modal window form. For the Cognito authentication challenge resolve used during login.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/21/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from 'react';
import LoginContext from 'context/LoginContext';
import NotificationContext from "context/NotificationContext";
import {
    Button,
    Form,
    Modal,
    Row,
    FormGroup,
    Input,
    Label
  } from "reactstrap";
  
function SelectMfaType_RAC() {

    const { SelectMfaTypeRac, viewModalSelectMfaType, closeModalSelectMfaType } = useContext(LoginContext);
    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const closeModal = (e) => {
        e.preventDefault();
        closeModalSelectMfaType();
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleSend(event);
        }
    };

    const handleSend = () => {
        const radios = document.getElementsByName('mfaPreference')
        let ref = false
        let choice

        radios.forEach(imput => {
            if (imput.checked) {
                choice = imput.value;
                ref = true
            }
        });
        if (ref && choice) {
            SelectMfaTypeRac({choice: choice})
        } else {
            setType("danger");
            setMessage("Choose an option to continue");
            setStatus(1);
        }
    }
    
    return(
        <>
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={viewModalSelectMfaType}
            toggle={closeModal}
        >
            <div className="modal-header bg-gradient-hensall">
                <h6 className="modal-title text-white" id="modal-title-default">
                    Select MFA Type.
                </h6>
            </div>
            <div className="modal-body pb-0">
                <Form>
                    <div>
                        <p className='title'>Choose your preferred MFA type.</p>
                        <FormGroup className="custom-control custom-radio mb-3">
                            <Input
                                className="custom-control-input"
                                id="radio1"
                                name="mfaPreference"
                                type="radio"
                                value="SMS_MFA"
                                onKeyDown={handleKeyDown}
                            />
                            <label className="custom-control-label" htmlFor="radio1">
                                By SMS (to your saved phone number)
                            </label>
                        </FormGroup>
                        <FormGroup className="custom-control custom-radio">
                            <Input
                                className="custom-control-input"
                                id="radio2"
                                name="mfaPreference"
                                type="radio"
                                value="SOFTWARE_TOKEN_MFA"
                                onKeyDown={handleKeyDown}
                            />
                            <Label className="custom-control-label" htmlFor="radio2">
                                By authentication software (Google authenticator, Authy, etc)
                            </Label>
                        </FormGroup>
                    </div>
                </Form>
            </div>
            <div className="modal-footer">
                <Row className="col justify-content-end">
                    <Button
                        color="hensall"
                        href=""
                        onClick={handleSend}
                    >
                        Send
                    </Button>
                    <Button
                        className="btn btn-hensall-cancel"
                        color="default"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Row>
            </div>
        </Modal>
        </>
    );

}

export default SelectMfaType_RAC;