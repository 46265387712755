/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Loading.js
# Description: This file contains the base elements that compose the loading spinner and applies css classes
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from "reactstrap";
import "./spinner.css";

function Loading() {

    const [load, setLoad] = useState(true);

    const closeModal = (e) => {
        e.preventDefault();
    }

    return(
        <>
        <Modal
            className="modal-dialog-centered modal-loading"
            size="sm"
            isOpen={load}
            toggle={closeModal}
        >
            <div className="modal-body">
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            </div>
        </Modal>
        </>
    );

}

export default Loading;