/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: auditFilterForm.js
# Description: This file contains a form validation logic and errors for the audit filter and download buttons
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 01/17/2023

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/15/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {

    let errores = {};

    if (form.date_initial > form.date_final) {
        errores.date_initial = "The Start date should not be greater than End date";
        errores.date_final = "The End date should be greater than Start date";
    } else {
        if (!form.date_initial?.trim()) {
            errores.date_initial = "The field is required.";
        }else{
            errores.date_initial = "";
        }
    
        if (!form.date_final?.trim()) {
            errores.date_final = "The field is required.";
        }else{
            errores.date_final = "";
        }
    }

    return errores;

};

const AuditFilterForm = {
    validationsForm,
};
  
export default AuditFilterForm;