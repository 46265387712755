/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: smsRacForm.js
# Description: This file contains a form validation logic and errors for the MFA SMS challenge form.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/16/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    let errores = {};

    let regexNumber = /^[0-9]+$/;
    let regexNumber6 = /^.{1,6}$/;

    if(!form.verificationCode?.trim()){
        errores.verificationCode = "Please the field is required.";
    }  else if (!regexNumber.test(form.verificationCode.trim())) {
        errores.verificationCode = "The field accepts numbers only.";
    } else if (!regexNumber6.test(form.verificationCode.trim())) {
        errores.verificationCode = "The field must be a 6 digit number.";
    }else {
        errores.verificationCode = "";
    }

    return errores;
};

const smsRacForm = {
    validationsForm,
  };
  
export default smsRacForm;