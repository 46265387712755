/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: systemsForm.js
# Description: This file contains a form validation logic and errors for the systems form in UAM
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/21/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    
    let errores = {};

    if (!form.name?.trim()) {
        errores.name = "Please the field is required.";
    } else if (form.name?.trim().length > 30){
        errores.name = "The field must be less than 30 characters.";
    } else{
        errores.name = "";
    }

    if (!form.type?.trim()) {
        errores.type = "Please the field is required.";
    }else{
        errores.type = "";
    }

    if (!form.url?.trim()) {
        errores.url = "Please the field is required.";
    } else if (form.url?.trim().length > 100) {
        errores.url = "The field must be less than 100 characters.";
    } else{
        errores.url = "";
    }

    if (!form.acronym?.trim()) {
        errores.acronym = "Please the field is required.";
    } else if (form.acronym?.trim().length > 15) {
        errores.acronym = "The field must be less than 15 characters.";
    } else {
        errores.acronym = "";
    }

    if (form.token_structure?.trim().length > 50) {
        errores.token_structure = "The field must be less than 50 characters.";
    } else {
        errores.token_structure = "";
    }

    if (form.api?.trim().length > 100) {
        errores.api = "The field must be less than 100 characters.";
    } else {
        errores.api = "";
    }

    return errores;
};

const UsersForm = {
    validationsForm,
};
  
export default UsersForm;