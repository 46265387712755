import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationContext from "context/NotificationContext";
import { Link, useParams } from "react-router-dom";
import FieldsContext from "context/FieldsContext";
import FieldsFormValidate from "../../../services/fieldsForm";
import { useForm } from "hooks/useForm";
import ReactBSAlert from "react-bootstrap-sweetalert";
import UpdateOption from "./UpdateOption";
import useAuth from "hooks/useAuth";

const initialForm = {
  name: "",
  type: "",
  description: "",
  options: []
};

const Formulario = () => {

  const { detail:data, module, saveData, saveOption, deleteOption, updateData, updateOption, setModule, setToDetail, setToUpdate, field, fetchField, setLoading } = useContext(FieldsContext);

  const {checkTokenExpiry} = useAuth()

  const { setMessage, setStatus, setType } = useContext(NotificationContext);
  
  const [dataField, setDataField] = useState("")
  const [options, setOptions] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [idDeleteOption, setIdDeleteOption] = useState("");

  const [load, setLoad] = useState(false);
  const [descriptionField, setDescriptionField] = useState("")

  const [applications, setApplications] = useState([]);
  const [state, setState] = useState({});
  const [typeSelect, setTypeSelect] = useState(false)

  const hideAlert = () => {
    setState({
      alert: null
    });
  };

  const {
    form,
    errors,
    setForm,
    setErrors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, FieldsFormValidate.validationsForm);
 
  const { id } = useParams();

  const handleStateSelect = (e) => {
    if(e.target.value === "SELECT") {
        setTypeSelect(true)
    } else {
      setTypeSelect(false)
    }
  }

  const handlePushOptions = async() => {
    if(descriptionField === "") {
      setShowErrors(true)
      return
    } else if (descriptionField.length > 30) {
      setShowErrors(true)
      setType('danger')
      setMessage('"Options fields" information should be less than 30 characters')
      setStatus(1)
      return
    }
    let newOption = { name: descriptionField }
    if(id) {
      newOption.field_id = id
      setShowErrors(false)
      await saveOption(newOption)
      await fetchField(id)
      setDescriptionField("")
      return
    }
    let params = [...options, newOption]
    setForm({...form, options: params})
    setOptions(params)
    setShowErrors(false)
    setDescriptionField("")
  }

  const handleDescriptionField = (e) => {
    setShowErrors(true)
    setDescriptionField(e.target.value)
  }

  const handleRemoveOption = (id_option) => {
    if(id) return
    let temp = options.filter((el, index) => index !== id_option)
    setOptions(temp)
  }

  const handleSave = (e) => {
      e.preventDefault();
      if(typeSelect) {
        if(options && !options.length > 0) {
          setShowErrors(true)
          return
        }
      }
      let valid = handleSubmit(e);
      if(valid){
        setLoading(true)
        saveData(form);
      }
  }

  const handleUpdate = (e) => {
      e.preventDefault();
      let valid = handleSubmit(e);
      if(valid){
        if(JSON.stringify(data) === JSON.stringify(form)){
          setType('info')
          setMessage("No changes detected. Please cancel the process or change the information to save");
          setStatus(1);
        } else {
          setLoading(true)
          updateData(form);
        }
      }
  }

  useEffect(() => {
    checkTokenExpiry();
    if(id){
        setToDetail(id);
        setToUpdate(id);
        setModule("update");
    }else{
        setModule("add");
    }
  },[]);

  useEffect(() => {
      setForm(data);
      setErrors(initialForm);
  },[data]);

  useEffect(() => {
    if(field && Object.keys(field).length > 0 && field.options && field.options.length > 0) {
      setOptions(field.options)
    }else setOptions([])
  }, [field])

  useEffect(() => {
    if(data.type === "SELECT") {
        setTypeSelect(true)
    } else if(data.type && data.type !== "") {
        setTypeSelect(false)
    }
  }, [data.type])

  useEffect(() => {
    if(data.options && data.options.length > 0) {
      setOptions(data.options)
    }
  }, [data.options])

    
  const handleDeleteOption = (e, id) => {
    e.preventDefault();
    confirmAlertOption(id);
  }

  const confirmAlertOption = (id_alert) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onCancel={() => hideAlert()}
          onConfirm={() => {setIdDeleteOption(id_alert); hideAlert();}}
          showCancel
          confirmBtnBsStyle="hensall-cancel"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="hensall"
          cancelBtnText="Cancel"
          btnSize=""
        >
          You won't be able to revert this!
        </ReactBSAlert>
      )
    });
  };

  const request = async () => {
    await deleteOption(idDeleteOption);
    await fetchField(id)

  }

  useEffect(() => {
    if(idDeleteOption){
      request()
    }
  }, [idDeleteOption]);

  const handleModalUpdate = () => {
    setLoad(!load)
  }

  return (
    <>
      {state.alert}
      {load ? <UpdateOption load={load} setLoad={setLoad} handleModalUpdate={handleModalUpdate} updateOption={updateOption} dataField={dataField} /> : null}
      <SimpleHeader name={"Field " + (module ? (module[0].toUpperCase() + module.slice(1)) : "")} parentName="Fields" toUrl="fields" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Fields Form</h3>
                    <p className="text-sm mb-0">This is a form to create a new Custom Field or update its information.</p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Information</h6>
                  <div>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name" > Name </label> 
                          <Input
                            className="form-control"
                            id="input-name"
                            type="text"
                            name="name"
                            required="required"
                            invalid={errors.name !== ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={data.name}
                          />
                          <div className="invalid-feedback">{errors.name}</div>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-type-field" > Type </label>
                            <Input
                              invalid={errors.type !== ""}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleStateSelect(e);
                                handleChange(e)
                              }}
                              disabled = {data.type ? "disabled" : ""}
                              className="form-control" id="input-type-field" type="select" name="type" value={data.type}>
                                <option value="" hidden>Select type</option>
                                <option value="SELECT">Select</option>
                                <option value="TEXT">Text</option>
                            </Input>
                            <div className="invalid-feedback">{errors.type}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-description" > Description </label>
                          <Input
                            className="form-control"
                            id="input-description"
                            type="text"
                            name="description"
                            required="required"
                            invalid={errors.description !== ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={data.description}
                          />
                          <div className="invalid-feedback">{errors.description}</div>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        {typeSelect ? 
                          <FormGroup>
                            <label className="form-control-label" htmlFor="input-options" > Options Fields </label>
                            <Row className="mb-2">
                              <Col xs={9} className="pr-0 ">
                                  <Input
                                      className="form-control h-100"
                                      id="input-options"
                                      type="text"
                                      name="options"
                                      required="required"
                                      invalid={showErrors && descriptionField === ""}
                                      value={descriptionField}
                                      onChange={handleDescriptionField}
                                  />
                                  <div className="invalid-feedback">{errors.options}</div>
                              </Col>
                              <Col xs={3}  className="pl-0">
                                <p className="btn btn-warning mb-0 w-100" onClick={() => {handlePushOptions()}}>Add option</p>
                              </Col>
                            </Row>
                            <div className="invalid-feedback">{errors.options}</div>
                            <div className="bg-light d-flex flex-column gap-2 p-3 rounded-lg container-options-fields">
                              {options && options.length > 0 ? options.map((option, index) => (
                                <div key={index} className="shadow rounded-pill py-1 px-3 bg-white d-flex align-items-center justify-content-between py-2">
                                  <p className="mb-0 font-weight-bold">{option.name}</p>
                                  <div className="d-flex align-items-center">
                                    {id ? <p className="btn btn-hensall btn-sm m-0" 
                                      onClick={() => { 
                                        setDataField(option)
                                        handleModalUpdate() 
                                      }}
                                    >Update</p> : null}
                                    <button className="btn btn-hensall-cancel btn-sm"
                                      onClick={e => {
                                        handleRemoveOption(index)
                                        if(id) { handleDeleteOption(e, option.id) }
                                      }} 
                                    >Remove</button>
                                  </div>
                                </div>
                                )) : <p className="mb-0">There is no information to display in this section of <b>Options!</b></p>
                              }
                            </div>
                          </FormGroup> : null
                        }
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mb-4">
                      {module == "update" ? (
                        <Button color="hensall" onClick={handleUpdate}> Update </Button>
                      ) : (
                        <Button color="hensall" onClick={handleSave} > Save </Button>
                      )}
                      <Link className="btn btn-hensall-cancel" color="default" to={"/admin/fields"}>Cancel</Link>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Formulario;
