/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: usersAppForm.js
# Description: This is the file used to validate the Fieldtrace connection form
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/31/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {

    let errores = {};

    if (!form.username?.trim()) {
        errores.username = "Please the field is required.";
    }else{
        errores.username = "";
    }

    return errores;
    
};

const UsersAppForm = {
    validationsForm,
};
  
export default UsersAppForm;