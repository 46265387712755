import UsersContext from 'context/UsersContext';
import React, { useContext, useEffect } from 'react';
import { Button, Table, Alert } from "reactstrap";

  
function TableCustomFields({ id, confirmAlert }) {
    const { fieldUsers, fetchFieldsUsers } = useContext(UsersContext);
    
    useEffect(() => {
        const method = async () => {
            await fetchFieldsUsers(id)
        }
        method()
    },[id]);

    return(
        <Table className="align-items-center table-flush table-permissions" responsive>
            <thead className="thead-light">
                <tr>
                    <th>Name field</th>
                    <th>Type Field</th>
                    <th>Value</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {fieldUsers && fieldUsers.length > 0 ? fieldUsers.map((field, index) => 
                    <tr key={index}>
                        <td>{field?.field_name}</td>
                        <td>{field?.field_type}</td>
                        <td>{field.field_type == "SELECT" ? field.option_name : field?.value}</td>
                        <td className="table-actions">
                            <Button
                                className='btn btn-hensall-cancel btn-sm'
                                onClick={e => confirmAlert(field.id, "customField")}
                            >
                                Delete
                            </Button>
                        </td>
                    </tr>
                    ) : (
                        <tr>
                            <td>
                            <Alert color="secondary" className="m-2">
                                There is no information of <strong>Custom field!</strong>
                            </Alert>
                            </td>
                        </tr>
                    )}
            </tbody>
        </Table>
    );

}

export default TableCustomFields;

