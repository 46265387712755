/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: newPasswordReqForm.js
# Description: This file contains a form validation logic and errors for the MFA by SMS setup form.
# Created by: Juan David Olivares Padilla
# Creation Date: 11/16/2022

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    let errores = {};

    let regexRequireNumber = /^(?=.*[0-9])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
    let regexRequireLowercase = /^(?=.*[a-z])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
    let regexRequireUppercase = /^(?=.*[A-Z])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
    let regexRequireSpecial = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
    let regexMinimum8 = /^[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

    if (!form.newPassword?.trim()) {
        errores.newPassword = "Please the field is required.";
    } else if (!regexMinimum8.test(form.newPassword.trim())){
        errores.newPassword = "Password must be at least 8 characters long.";
    } else if (!regexRequireLowercase.test(form.newPassword.trim())){
        errores.newPassword = "Password must have at least one lowercase letter.";
    } else if (!regexRequireUppercase.test(form.newPassword.trim())){
        errores.newPassword = "Password must have at least one uppercase letter.";
    } else if (!regexRequireNumber.test(form.newPassword.trim())){
        errores.newPassword = "Password must have at least one number.";
    } else if (!regexRequireSpecial.test(form.newPassword.trim())){
        errores.newPassword = "Password must have at least one special character (\^$*.[\]{}()?\"!@#%&/\\,><\':;|_~`=+\- )";
    } else{
        errores.newPassword = "";
    }

    if (!form.repeatPassword?.trim()) {
        errores.repeatPassword = "Please the field is required.";
    } else if (form.newPassword !== form.repeatPassword) {
        errores.repeatPassword = "The password doesn't match.";
    } else{
        errores.repeatPassword = "";
    }

    return errores;
};

const NewPasswordReqForm = {
    validationsForm,
  };
  
  export default NewPasswordReqForm;