/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: InitContext.js
# Description: This is the Dashboards context, this file provides the API requests functions to obtainz metrics and more
# Created by: Juan David Olivares Padilla
# Creation Date: 09/27/2023

# Last Modification By: No modifications
# Last Modification Date: No modifications

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext, useState } from "react";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";

const { REACT_APP_ENV } = process.env;

const DashboardsContext = createContext();

const DashboardsProvider = ({children}) => {

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);

    const [signinSuccesses, setSigninSuccesses] = useState();
    const [lastMonths, setLastMonths] = useState();

    const { REACT_APP_API_URL } = process.env;

    let api = helpHttp();
    let url = REACT_APP_API_URL;

    //Helper funcitons
    // Función para formatear una fecha en el formato deseado
    function formatDateToCustomString(date) {        
        let dater = new Date(date)

        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
        const day = daysOfWeek[dater.getUTCDay()];
        const dayOfMonth = dater.getUTCDate();
        const month = months[dater.getUTCMonth()];
        const year = dater.getUTCFullYear();
    
        return `${day}, ${dayOfMonth} ${month} ${year} 00:00:00 GMT`;
    }

    //Sort API response array
    function sortData(data) {
        return data.sort((a, b) => {
            const firstDateA = new Date(a.timestamps[0]);
            const firstDateB = new Date(b.timestamps[0]);
    
            if (firstDateA > firstDateB) {
                return 1; // return 1 if the first date is later
            } else if (firstDateA < firstDateB) {
                return -1; // return -1 if the first date is earlier
            } else {
                return 0;
            }
        });
    }

    // API request functions

    const getCWMetrics = (data) => {
        let endpoint = url+"users/get_metrics";
        let options = {
            headers: {"Content-Type": "application/json"},
            body: data
        }
    
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                if (res.MetricDataResults && res.MetricDataResults.length !== 0) {
                    if (res.MetricDataResults[0].StatusCode === "Complete") {
                        setSigninSuccesses({
                            timestamps: res.MetricDataResults[0].Timestamps,
                            values: res.MetricDataResults[0].Values
                            
                        })
                    }
                }
            }else{
                setType("danger");
                setMessage("Can't get metrics data");
                setStatus(1);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setType('danger')
            setMessage("Can't load data")
            setStatus(1)
        });
    };

    const getLastMonthsMetrics = (data) => {

        let StartTimes = data.StartTimes;
        let EndTimes = data.EndTimes;

        let endpoint = url+"users/get_metrics";
        let options = {
            headers: {"Content-Type": "application/json"},
        }

        let responseArr = []

        StartTimes.map((startTime, index) => {
            switch (index) {
                case 0:
                    options.body = {EndTime: EndTimes[0]}
                    break;
                case 1:
                    options.body = {EndTime: EndTimes[1]}
                    break;
                case 2:
                    options.body = {EndTime: EndTimes[2]}
                    break;
                default:
                    break;
            }

            delete data[StartTimes];
            delete data[EndTimes];

            options.body = {StartTime: startTime, ...options.body, ...data}

            delete options.body[StartTimes];
            delete options.body[EndTimes];


            api.post(endpoint, options).then((res) => {
                if(!res.err){
                    if (res.MetricDataResults && res.MetricDataResults.length !== 0) {
                        if (res.MetricDataResults[0].StatusCode === "Complete") {
                            if(res.MetricDataResults[0].Timestamps.length < 12){

                                let timeStampTemp = [];
                                let valueTemp = [];

                                const len = res.MetricDataResults[0].Timestamps.length

                                let ultimaFecha = new Date(res.MetricDataResults[0].Timestamps[len - 1]);
                                ultimaFecha = new Date(ultimaFecha.setMonth(ultimaFecha.getMonth()))

                                // if (REACT_APP_ENV === "TESTING" || REACT_APP_ENV === "DEVELOPMENT") {
                                //     if (ultimaFecha.getUTCFullYear() === 2022) {
                                //         for (let i = 0; i < (12 - res.MetricDataResults[0].Timestamps.length); i++) {
                                //             ultimaFecha = new Date(ultimaFecha.setUTCMonth(ultimaFecha.getUTCMonth() - 1))
                                //             timeStampTemp.unshift(formatDateToCustomString(ultimaFecha))
                                //             valueTemp.push(0)
                                //             if (ultimaFecha.getUTCMonth() === 0) {
                                //                 break;
                                //             }
                                //         }
                                //     } else if (ultimaFecha.getUTCFullYear() === 2024) {
                                //         for (let i = 0; i < (12 - res.MetricDataResults[0].Values.length); i++) {
                                //             if (ultimaFecha.getUTCMonth() === 0) {
                                //                 break;
                                //             }
                                //             valueTemp.push(0)
                                //         }
                                //     }
                                // } else {
                                    if (ultimaFecha.getUTCFullYear() === 2023) {
                                        for (let i = 0; i < (12 - res.MetricDataResults[0].Timestamps.length); i++) {
                                            ultimaFecha = new Date(ultimaFecha.setUTCMonth(ultimaFecha.getUTCMonth() - 1))
                                            timeStampTemp.unshift(formatDateToCustomString(ultimaFecha))
                                            valueTemp.push(0)
                                            if (ultimaFecha.getUTCMonth() === 0) {
                                                break;
                                            }
                                        }
                                    } else if (ultimaFecha.getUTCFullYear() === 2024) {
                                        for (let i = 0; i < (12 - res.MetricDataResults[0].Values.length); i++) {
                                            ultimaFecha = new Date(ultimaFecha.setUTCMonth(ultimaFecha.getUTCMonth() - 1))
                                            if (ultimaFecha.getUTCMonth() === 0) {
                                                break;
                                            }
                                            valueTemp.unshift(0)
                                        }                               
                                        res.MetricDataResults[0].Timestamps.reverse();
                                        ultimaFecha = new Date(res.MetricDataResults[0].Timestamps[0]);
                                        ultimaFecha = new Date(ultimaFecha.setMonth(ultimaFecha.getMonth() + 1))
                                        for (let i = 0; i < (12 - res.MetricDataResults[0].Timestamps.length); i++) {
                                            ultimaFecha = new Date(ultimaFecha.setUTCMonth(ultimaFecha.getUTCMonth() + 1))
                                            timeStampTemp.push(formatDateToCustomString(ultimaFecha))
                                            if (ultimaFecha.getUTCMonth() === 12) {
                                                break;
                                            }
                                        }
                                    }
                                // }

                                timeStampTemp.push(...res.MetricDataResults[0].Timestamps.reverse())
                                valueTemp.push(...res.MetricDataResults[0].Values.reverse())

                                responseArr.push({
                                    timestamps: timeStampTemp,
                                    values: valueTemp.reverse()
                                })
                            }else {
                                responseArr.push({
                                    timestamps: res.MetricDataResults[0].Timestamps,
                                    values: res.MetricDataResults[0].Values
                                })
                            }                            

                            // if (REACT_APP_ENV === "TESTING" || REACT_APP_ENV === "DEVELOPMENT") {
                            //     if (responseArr.length === 3) {
                            //         const temp = sortData(responseArr)
                            //         setLastMonths(temp)
                            //     }
                            // } else {
                                if (responseArr.length === 2) {
                                    const temp = sortData(responseArr)
                                    setLastMonths(temp)
                                }
                            // }
                        }
                    }
                }else{
                    setType("danger");
                    setMessage("Can't get metrics data");
                    setStatus(1);
                }
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            })
        })
    };

    const data = { getCWMetrics,
                   getLastMonthsMetrics,
                   signinSuccesses,
                   lastMonths };

    return <DashboardsContext.Provider value={data}>{children}</DashboardsContext.Provider>;
}

export { DashboardsProvider };
export default DashboardsContext;