/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: List.jsx
# Description: This is Systems main list base component with pagination and functional buttons.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/21/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/11/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import SystemsContext from "../../../context/SystemsContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationContext from "context/NotificationContext";
import useAuth from "hooks/useAuth";

import {
  Card,
  CardHeader,
  Alert,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  UncontrolledDropdown, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem, 
  Badge
} from "reactstrap";

import { useParams } from "react-router";
import { Link } from "react-router-dom";

const { SearchBar } = Search;

function List({ tab }) {

  const { db:data, setDetail,  setToDetail, setToUpdate, setViewModal, setModule, enableDisable, deleteData, resetPassword } = useContext(SystemsContext);
  const { setMessage, setStatus, setType } = useContext(NotificationContext);
  const { checkTokenExpiry } = useAuth();

  const[state, setState] = useState({});
  const[idDelete, setIdDelete] = useState();

  const { isAuthenticated, validatePermission } = useAuth();

  let emptysearchFlag = false;

  //paginator
  const initialSizePerPage = parseInt(localStorage.getItem('sizePerPage')) || 10;
  const [sizePerPage, setSizePerPage] = useState(initialSizePerPage);
  
  useEffect(() => {
    localStorage.setItem('sizePerPage', sizePerPage);
  }, [sizePerPage]);

  const pagination = paginationFactory({
    sizePerPage: sizePerPage,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              value={currSizePerPage}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                onSizePerPageChange(value, 1); // Reset page to 1 when size changes
                setSizePerPage(value); // Update state and local storage
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const hideAlert = () => {
    setState({
      alert: null
    });
  };

  const confirmAlertDelete = (id) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onCancel={() => hideAlert()}
          onConfirm={() => {setIdDelete(id); hideAlert();}}
          showCancel
          confirmBtnBsStyle="hensall-cancel"
          confirmBtnText="Yes, delete it!"
          cancelBtnBsStyle="hensall"
          cancelBtnText="Cancel"
          btnSize=""
        >
          You won't be able to revert this!
        </ReactBSAlert>
      )
    });
  };

  const confirmAlertEnableDisable = (item) => {
    setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onCancel={() => hideAlert()}
          onConfirm={() => {enableDisable(item); hideAlert();}}
          showCancel
          confirmBtnBsStyle="hensall-cancel"
          confirmBtnText="Ok"
          cancelBtnBsStyle="hensall"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {item.status ? "This application won't be accessible until reactivation!" : "This application will now be accessible!"}
        </ReactBSAlert>
      )
    });
  };

  useEffect(() => {
    checkTokenExpiry();
    setDetail({});
    setToUpdate(0);
  },[]);

  useEffect(() => {
    if(idDelete){
      deleteData(idDelete);
    }
  },[idDelete]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    confirmAlertDelete(id);
  }

  const handleEnableDisable = (e, item) => {
    e.preventDefault();
    confirmAlertEnableDisable(item);
  }

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
      <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-black"
            href="#pablo"
            role="button"
            size="sm"
            color="hensall"
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>

            <DropdownItem 
              tag={Link}
              to={"/admin/applications/detail/"+row.id}
            >
              Detail
            </DropdownItem>

            {row.log_file && isAuthenticated() && validatePermission(["VIEW_LOGS"]) &&
            <DropdownItem 
              tag={Link}
              to={"/admin/applications/log/"+row.id}
            >
              Logs
            </DropdownItem> 
            }

            <DropdownItem
                className=""
                onClick={e => handleEnableDisable(e, row)}
            >
              {row.status ? 'Deactivate' : 'Activate'}
            </DropdownItem>
            
            <DropdownItem
                className="item-delete"
                onClick={e => handleDelete(e, row.id)}
              >
                Delete
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  };

  /*const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
        <Link className='btn btn-hensall btn-sm'
    			color="hensall"
    			to={"/admin/applications/detail/"+row.id}
    		>
    			Detail
    		</Link>
        {row.log_file ? 
        <Link className='btn btn-hensall btn-sm'
          color="hensall"
          to={"/admin/applications/log/"+row.id}
        >
          Logs
        </Link>
        : ("")}
    		<Button className={row.status ? 'btn btn-hensall-cancel btn-sm' : 'btn btn-hensall btn-sm'}
    			onClick={e => handleEnableDisable(e, row)}
    		>
    			{row.status ? 'Deactivate' : 'Activate'}
    		</Button>
    		<Button
    			className='btn btn-hensall-cancel btn-sm'
    			onClick={e => handleDelete(e, row.id)}
    		>
    			Delete
    		</Button>
    		</>
    );
  };*/

  const NoDataIndication = () => (
    <Alert color="secondary" className="mt-2 mb-1 text-center">
      Search input didn't match any register of the table.
    </Alert>
  );

  return (
    <>
    {state.alert}
    <SimpleHeader name="Applications" toUrl="applications" />
      <Container className="mt--6 table-action-20" fluid>
        <Row>
          <div className="col">
            <Card>
            <CardHeader>
                <h3 className="mb-0">Applications Result List</h3>
                <p className="text-sm mb-0">
                    This is a list of Applications where can be managed new and existing Applications.
                </p>
              </CardHeader>
              {(data.length > 0) ? ( 
              <>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "#",
                    sort: true,
                    hidden: true,
                  },
                  {
                    dataField: "name",
                    text: "NAME",
                    sort: true,
                  },
                  {
                    dataField: "type",
                    text: "TYPE",
                    sort: true,
                  },
                  {
                    dataField: "url",
                    text: "URL",
                    sort: true,
                  },
                  {
                    dataField: "token_structure",
                    text: "TOKEN",
                    sort: true,
                    hidden: true,
                  },
                  {
                    dataField: "acronym",
                    text: "ACRONYM",
                    sort: true,
                  },
                  {
                  dataField: "action",
                  text: "ACTION",
                  formatter: linkFollow,
                  sort: false,
                  },
                ]}
                search
                >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      noDataIndication={NoDataIndication}
                    />
                  </div>
                )}
              </ToolkitProvider>
              </> 
            ) : (
              <CardBody>
                <Alert color="secondary" className="m-2">
                  There is no information to display in this section of <strong>Applications!</strong>
                </Alert>
              </CardBody>
            )}
            <div className="m-3">
              <Link className='btn btn-hensall'
                color="hensall"
                to={"/admin/applications/add"}
              >
                Add Applications
              </Link>
            </div>
            </Card>
          </div>
        </Row>
        </Container>
    </>
  );
}

export default List;