/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: loginForm.js
# Description: This file contains a form validation logic and errors for the login form.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/21/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 11/17/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
export const validationsForm = (form) => {
    let errores = {};
    let regexEmail = /^(?=.{1,254}$)(?=.{1,64}@.{1,255}$)([a-zA-Z0-9!#$%&'*+/=?^_`{|}~\-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~\-]+)*)@([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;

    if (form.prelogin) {
        if (!form.email?.trim()) {
            errores.email = "Please the field is required.";
        } else if (!regexEmail.test(form.email.trim())) {
            errores.email = "Please type a valid email address.";
        } else {
            errores.email = "";
        }
    } else {
        if (!form.email?.trim()) {
            errores.email = "Please the field is required.";
        } else if (!regexEmail.test(form.email.trim())) {
            errores.email = "Please type a valid email address.";
        } else {
            errores.email = "";
        }
    
        if (!form.password?.trim()) {
            errores.password = "Please the field is required.";
        }else{
            errores.password = "";
        }
    }

    return errores;
};

const LoginForm = {
    validationsForm,
};
  
  export default LoginForm;